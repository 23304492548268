import { useState } from "react";
import { Button } from "react-bootstrap";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { toast } from "react-toastify";
import { clearCache } from "../_services/master-data-svc";

const ManageCache: React.FC<{}> = ({ }) => {

    const [loadingCacheClear, setLoadingCacheClear] = useState<boolean>(false);
    const [disabledCacheButton, setDisabledCacheButton] = useState<boolean>(false);
    const [cacheName, setCacheName] = useState<string>('all');

    const handleClearCacheTemplate = async () => {
        setLoadingCacheClear(true);
        setDisabledCacheButton(true);
        let isSuccess = await clearCache(cacheName);
        if (isSuccess) {
            toast.success("Successfully clear cache", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
        }
        else {
            toast.error("Error occurred", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setDisabledCacheButton(false);
        setLoadingCacheClear(false);
    }

    return (
        <>
            <div style={{ marginLeft: '30%', minHeight: '410px', maxHeight: '410px' }}>
                {loadingCacheClear && <LoadingIcon />}
                <div className="d-flex justify-content-between">
                    <Button disabled={disabledCacheButton} variant="primary" onClick={handleClearCacheTemplate}>
                        Clear All Cache
                    </Button>
                </div>
            </div>
        </>
    );
}


export default ManageCache;