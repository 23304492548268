import { Button, Form, Modal } from "react-bootstrap";
import { IApprover } from "../../data-model/approver";
import { useEffect, useState } from "react";
import AutocompleteSearch from "../../common/auto-complete/auto-complete-search";
import { IEmployee } from "../../data-model";
import { getAllCWMemberData } from "../_services/master-data-svc";
import IApproverBUUnits from "../../data-model/approver-buunits";
import { getAllApproverBUUnits } from "../_services/approval-svc";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { useDelayDebounce } from "../../common/hooks/debounce";


const ApproverCreate: React.FC<{
    show: boolean;
    onClose: () => void;
    onSave: (updatedDetails: IApprover) => void;
    initialApprover: IApprover;
}> = ({ show, onClose, initialApprover, onSave }) => {

    const delayDebouncedValue = useDelayDebounce(100);

    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const [allApproverBUUnits, setAllApproverBUUnits] = useState<IApproverBUUnits[]>([]);
    const [formData, setFormData] = useState<IApprover>(initialApprover);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (delayDebouncedValue > 0) {
            fetchAllApproverBUUnits();
        }
    }, [delayDebouncedValue]);

    const fetchAllApproverBUUnits = async (): Promise<IApproverBUUnits[]> => {
        try {
            setLoading(true)
            let data = await getAllApproverBUUnits();
            if (data) {
                setAllApproverBUUnits(data);
            }
            setLoading(false)
        } catch (error) {
            console.error('Error fetching status:', error);
        }
        return [];
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = () => {
        const errors = validateFormData("", "");
        if (Object.keys(errors).length === 0) {
            onSave(formData);
            onClose();
        } else {
            setValidationErrors(errors);
        }
    }

    const handleApproverSelected = (item: IEmployee | undefined, selectedText: string) => {
        setFormData((prevData) => ({
            ...prevData,
            ["FullName"]: (item != undefined ? item.fullName : selectedText) ?? '',
            ["UserName"]: (item != undefined ? item.userName : null) ?? '',
            ["Email"]: (item != undefined ? item.email : null) ?? '',
        }));

        validateFormData("FullName", (item != undefined ? item.fullName : null) ?? '');
    };

    const validateFormData = (name: string, value: string) => {
        const errors: { [key: string]: string } = {};
        if (!formData.FullName || !formData.UserName || !formData.Email) {
            errors.Approver = 'Approver is required. Search and select';
        }
        if (!formData.BUUnitId) {
            errors.BUUnit = 'Approver BU-Unit is required.';
        }

        // Clear validation error when the input becomes valid
        if (validationErrors[name]) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }

        return errors;
    }

    const handleApproverBUUnitChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            ["BUUnitId"]: parseInt(value),
        }));
    }

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{initialApprover ? 'Edit Approver' : 'Create Approver'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formApprover">
                            <Form.Label>Approver</Form.Label>
                            <AutocompleteSearch
                                OnItemSelected={handleApproverSelected}
                                SelectedInitialItem={formData?.FullName ?? ''}
                                PlaceHolder='Type Approver Name..'
                                APICallback={(searchText) => getAllCWMemberData(searchText)}
                                AllowFreeText={true}
                                ShowVaidationError={!!validationErrors.FullName}
                                DisplayProperty={(item) => item.fullName ?? 'fullName'}
                            />
                            {
                                validationErrors.Approver && <p style={{ color: 'red' }} className="error-message">{validationErrors.Approver}*</p>
                            }
                        </Form.Group>
                        <Form.Group controlId="formBUUnit">
                            <Form.Label>Pipeline/BU-Unit</Form.Label>
                            {loading ? <LoadingIcon /> :
                                <select style={{ marginLeft: '10px', marginTop: '10px' }} id="status" name="status"
                                    value={formData?.BUUnitId ?? -1}
                                    onChange={handleApproverBUUnitChange}>
                                    <option disabled value="-1">-Select-</option>
                                    {allApproverBUUnits.map((ap) => (
                                        <option key={ap.Id} value={ap.Id}>
                                            {ap.Description}
                                        </option>
                                    ))}
                                </select>
                            }
                            {
                                validationErrors.BUUnit && <p style={{ color: 'red' }} className="error-message">{validationErrors.BUUnit}*</p>
                            }
                        </Form.Group>
                        <Form.Group controlId="formApproverIsActive">
                            <Form.Check
                                type="checkbox"
                                label="Is Active"
                                name="IsActive"
                                checked={formData.IsActive}
                                onChange={(e) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        IsActive: e.target.checked,
                                    }))
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        <i className="fas fa-times"></i> Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        <i className="fas fa-save"></i> {formData.Id && formData.Id > 0 ? 'Update Approver' : 'Save Approver'}
                    </Button>
                </Modal.Footer>
            </Modal >

        </>
    )
};

export default ApproverCreate;
