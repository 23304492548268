import axios from 'axios';
import { msalInstance } from '../..';

const getApiData = async (url: string, params?: any, contentType?: string, responseType?: string) => {
    const account = await msalInstance.getAllAccounts()[0];
    const accessTokenRequest = {
        scopes: (window as any).REACT_APP_AZURE_AD_SCOPE?.split(','),
        account: account,
    };
    let accTokenObj = await msalInstance.acquireTokenSilent(accessTokenRequest);
    let accessToken = await accTokenObj.accessToken;
    if (!contentType)
        contentType = "application/json";
    let responseT = responseType ? responseType : undefined;
    return axios.get(url, {
        headers: {
            'Content-Type': contentType,
            Authorization: `Bearer ${accessToken}`,
            "Response-Type": responseT
        },
        params: params,
    }).then(function (response: any) {
        return response.data;
    }).catch((error) => {
        console.error(error);
        throw error;
    });
}

export default getApiData;