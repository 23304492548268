import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getRandomNumber } from '../../utilities/helper-function';
import { IInvoiceSchedule } from '../../data-model';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

interface IDataset {
    label: string,
    data: (number | null | undefined)[],
    backgroundColor: string;
}

interface IInvoiceData {
    labels: string[] | undefined,
    datasets: IDataset[]
}

const IInvoiceVerticleChart: React.FC<{
    invoiceSchedule: IInvoiceSchedule | null;
}> = ({ invoiceSchedule }) => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'xy',
                }
            },
            title: {
                display: true,
                text: `Invoice Schedule - ${invoiceSchedule?.Frequency?.Frequency ?? ''}`,
            },
            tooltip: {
                backgroundColor: '#31373D',
                callbacks: {
                    label: function (context: any) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                        }
                        return label;
                    },
                    labelTextColor: function (context: any) {
                        return 'white';
                    }
                },
                bodyFontSize: 24
            }
        },
        animation: {
            duration: 100
        },
    };

    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

    const [initialInvoiceScheduleData, setInitialInvoiceScheduleData] = useState<IInvoiceSchedule | null>();
    const [chartData, setChartData] = useState<any>();
    const [showProjectedWIP, setShowProjectedWIP] = useState<boolean>(true);
    const [showTotalProjectedWIP, setShowTotalProjectedWIP] = useState<boolean>(false);

    const [showInvoiceAmount, setShowInvoiceAmount] = useState<boolean>(true);
    const [showTotalInvoiceAmount, setShowTotalInvoiceAmount] = useState<boolean>(false);

    useEffect(() => {
        if (invoiceSchedule) {
            setInitialInvoiceScheduleData(invoiceSchedule);

            const invoiceData: IInvoiceData = {
                labels: invoiceSchedule.ScheduleFrequencies?.map((d) => d?.LabelText ?? ''),
                datasets: []
            }

            if (showProjectedWIP) {
                let projectedDatas = invoiceSchedule.ScheduleFrequencies?.map((p) => {
                    return p.ProjectedWIPEffortCost;
                });
                invoiceData.datasets.push({
                    label: 'Projected WIP',
                    data: projectedDatas ?? [],
                    backgroundColor: 'blue'
                });
            }
            if (showTotalProjectedWIP) {
                let totalProjectedWIPs = invoiceSchedule.ScheduleFrequencies?.map((p) => {
                    return p.TotalProjectedWIPCost;
                });
                invoiceData.datasets.push({
                    label: 'Total Projected WIP',
                    data: totalProjectedWIPs ?? [],
                    backgroundColor: '#454621'
                });
            }
            if (showInvoiceAmount) {
                let invoiceScheduleCost = invoiceSchedule.ScheduleFrequencies?.map((p) => {
                    return p.InvoiceAmount;
                });
                invoiceData.datasets.push({
                    label: 'Invoice Cost',
                    data: invoiceScheduleCost ?? [],
                    backgroundColor: 'green'
                });
            }
            if (showTotalInvoiceAmount) {
                let totalInvoiceScheduleCost = invoiceSchedule.ScheduleFrequencies?.map((p) => {
                    return p.TotalInvoiceScheduleCost;
                });
                invoiceData.datasets.push({
                    label: 'Total Invoice Cost',
                    data: totalInvoiceScheduleCost ?? [],
                    backgroundColor: '#754621'
                });
            }

            //Add diff
            let differenceProjectedVsInvoiceData = invoiceSchedule.ScheduleFrequencies?.map((p) => {
                return p.ProjectedVsInvoiceDiff;
            });
            invoiceData.datasets.push({
                label: 'Projected vs Invoice Diff',
                data: differenceProjectedVsInvoiceData ?? [],
                backgroundColor: 'red'
            });

            setChartData(invoiceData ?? null);
        } else {
            setChartData(null);
        }
    }, [invoiceSchedule, showProjectedWIP, showInvoiceAmount, showTotalProjectedWIP, showTotalInvoiceAmount]);


    return (
        <>
            <div className='row'>
                <Form.Group controlId="checkboxGroup">
                    <Form.Check
                        inline
                        type="checkbox"
                        label="Projected WIP"
                        //value="ProjectedWIP"
                        name="ProjectedWIP"
                        checked={showProjectedWIP}
                        onChange={(e) => setShowProjectedWIP(e.target.checked)}
                    />
                    <Form.Check
                        inline
                        type="checkbox"
                        label="Total Projected WIP"
                        name="TotalProjectedWIP"
                        checked={showTotalProjectedWIP}
                        onChange={(e) => setShowTotalProjectedWIP(e.target.checked)}
                    />
                    <Form.Check
                        inline
                        type="checkbox"
                        label="Invoice Amount"
                        name="InvoiceAmount"
                        checked={showInvoiceAmount}
                        onChange={(e) => setShowInvoiceAmount(e.target.checked)}
                    />
                    <Form.Check
                        inline
                        type="checkbox"
                        label="Total Invoice Amount"
                        name="TotalInvoiceAmount"
                        checked={showTotalInvoiceAmount}
                        onChange={(e) => setShowTotalInvoiceAmount(e.target.checked)}
                    />
                </Form.Group>

            </div>
            <div className='row'>
                {chartData && <Bar options={options} data={chartData} width='800px' height='400px' />}
            </div>
        </>
    )
}

export default IInvoiceVerticleChart;