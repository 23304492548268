import React, { useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import IPCRRatesGrid from './pcr-rates-manage';

const PCRRatesManage : React.FC<{ }> = ({  }) => {
    
    return (
      <div>
          <div className="row">
                <IPCRRatesGrid/>
            </div>          
      </div>     
    )
}

export default PCRRatesManage;