import { AlignmentType, Document, HeadingLevel, Packer, Paragraph, TabStopPosition, TabStopType, TextRun, Table, TableRow, TableCell, BorderStyle, VerticalAlign } from "docx";
import { formatDateAsString, formatToUSCurrency, parseDateAsStringFormat } from "../../utilities/helper-function";
import { IInvoiceSchedule } from "../../data-model";

export class InvoiceScheduleReport {

  private getTableHeaderCell(name: string): TableCell {
    let cell = new TableCell({
      children: [new Paragraph({
        text: name,
        heading: HeadingLevel.HEADING_3,
        //thematicBreak: true
      })],
      borders: {
        top: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
        bottom: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
        left: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
        right: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
      },
    });

    return cell;
  }

  private getTableDataCell(data: string): TableCell {
    let cell = new TableCell({
      children: [new Paragraph({
        text: data,
        alignment: AlignmentType.RIGHT,
      })],
      borders: {
        top: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
        bottom: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
        left: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
        right: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
      },
    })

    return cell;
  }


  public async create(invoiceSchedules?: IInvoiceSchedule | null): Promise<Document> {
    let invoiceData: TableRow[] = [];

    let tableHeader = new TableRow({
      children: [
        this.getTableHeaderCell("Invoice"),
        this.getTableHeaderCell("Date"),
        this.getTableHeaderCell("Amount"),
        this.getTableHeaderCell("Total Amount")
      ],
    })

    invoiceData.push(tableHeader);

    invoiceSchedules?.ScheduleFrequencies?.map((frequencyData, index) => {
      if (frequencyData.InvoiceAmount && frequencyData.InvoiceAmount > 0) {
        let tableRow = new TableRow({
          children: [
            this.getTableDataCell(`Invoice ${index + 1}`),
            this.getTableDataCell(`${formatDateAsString(frequencyData.FromDate)} - ${formatDateAsString(frequencyData.ToDate)}`),
            this.getTableDataCell(`${formatToUSCurrency(frequencyData.InvoiceAmount ?? null)}`)
          ],
        });
        invoiceData.push(tableRow);
        return tableRow;
      }
    });

    if (invoiceData && invoiceData?.length > 1) {
      const totalInvoice = invoiceSchedules?.ScheduleFrequencies?.reduce((total, frequency) => total + (frequency.InvoiceAmount ?? 0), 0);
      invoiceData[1].addChildElement(new TableCell({
        children: [new Paragraph({
          text: `${formatToUSCurrency(totalInvoice ?? null)}`,
          alignment: AlignmentType.CENTER,

        })],
        verticalAlign: VerticalAlign.CENTER,
        rowSpan: invoiceData.length,
        borders: {
          top: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
          bottom: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
          left: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
          right: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
        },
      })
      );
    }

    const document = new Document({
      sections: [
        {
          children: [
            new Table({
              rows: invoiceData,
            }),
          ],
        },
      ],
    });

    return document;
  }
}

