import React, { useRef, useState, ChangeEvent, useEffect } from 'react';
import '../auto-complete/auto-complete-search.scss'
import Form from 'react-bootstrap/Form';
import { useDebounce } from '../hooks/debounce';
import LoadingIcon from '../loading-icon/loading-icon';

interface AutocompleteSearchProps<T> {
    APICallback: (searchText: string) => Promise<T[]>;
    APIDelayTime?: number | 500;
    AllowFreeText: boolean | true;
    PlaceHolder: string;
    ShowVaidationError: boolean;
    SelectedInitialItem: string;
    OnItemSelected: (item: T | undefined, selectedText: string) => void;
    DisplayProperty: (item: T) => string;
    isDisabled?: boolean | false;
}

const AutocompleteSearch = <T,>({ PlaceHolder, ShowVaidationError, SelectedInitialItem, OnItemSelected,
    DisplayProperty, AllowFreeText, APICallback, APIDelayTime, isDisabled }: AutocompleteSearchProps<T>) => {

    const formRef = useRef<HTMLDivElement | null>(null);

    const [suggestions, setSuggestions] = useState<T[]>([]);
    const [selectedItem, setSelectedItem] = useState<T | null>(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState<string>('')
    const debouncedValue = useDebounce<string>(searchText, APIDelayTime)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (formRef.current && !formRef.current.contains(event.target as Node) && formRef.current?.id === 'auto_suggestions') {
                setShowSuggestions(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setSearchText(SelectedInitialItem);
    }, [SelectedInitialItem]);

    useEffect(() => {
        if (searchText?.length >= 3) {
            setLoading(true);
            APICallback(searchText).then((res) => {
                setSuggestions(res);
                setLoading(false);
            });
        } else {
            setSuggestions([]);
        }
    }, [debouncedValue])

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        setSearchText(input)
        let selectedSuggetion = suggestions?.filter((f) => DisplayProperty(f) == input)[0];
        OnItemSelected(selectedSuggetion, input);
        if (input.length > 0) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const handleItemClick = (item: T) => {
        setSearchText(DisplayProperty(item));
        setSuggestions([]);
        if (AllowFreeText)
            OnItemSelected(item, searchText);
        else
            OnItemSelected(item, DisplayProperty(item));
        setSelectedItem(item);
        setShowSuggestions(false);
    };

    const handleMouseEnter = (item: T) => {
        setSelectedItem(item);
    };

    const handleMouseLeave = () => {
        setSelectedItem(null);
    };

    return (
        <div>
            <Form.Control
                type="text"
                placeholder={PlaceHolder}
                disabled={isDisabled}
                value={searchText}
                title={searchText}
                onChange={handleInputChange}
                autoComplete="off"
                isInvalid={ShowVaidationError}
            />
            {showSuggestions && suggestions.length > 0 && (
                <div id="auto_suggestions" className="parent-element" ref={formRef}>
                    <div className="suggestions-container" style={{ minHeight: '0px', maxHeight: '150px', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #ccc' }}>
                        {loading && <LoadingIcon />}
                        {suggestions.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => handleItemClick(item)}
                                onMouseEnter={() => handleMouseEnter(item)}
                                onMouseLeave={handleMouseLeave}
                                className={selectedItem === item ? 'selected-row' : ''}
                            >
                                {DisplayProperty(item)}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AutocompleteSearch;
