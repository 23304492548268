import React, { useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import RoleResourceCreate from './role-resource-create';
import RolesResourceDetails from './role-resource-detail';
import { IProjectRolesResource } from '../../data-model';
import { applyRoleRateDiscount, deleteProjectRoleResource, getProjectAllRoleResources, saveProjectRoleResource, updateProjectRoleResource } from "../_services/role-resource-estimation-svc";
import { toast } from 'react-toastify';
import { useProjectDataContext } from "../../data-context/project-data-context";
import { initialDefaultRoleResource } from '../_services/initial-default-data';
import { mapToProjectEffortList } from '../../data-model/data-mapping';
import LoadingIcon from '../../common/loading-icon/loading-icon';
import { formatToUSCurrency, getRandomNumber, isProjectReadOnlyStatus, isUserRoleReadOnly } from '../../utilities/helper-function';
import ApplyRoleDiscountModal from './apply-discount';

const RoleResourceManage: React.FC<{ projectId?: number }> = ({ projectId }) => {
  const { currentProjectIdGblCtx, currentProjectInfoGblCtx, refreshProjectStatusGblCtx, userAccessInfoGblCtx } = useProjectDataContext();
  const [showRoleResCreateEditModal, setShowRoleResCreateEditModal] = useState(false);
  const [showRoleRateDiscountModal, setShowRoleRateDiscountModal] = useState(false);
  const [currentRoleResource, setCurrentRoleResource] = useState<IProjectRolesResource>(initialDefaultRoleResource);
  const { setAllProjectRoleResoursesGblCtx, setRefreshLevelOfEstimationGblCtx } = useProjectDataContext();
  const [allRoleResources, setAllRoleResources] = useState<IProjectRolesResource[]>([]);
  const [tempRefreshRoles, setTempRefreshRoles] = useState<number>(0);
  const [loadingRoleGrid, setLoadingRoleGrid] = useState<boolean>(false);
  const [hideAddRoleLink, setHideAddRoleLink] = useState<boolean>(false);
  const [hideDiscountLink, setHideDiscountLink] = useState<boolean>(false);

  const [avgSTD, setAvgSTD] = useState<number | null>();
  const [avgSTDColor, setAvgSTDColor] = useState<string | null>();
  const [avgOfferedRate, setAvgOfferedRate] = useState<number | null>();
  const [avgOfferedRateColor, setAvgOfferedRateColor] = useState<string | null>();

  useEffect(() => {
    fetchRoleResources();
  }, [projectId]);

  useEffect(() => {
    if (allRoleResources.length > 0) {
      setAllProjectRoleResoursesGblCtx(allRoleResources);
      let aStdRate = calculateAvgSTDRate();
      setAvgSTD(aStdRate);
      let aOfferedRate = calculateAvgOfferedRate()
      setAvgOfferedRate(aOfferedRate);
      if (aStdRate && aOfferedRate && aStdRate > 0 && aOfferedRate > 0 && aStdRate != aOfferedRate) {
        setAvgSTDColor(aStdRate > aOfferedRate ? 'green' : 'red')
        setAvgOfferedRateColor(aStdRate < aOfferedRate ? 'green' : 'red')
      }
    } else {
      setAllProjectRoleResoursesGblCtx([]);
      setAvgSTD(null)
      setAvgOfferedRate(null)
      setAvgSTDColor(null)
      setAvgOfferedRateColor(null)
    }
  }, [tempRefreshRoles]);

  useEffect(() => {
    if (refreshProjectStatusGblCtx && refreshProjectStatusGblCtx > 0) {
      setHideAddRoleLink(isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) || isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles));
      setHideDiscountLink(isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) || isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles));
    }
  }, [refreshProjectStatusGblCtx]);

  const fetchRoleResources = async () => {
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
    if (projId != undefined && projId > 0) {
      setLoadingRoleGrid(true);
      const data = await getProjectAllRoleResources(projId);
      setLoadingRoleGrid(false);
      if (data) {
        setAllRoleResources(data);
      }
    }
    else {
      setAllRoleResources([]);
    }
    setTempRefreshRoles(getRandomNumber());
  };

  const toggleRoleResCreateEditModal = () => {
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
    if (projId != undefined && projId > 0) {
      setCurrentRoleResource(prevData => ({ ...prevData, ProjectId: projId ?? 0 }));
      setShowRoleResCreateEditModal(!showRoleResCreateEditModal);
    }
    else
      toast.error("Add project header before add role and resource", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
  };

  const handleCreateEditSave = (projectRolesResource: IProjectRolesResource, copyResourceAtEffort: boolean): boolean => {
    try {
      setLoadingRoleGrid(true);
      saveRoleResource(projectRolesResource, copyResourceAtEffort);
      setTempRefreshRoles(getRandomNumber());
    } catch (error) {
      console.error('API call failed:', error);
    }
    return true;
  };

  const saveRoleResource = async (projectRolesResource: IProjectRolesResource, copyResourceAtEffort: boolean) => {
    let [isSuccess, message] = [false, ''];
    if (projectRolesResource.RoleResourceId) {
      [isSuccess, message] = await updateProjectRoleResource(projectRolesResource);
    }
    else {
      [isSuccess, message] = await saveProjectRoleResource(projectRolesResource, copyResourceAtEffort);
    }

    if (isSuccess) {
      toast.success("Saved Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
      if (copyResourceAtEffort) {
        setRefreshLevelOfEstimationGblCtx(getRandomNumber());
      }
      await fetchRoleResources();
    }
    else {
      toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
    }
    setLoadingRoleGrid(false);
    return [isSuccess, message]
  };

  const deleteRoleResource = async (projectRolesResource: IProjectRolesResource) => {
    try {
      let [isSuccess, message] = [false, ''];
      if (projectRolesResource) {
        setLoadingRoleGrid(true);
        [isSuccess, message] = await deleteProjectRoleResource(projectRolesResource);
        if (isSuccess) {
          setRefreshLevelOfEstimationGblCtx(getRandomNumber());
          setTempRefreshRoles(getRandomNumber());
          await fetchRoleResources();
          toast.success(`Role '${projectRolesResource.PCRRoleRate?.Role.Name}' Successfully`, { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
        } else {
          toast.error("Error: " + message, { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setLoadingRoleGrid(false);
      }
    } catch (error) {
      console.error('API call failed:', error);
    }
  };

  const handleRoleRateApplyDiscount = async (discountPercent: number) => {
    try {
      let [isSuccess, message] = [false, ''];
      if (projectId && projectId > 0 && (discountPercent != undefined && discountPercent <= 100 && discountPercent > -100)) {
        setLoadingRoleGrid(true);
        [isSuccess, message] = await applyRoleRateDiscount(projectId, discountPercent);
        if (isSuccess) {
          setRefreshLevelOfEstimationGblCtx(getRandomNumber());
          setTempRefreshRoles(getRandomNumber());
          await fetchRoleResources();
          toast.success(`Applied Discount '${discountPercent}%' Successfully`, { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
          setShowRoleRateDiscountModal(false);
        } else {
          toast.error("Error: " + message, { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setLoadingRoleGrid(false);
      } else {
        toast.error(`Invalid input`, { autoClose: 20000, position: toast.POSITION.TOP_CENTER })
      }
    } catch (error) {
      console.error('API call failed:', error);
    }
  };

  const handleDeleteSave = (projectRolesResource: IProjectRolesResource): boolean => {
    try {
      deleteRoleResource(projectRolesResource);
    } catch (error) {
      console.error('API call failed:', error);
    }
    return true;
  };

  const calculateAvgSTDRate = (): number | null => {
    if (allRoleResources?.length > 0) {
      let totalSTD = 0;
      allRoleResources?.map((res) => {
        totalSTD = totalSTD + (res.STDRate ?? 0)
      });
      return (totalSTD / allRoleResources.length);
    } else {
      return null;
    }
  };

  const calculateAvgOfferedRate = (): number | null => {
    if (allRoleResources?.length > 0) {
      let totalOfferedRate = 0;
      allRoleResources?.map((res) => {
        totalOfferedRate = totalOfferedRate + (res.OfferedRate ?? 0)
      });
      return (totalOfferedRate / allRoleResources.length);
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="card shadow mb-1">
        {/*  <!-- Card Header - Dropdown --> */}
        <div
          className="card-header py-1 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Roles, Resource, and Rates</h6>
          <div className="dropdown no-arrow">
            <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
              aria-labelledby="dropdownMenuLink">
              <div className="dropdown-header">Manage Role Resource:</div>
              {!hideAddRoleLink && <a className="dropdown-item" onClick={toggleRoleResCreateEditModal}>Add Role</a>}

              <div className="dropdown-divider"></div>
              {!hideDiscountLink && allRoleResources?.length > 0 && <a onClick={() => setShowRoleRateDiscountModal(true)} className="dropdown-item">
                Apply Discount
              </a>
              }
            </div>
          </div>
        </div>
        <div className={loadingRoleGrid ? 'card-body table-fade' : 'card-body'}>
          {loadingRoleGrid ? (<LoadingIcon />) : ''}
          <div>
            <RolesResourceDetails projectId={currentProjectIdGblCtx} onDeleteSave={handleDeleteSave} onEditSave={handleCreateEditSave} allRoleResources={allRoleResources} />
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center">
            <div style={{ textAlign: 'center', paddingTop: '10px' }}>

              {!hideAddRoleLink && <a onClick={toggleRoleResCreateEditModal} className="w-100" style={{ cursor: 'pointer', paddingRight: '20px' }}>
                <i className="fas fa-plus"></i> Add Role
              </a>
              }
              <span className="mr-5">
                <i className="fas fa-circle"></i>Avg STD Rate: <strong>{formatToUSCurrency(avgSTD)}</strong>
              </span>
              <span className="mr-5">
                <i className="fas fa-circle" style={{ color: avgOfferedRateColor ?? '' }}></i>Avg Offered Rate: <strong style={{ color: avgOfferedRateColor ?? '' }}>{formatToUSCurrency(avgOfferedRate)}</strong>
              </span>
              <span className="mr-5">
                <i className="fas fa-user fa-1x text-gray-300 text-primary mr-1"></i><strong>{allRoleResources?.length}</strong>
              </span>
              {!hideDiscountLink && allRoleResources?.length > 0 && <a onClick={() => setShowRoleRateDiscountModal(true)} className="w-100" style={{ cursor: 'pointer' }}>
                <i className="fas fa-percent"></i> Apply Discount
              </a>
              }
              <div>
                {showRoleResCreateEditModal && (
                  <RoleResourceCreate show={showRoleResCreateEditModal} onClose={toggleRoleResCreateEditModal} onSave={handleCreateEditSave} initialRoleResource={currentRoleResource} />
                )}
              </div>
              <div>
                {showRoleRateDiscountModal && (
                  <ApplyRoleDiscountModal show={showRoleRateDiscountModal} onClose={() => setShowRoleRateDiscountModal(p => !p)} onApplyDiscount={handleRoleRateApplyDiscount} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RoleResourceManage;