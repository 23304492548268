import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useProjectDataContext } from "../../data-context/project-data-context";
import { useState } from "react";
import { createProjectVersion } from "../_services/dashboard-svc";
import { getRandomNumber } from "../../utilities/helper-function";
import { useNavigate } from 'react-router-dom';

const ProjectVersionCreateModal: React.FC<{
    show: boolean;
    onClose: () => void;
    projectId: number;
    parentProjectId?: number | null;
}> = ({ show, onClose, projectId, parentProjectId }) => {

    const { setCurrentProjectIdGblCtx, currentProjectIdGblCtx, setRefreshProjectVersionGblCtx } = useProjectDataContext();
    const [userComment, setUserComment] = useState<string>('');
    const [versionName, setVersionName] = useState<string>('');
    const [disabledDuplicateButton, setDisabledDuplicateButton] = useState<boolean>(false);
    const [useLatestPCRRate, setUseLatestPCRRate] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const navigate = useNavigate();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name == "versionName") {
            setVersionName(value);
        }
        if (name == "userComment") {
            setUserComment(value);
        }

        if (validationErrors[name]) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }

        validateFormData(name, value);
    };

    const validateFormData = (name: string, value: string) => {
        const errors: { [key: string]: string } = {};

        if (!versionName) {
            errors.versionName = 'Version name is required';
        }
        if (versionName && (versionName.trim().length <= 0 || versionName.trim().length > 20)) {
            errors.versionName = 'Version name should be 0-20 charecter';
        }

        if (userComment && (userComment.trim().length <= 0 || userComment.trim().length > 200)) {
            errors.userComment = 'Comment should be 0-200 charecter';
        }

        if (validationErrors[name]) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }

        return errors;
    }

    const handleCreateVersion = async () => {
        setDisabledDuplicateButton(true);
        const errors = validateFormData("", "");
        if (Object.keys(errors).length === 0) {
            toast.info('Creating versioning...', { toastId: 'info1', autoClose: 1000, position: toast.POSITION.TOP_CENTER });

            if (projectId && projectId > 0) {
                let [isSuccess, message, error] = await createProjectVersion(projectId, userComment, versionName, useLatestPCRRate, parentProjectId);
                if (isSuccess) {
                    toast.success("Project version created successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                    //setCurrentProjectIdGblCtx(parseInt(message)); 
                    setUserComment('');
                    setVersionName('');
                    setUseLatestPCRRate(false);
                    onClose();
                    setRefreshProjectVersionGblCtx(getRandomNumber());
                    navigate(`/project-dashboard/${parseInt(message)}`);
                }
                else {
                    toast.error("Error:" + error, { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
                }
            }
        } else {
            setValidationErrors(errors);
        }
        setDisabledDuplicateButton(false);
    };

    const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
        <>
            <Modal onClick={handleModalClick} show={show} onHide={onClose} onClose={onClose} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Project New Version</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group controlId="formVersionName">
                                    <Form.Label>Version Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="versionName"
                                        value={versionName}
                                        isInvalid={!!validationErrors.versionName}
                                        onChange={handleInputChange}
                                        placeholder="Enter your version name..."
                                    />
                                </Form.Group>
                            </div>
                            {
                                validationErrors.versionName && <p style={{ color: 'red' }} className="error-message">{validationErrors.versionName}*</p>
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-11">
                                <Form.Group controlId="fromUserComment">
                                    <Form.Label>User Comment</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="userComment"
                                        value={userComment}
                                        onChange={handleInputChange}
                                        isInvalid={!!validationErrors.userComment}
                                        rows={3}
                                        cols={55}
                                        placeholder="Enter your comment here..."
                                    />
                                </Form.Group>
                            </div>
                            {
                                validationErrors.userComment && <p style={{ color: 'red' }} className="error-message">{validationErrors.userComment}*</p>
                            }
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                            <label className="pl-2 fw-bold">
                                <input
                                    type="checkbox"
                                    name="Risk"
                                    checked={useLatestPCRRate}
                                    onChange={(e) => setUseLatestPCRRate(e.target.checked)}
                                />
                                {'Use Latest PCR Rate'}
                            </label>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ marginTop: '15px' }} variant="secondary" onClick={onClose}>
                        <i className="fas fa-times"></i>  Close
                    </Button>
                    <Button style={{ marginLeft: '10px', marginRight: '10px', marginTop: '14px' }} disabled={disabledDuplicateButton} variant="primary" onClick={handleCreateVersion}>
                        <i className="fas fa-save"></i> Create New Version
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ProjectVersionCreateModal;