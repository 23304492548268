import React, { useEffect, useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import ProjectSummaryGrid from './project-summary-grid';
import { useProjectDataContext } from '../../data-context/project-data-context';
import { useDelayDebounce } from '../../common/hooks/debounce';
import { Link } from 'react-router-dom';

const ProjectSummary: React.FC<{}> = ({ }) => {
  const { setAllProjectRoleUtilizationGblCtx, setCurrentProjectIdGblCtx, setAllPhaseRoleUtilizationGblCtx,
    setCurrentProjectInfoGblCtx, setCurrentProjectParentIdGblCtx } = useProjectDataContext();

  const delayDebouncedValue = useDelayDebounce(100);

  useEffect(() => {
    setCurrentProjectIdGblCtx(undefined);
    setCurrentProjectInfoGblCtx(undefined);
    setAllProjectRoleUtilizationGblCtx([]);
    setAllPhaseRoleUtilizationGblCtx([]);
    setCurrentProjectParentIdGblCtx(undefined);

  }, [delayDebouncedValue])

  return (
    <div>
      <div className="row">
        <ProjectSummaryGrid />
      </div>      
    </div>
  )
}

export default ProjectSummary;