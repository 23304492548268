import * as XLSX from 'xlsx';
import { IProjectEffortHours, IProjectHeaderDetails, IProjectRolesResource } from '../../data-model';
import { calculateDiscount, formatDateAsString, formatToUSCurrency } from '../../utilities/helper-function';
export class CreateEffortReport {
    public writeDataToExcel = async (projectHeaders: IProjectHeaderDetails | null | undefined, allRoleResources: IProjectRolesResource[] | null | undefined, effortHeaders: string[], efforts: string[][]) => {
        const templatePath = process.env.PUBLIC_URL + '/template-excel-file/template.xlsm';

        try {
            const response = await fetch(templatePath);

            if (!response.ok) {
                throw new Error(`Failed to fetch template file: ${response.statusText}`);
            }

            const arrayBuffer = await response.arrayBuffer();

            // Read the template workbook
            const templateWorkbook = XLSX.read(arrayBuffer, { type: 'array' });

            const sheetName = templateWorkbook.SheetNames[0];
            const worksheet = templateWorkbook.Sheets[sheetName];

            //Write Header - Preparer
            if (projectHeaders) {
                XLSX.utils.sheet_add_aoa(worksheet, [[projectHeaders?.Preparer ?? '']], { origin: "B4" });
                XLSX.utils.sheet_add_aoa(worksheet, [[projectHeaders?.DatePrepared ?? '']], { origin: "B5" });
                XLSX.utils.sheet_add_aoa(worksheet, [[projectHeaders?.ExecutiveSponsor ?? '']], { origin: "B7" });
                XLSX.utils.sheet_add_aoa(worksheet, [[projectHeaders?.EngagementManager ?? '']], { origin: "B8" });
                XLSX.utils.sheet_add_aoa(worksheet, [[projectHeaders?.ClientName ?? '']], { origin: "B9" });
                XLSX.utils.sheet_add_aoa(worksheet, [[projectHeaders?.Title ?? '']], { origin: "B10" });
                XLSX.utils.sheet_add_aoa(worksheet, [[formatDateAsString(projectHeaders?.StartDate)]], { origin: "B11" });

                if (projectHeaders.StartDate && projectHeaders.StartDate.getDay() != 1) {
                    XLSX.utils.sheet_add_aoa(worksheet, [["Start date is not monday. Please correct it"]], { origin: "C11" });
                }

                XLSX.utils.sheet_add_aoa(worksheet, [[formatToUSCurrency(projectHeaders?.OfferedPrice)]], { origin: "B13" });
                XLSX.utils.sheet_add_aoa(worksheet, [[projectHeaders?.RiskMultiple?.toFixed(2) ?? '']], { origin: "B16" });
                XLSX.utils.sheet_add_aoa(worksheet, [[calculateDiscount(projectHeaders?.OfferedPrice ?? 0, projectHeaders?.RiskAdjustedServicesFees ?? 0)?.toFixed(2)]], { origin: "B23" });

            }
            //Write all role resources
            let startingRoleResourceRow = 3;
            allRoleResources?.forEach((roleResource, rowIndex) => {
                let roleCell = `F${(rowIndex + startingRoleResourceRow)}`
                XLSX.utils.sheet_add_aoa(worksheet, [[roleResource.PCRRoleRate?.Role.Name]], { origin: roleCell });

                let resourceCell = `G${(rowIndex + startingRoleResourceRow)}`
                XLSX.utils.sheet_add_aoa(worksheet, [[roleResource.Resource]], { origin: resourceCell });

                let stdCell = `H${(rowIndex + startingRoleResourceRow)}`
                XLSX.utils.sheet_add_aoa(worksheet, [[roleResource.STDRate]], { origin: stdCell });

                let altCell = `I${(rowIndex + startingRoleResourceRow)}`
                XLSX.utils.sheet_add_aoa(worksheet, [[roleResource.ALTRate]], { origin: altCell });
            });

            //Write all role resources
            let startingEffortsRow = allRoleResources && allRoleResources?.length > 20 ? allRoleResources.length + 5 : 25;
            effortHeaders?.forEach((header, colIndex) => {
                const cellAddress = XLSX.utils.encode_cell({
                    r: startingEffortsRow,
                    c: colIndex + XLSX.utils.decode_col('A'),
                });
                XLSX.utils.sheet_add_aoa(worksheet, [[header ?? '']], { origin: cellAddress });
            });

            //Write Efforts
            let startingEffortsHoursRow = startingEffortsRow + 1;
            efforts.forEach((effort, rowIndex) => {
                effort.forEach((value, colIndex) => {
                    const cellAddress = XLSX.utils.encode_cell({
                        r: rowIndex + startingEffortsHoursRow,
                        c: colIndex + XLSX.utils.decode_col('A'),
                    });

                    const integerPattern: RegExp = /^-?\d+(\.\d+)?$/;
                    if (value && integerPattern.test(value)) {
                        XLSX.utils.sheet_add_aoa(worksheet, [[parseFloat(value)]], { origin: cellAddress });
                    } else {
                        XLSX.utils.sheet_add_aoa(worksheet, [[value]], { origin: cellAddress });
                    }
                });
            });

            XLSX.writeFile(templateWorkbook, `${projectHeaders?.Title ?? 'efforts'}.xlsm`);
        } catch (error) {
            console.error('Error:', error);
        }
    }
}