import { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { IPCRRoleRate } from "../../data-model";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { formatDateAsString, formatDateTimeAsString } from "../../utilities/helper-function";
import { getPCRHistoryRoleId } from "../_services/pcr-rates-svc";

const PCRRateHistoryModal: React.FC<{
    show: boolean;
    onClose: (isSuccess: boolean) => void;
    pcrRoleId: number | undefined;
}> = ({ show, onClose, pcrRoleId }) => {
    const [allPCRRoleRate, setAllPCRRoleRate] = useState<IPCRRoleRate[] | undefined>();
    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        if (pcrRoleId && pcrRoleId > 0) {
            fetchPCRHistoryRoleId();
        }
    }, [pcrRoleId]);

    const fetchPCRHistoryRoleId = async () => {
        setLoading(true);
        const data = await getPCRHistoryRoleId(pcrRoleId);
        if (data) {
            setAllPCRRoleRate(data);
        }
        setLoading(false);
    };

    const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
        <div id="auditTransaction" className='auditTransaction'>
            <Modal onClick={handleModalClick} show={show} onHide={() => onClose(false)} onClose={() => onClose(false)} centered backdrop="static" dialogClassName="modal-lg">
                <Modal.Header closeButton>
                    <h4>PCR Rates History</h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-container pr-3" style={{ minHeight: '450px', maxHeight: '450px', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #ccc' }}>
                        {loading ? (<LoadingIcon />) : ''}
                        <div>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Valid From</th>
                                        <th>Valid To</th>
                                        <th>Rate</th>
                                        <th>Default</th>
                                        <th>Active?</th>
                                        <th>Created By</th>
                                        <th>Create Date</th>
                                        <th>Modified By</th>
                                        <th>Modified Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allPCRRoleRate?.map((item) => (
                                        <tr>
                                            <td>{formatDateAsString(new Date(item.DateFrom))}</td>
                                            <td>{formatDateAsString(new Date(item.DateTo))}</td>
                                            <td>{item.Rate}</td>
                                            <td>{item.IsDefault ? "Yes" : "No"}</td>
                                            <td>{item.IsActive ? "Yes" : "No"}</td>
                                            <td>{item.CreatedBy}</td>
                                            <td>{formatDateTimeAsString(item.CreateDate)}</td>
                                            <td>{item.ModifiedBy}</td>
                                            <td>{formatDateTimeAsString(item.ModifiedDate)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default PCRRateHistoryModal;