import { IProjectEffortHours, IProjectEffortEstimation, IProjectEffortSummary, IProjectHeaderDetails, IProjectRolesResource, IRole, ISkillBase, IPCRRoleRate, IProjectStatus } from "../../data-model";
import { IApprover } from "../../data-model/approver";
import { ISkillLevel } from "../../data-model/role-skill-base";

export const initialDefaultRole: IRole = {
  RoleId: 0,
  Name: '',
  Code: '',
  Description: '',
  Skills: null,
  IsActive: true,
}

export const initialDefaultApprover: IApprover = {
  IsActive: true
}

export const initialDefaultStatus: IProjectStatus = {
  StatusId: -1,
  Status: null,
  Description: null
}

export const initialDefaultSkillBase: ISkillBase = {
  RoleId: 0,
  Skill: '',
  SkillBaseId: null,
  IsActive: true,
  Roles: null
}

export const initialDefaultRoleResource: IProjectRolesResource = {
  ProjectId: 0,
  RoleResourceId: 0,
  PCRRoleRateId: undefined,
  PCRRoleRate: undefined,
  Resource: '',
  SelectedSkills: [],
  Notes: undefined,
  STDRate: 0,
  ALTRate: null,
  OfferedRate: 0,
  Discount: null
};

export function initialDefaultIProjectEffortEstimation(): IProjectEffortEstimation {
  return {
    RoleResourceEstimateId: 0,
    ProjectId: 0,
    Phase: '',
    SubPhase: '',
    Order: 0,
    ProjectRoleResourceId: null,
    PrjectRoleResourceDetail: undefined,
    TotalHours: 0,
    TotalFees: 0,
    EffortByWeek: null
  }
};

export const defaultProjectEffortSummary: IProjectEffortSummary = {
  ProjectCost: 0,
  QualityError: [],
  TotalHours: 0,
  TotalResource: 0,
  TotalUniqueRole: 0,
  ProjectSTDCost: 0,
  ProjectId: undefined
}

export const defaultProjectHeaderDetails: IProjectHeaderDetails = {
  ProjectId: 0,
  Preparer: '',
  DatePrepared: null,
  ExecutiveSponsor: '',
  EngagementManager: '',
  HubspotClientId: null,
  HubspotDealName: '',
  HubspotDealId: null,
  ClientName: '',
  Title: '',
  Status: null,
  StartDate: null,
  OfferedPrice: null,
  ProposedFeeStructure: "",
  EstimatedServicesFeesAltRate: null,
  RiskMultiple: null,
  RiskAdjustedServicesFees: null,
  RiskAdjustedServicesFeesStdRate: null,
  NetOfferedBlendedRate: null,
  MaximumWIPAccumulationDollar: null,
  Version: null,
  VersionName: null,
  VersionUserComment: null,
  ParentProjectId: null,
  IsProjectDeleteApplicable: null,
  IsActive: true
};

export const getProjectEffortHours = (roleResourceEstimateId: number, weekId: number, weekNumber: number, effortHours: number): IProjectEffortHours => {
  return {
    RoleResourceEstimateId: roleResourceEstimateId,
    WeekId: weekId,
    WeekNumber: weekNumber,
    DateFrom: null,
    DateTo: null,
    EffortHours: effortHours,
  };
}

export const allMasterSkillLevel: ISkillLevel[] = [{
  Name: "Beginner",
  Value: 1
},
{
  Name: "Novice",
  Value: 2
},
{
  Name: "Intermediate",
  Value: 3
},
{
  Name: "Advanced",
  Value: 4
},
{
  Name: "Expert",
  Value: 5
}
];