import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IInvoiceFrequency, IInvoiceFrequencyData, IInvoiceSchedule, IProjectEffortEstimation, IProjectEffortHours, IProjectPhaseUtilization } from "../../data-model";
import { getAllMasterInvoiceFrequency } from "../_services/master-data-svc";
import { useDelayDebounce } from "../../common/hooks/debounce";
import {
    IFrequencyRange, areObjectsEqual, calculateEndDate, calculateWeeksDifference, compareDatesOnly,
    formatDateAsString,
    formatToUSCurrency, getBiWeeklyRanges, getMonthsInRange, getQuartersInRange, getRandomNumber,
    getWeekDateRangeByWeekNumber, getWeekdaysInRange, getWeeksInRange, getYearsInRange,
    isProjectReadOnlyStatus,
    isUserRoleReadOnly,
    parseDateAsStringFormat
} from "../../utilities/helper-function";
import { useProjectDataContext } from "../../data-context/project-data-context";
import IInvoiceVerticleChart from "./invoice-verticle-chart";
import { toast } from "react-toastify";
import { getAllWeeklyEffort } from "../_services/weekly-effort-svc";
import ConfirmationModal from "../../common/confirmation-dialog";
import { getProjectAllRoleResourceEstimation } from "../_services/role-resource-estimation-svc";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import ModifyOfferedPriceModal from "../project-header-manage/modify-offered-price";
import { getInvoiceSchedule, saveNewInvoiceSchedule, updateInvoiceSchedule } from "../_services/invoice-schedule-svc";
import { getProjectHeader } from "../_services/dashboard-svc";
import { Packer } from "docx";
import saveAs from "file-saver";
import { InvoiceScheduleReport } from "./invoice-schedule-report";
import { PhaseEffort } from "../../common/class/phase-effort";

const InvoiceScheduleManage: React.FC<{
}> = ({ }) => {

    const { currentProjectIdGblCtx, setCurrentProjectIdGblCtx, setCurrentProjectInfoGblCtx, setRefreshProjectStatusGblCtx,
        currentProjectInfoGblCtx, refreshProjectStatusGblCtx, userAccessInfoGblCtx } = useProjectDataContext();
    const delayDebouncedInitialValue = useDelayDebounce(50);

    const [allInitialRoleResourseEstimation, setAllInitialRoleResourseEstimation] = useState<IProjectEffortEstimation[]>([]);
    const [allProjectPhaseUtilization, setAllProjectPhaseUtilization] = useState<IProjectPhaseUtilization[]>([]);
    const [initialInvoiceScheduleData, setInitialInvoiceScheduleData] = useState<IInvoiceSchedule>();
    const [invoiceScheduleData, setInvoiceScheduleData] = useState<IInvoiceSchedule | undefined>();
    const [allInitialEffortHours, setAllInitialEffortHours] = useState<IProjectEffortHours[]>([]);
    const [allMasterFrequencies, setAllMasterFrequencies] = useState<IInvoiceFrequency[] | null>();
    const [selectedFrequency, setSelectedFrequency] = useState<IInvoiceFrequency | null>();
    const [readonlyUIDisabled, setReadonlyUIDisabled] = useState<boolean>(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
    const [loadingIconEffortGrid, setLoadingIconEffortGrid] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const [showChangeFrequencyModal, setShowChangeFrequencyModal] = useState<boolean>(false);
    const [tempSelectedFrequencies, setTempSelectedFrequencies] = useState<IInvoiceFrequency | null>();
    const [refreshInvoiceScheduleCost, setRefreshInvoiceScheduleCost] = useState<number>(0);
    const [refreshInitialDataLoad, setRefreshInitialDataLoad] = useState<number>(0);
    const [totalInvoiceCost, setTotalInvoiceCost] = useState<number>(0);
    const [totalALTCost, setTotalALTCost] = useState<number>(0);
    const [maximumWIPAccumulation, setMaximumWIPAccumulation] = useState<number>(0);

    const [currentProjectId, setCurrentProjectId] = useState<number | undefined>(undefined);
    const [showOfferedPriceModal, setShowOfferedPriceModal] = useState<boolean>(false);
    const [disabledDownloadReport, setDisabledDownloadReport] = useState<boolean>(true);

    var phaseEffort = new PhaseEffort();

    const errors: { [key: string]: string } = {};

    const { projectId } = useParams<{ projectId: string }>();

    useEffect(() => {
        if (projectId) {
            setCurrentProjectId(parseInt(projectId));
        }
    }, [projectId]);

    useEffect(() => {
        if (delayDebouncedInitialValue && delayDebouncedInitialValue > 0) {
            fetchProjectHeader();
            fetchProjectInvoiceSchedule();
            fetchWeeklyEfforts();
            fetchAllRoleResourceEffortEstimations();
        }
        if (delayDebouncedInitialValue > 0 && (allMasterFrequencies == null || allMasterFrequencies?.length == 0)) {
            fetchAllMasterFrequencies();
        }
    }, [delayDebouncedInitialValue]);

    useEffect(() => { //Make sure everything loaded
        if (refreshInvoiceScheduleCost > 0 && allInitialRoleResourseEstimation && allInitialRoleResourseEstimation.length > 0 && allInitialEffortHours
            && allInitialEffortHours.length > 0 && invoiceScheduleData && allMasterFrequencies && allMasterFrequencies.length > 0) {
            preparedInvoiceScheduleData();
        }

    }, [refreshInvoiceScheduleCost]);

    useEffect(() => { //Make sure everything loaded
        if (refreshInitialDataLoad > 0 && allInitialRoleResourseEstimation && allInitialRoleResourseEstimation.length > 0
            && allInitialEffortHours && allInitialEffortHours.length > 0 && allMasterFrequencies && allMasterFrequencies.length > 0) {

            if (invoiceScheduleData && invoiceScheduleData.InvoiceScheduleId && invoiceScheduleData.InvoiceScheduleId > 0) {
                let frequency = allMasterFrequencies?.filter((f) => f.FrequencyId == invoiceScheduleData?.Frequency?.FrequencyId)[0];
                if (frequency) {
                    setSelectedFrequency(frequency);
                    setInvoiceScheduleData({
                        ...invoiceScheduleData,
                        Frequency: frequency
                    });
                }
            }
            let allWeekNumbers = allInitialEffortHours?.map(effort => effort.WeekNumber);
            let maxWeek = Math.max(...allWeekNumbers ?? [], 0);
            let endDateMaxWeek: number = calculateWeeksDifference(currentProjectInfoGblCtx?.StartDate, currentProjectInfoGblCtx?.EndDate);
            if (maxWeek < endDateMaxWeek) {
                maxWeek = endDateMaxWeek;
            }
            let endDate = calculateEndDate(currentProjectInfoGblCtx?.StartDate, maxWeek);

            setInvoiceScheduleData({
                ...invoiceScheduleData,
                TotalWeekNumber: maxWeek,
                StartDate: currentProjectInfoGblCtx?.StartDate,
                EndDate: endDate
            });
            setRefreshInvoiceScheduleCost(getRandomNumber());
            setTotalALTCost(currentProjectInfoGblCtx?.EstimatedServicesFeesAltRate ?? 0);

            let phaseUtilization = phaseEffort.getProjectPhaseUtilization(allInitialRoleResourseEstimation, allInitialEffortHours);
            if (phaseUtilization && phaseUtilization.length > 0) {
                setAllProjectPhaseUtilization(phaseUtilization);
            }
        }

    }, [refreshInitialDataLoad]);

    useEffect(() => {
        if (refreshProjectStatusGblCtx && refreshProjectStatusGblCtx > 0) {
            setReadonlyUIDisabled(isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) || isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles));
        }
    }, [refreshProjectStatusGblCtx]);

    useEffect(() => {
        setSaveButtonDisabled(areObjectsEqual(initialInvoiceScheduleData ?? {}, invoiceScheduleData ?? {}));
    }, [initialInvoiceScheduleData, invoiceScheduleData]);

    const getFrequencyDataFromFrequencyRangeByDate = (frequencyRange: IFrequencyRange[]): IInvoiceFrequencyData[] => {
        let invFrequency: IInvoiceFrequencyData[] = frequencyRange.map((record, index) => {
            let existingFrequency = invoiceScheduleData?.ScheduleFrequencies?.filter((f) => f.FrequencyColumnNumber == index + 1)[0];
            if (!existingFrequency) {
                existingFrequency = {
                    FrequencyColumnNumber: index + 1,
                    FromDate: record.startDate,
                    ToDate: record.endDate,
                }
            }
            existingFrequency.LabelText = `${record.name} (${index + 1})`;
            existingFrequency.ProjectedWIPEffortCost = calculateCostBetweenDateRange(record.startDate, record.endDate);
            return existingFrequency;
        });

        return invFrequency;
    }

    const getFrequencyDataFromFrequencyRangeByPhase = (frequencyRange: IFrequencyRange[]): IInvoiceFrequencyData[] => {
        let invFrequency: IInvoiceFrequencyData[] = frequencyRange.map((record, index) => {
            let existingFrequency = invoiceScheduleData?.ScheduleFrequencies?.filter((f) => f.FrequencyColumnNumber == index + 1)[0];
            if (!existingFrequency) {
                existingFrequency = {
                    FrequencyColumnNumber: index + 1,
                    FromDate: record.startDate,
                    ToDate: record.endDate,
                }
            }
            existingFrequency.LabelText = `${record.name}`;
            existingFrequency.ProjectedWIPEffortCost = allProjectPhaseUtilization.filter((f) => f.ProjectPhase == record.name)[0]?.TotalCostUtilize;
            return existingFrequency;
        });

        return invFrequency;
    }

    const preparedInvoiceScheduleData = () => {
        let invFrequency: IInvoiceFrequencyData[] = [];
        if (invoiceScheduleData?.Frequency && invoiceScheduleData?.StartDate && invoiceScheduleData?.EndDate) {
            let rangeData: IFrequencyRange[] | null = null;
            let costCalculationByDate = true;
            switch (invoiceScheduleData?.Frequency.Frequency) {
                case "Weekly":
                    rangeData = getWeeksInRange(invoiceScheduleData.StartDate, invoiceScheduleData?.EndDate, (invoiceScheduleData.TotalWeekNumber ?? 0));
                    break;
                case "BiWeekly":
                    rangeData = getBiWeeklyRanges(invoiceScheduleData.StartDate, invoiceScheduleData?.EndDate);
                    break;
                case "Monthly":
                    rangeData = getMonthsInRange(invoiceScheduleData.StartDate, invoiceScheduleData?.EndDate);
                    break;
                case "Quarterly":
                    rangeData = getQuartersInRange(invoiceScheduleData.StartDate, invoiceScheduleData?.EndDate);
                    break;
                case "Yearly":
                    rangeData = getYearsInRange(invoiceScheduleData.StartDate, invoiceScheduleData?.EndDate);
                    break;
                case "ByPhase":
                    costCalculationByDate = false;
                    rangeData = getPhaseInRange(invoiceScheduleData.StartDate, invoiceScheduleData?.EndDate);
                    break;
                default:
                    console.error('No matched frequency.');
                    break;
            }
            if (rangeData && rangeData.length > 0) {
                if (costCalculationByDate) {
                    invFrequency = getFrequencyDataFromFrequencyRangeByDate(rangeData);
                    invFrequency = calculateAndUpdateAllTotalDataByDate(invFrequency);
                    calculateTotalInvoiceAmount(invFrequency ?? []);
                    calculateMaxWIPAccumulation(invFrequency ?? []);
                } else {
                    invFrequency = getFrequencyDataFromFrequencyRangeByPhase(rangeData);
                    invFrequency = calculateAndUpdateAllTotalDataByPhase(invFrequency);
                    calculateTotalInvoiceAmount(invFrequency ?? []);
                    calculateMaxWIPAccumulation(invFrequency ?? []);
                }
            }
        }
    }

    const getPhaseInRange = (startDate: Date, endDate: Date): IFrequencyRange[] => {
        const ranges: IFrequencyRange[] = [];
        allProjectPhaseUtilization?.map((phase) => {
            if (phase.TotalTimeUtilize > 0 && phase.TotalCostUtilize > 0) {
                const monthRange: IFrequencyRange = {
                    name: phase.ProjectPhase,
                    startDate: phase.PhaseStartDate ?? startDate,
                    endDate: phase.PhaseEndDate ?? endDate
                };
                ranges.push(monthRange);
            }
        });
        return ranges;
    };

    const calculateAndUpdateAllTotalDataByPhase = (frequencies: IInvoiceFrequencyData[]): IInvoiceFrequencyData[] => {
        if (frequencies && frequencies.length > 0) {
            let totalInvoiceCost: number = 0
            let totalProjectedWIPCost: number = 0
            let preparedData = frequencies?.map((invData) => {
                totalInvoiceCost = totalInvoiceCost + (invData?.InvoiceAmount != null ? invData.InvoiceAmount : 0);
                totalProjectedWIPCost = totalProjectedWIPCost + (invData?.ProjectedWIPEffortCost != null ? invData.ProjectedWIPEffortCost : 0);
                let modData: IInvoiceFrequencyData = {
                    ...invData,
                    TotalInvoiceScheduleCost: totalInvoiceCost,
                    TotalProjectedWIPCost: totalProjectedWIPCost,
                    ProjectedVsInvoiceDiff: (totalProjectedWIPCost ?? 0) - (totalInvoiceCost ?? 0)
                }
                return modData
            });

            setInvoiceScheduleData({ ...invoiceScheduleData, ScheduleFrequencies: (preparedData ?? []), MaximumWIPAccumulation: calculateMaxWIPAccumulation(preparedData) });
            return preparedData;
        }
        return [];
    }

    const calculateAndUpdateAllTotalDataByDate = (frequencies: IInvoiceFrequencyData[]): IInvoiceFrequencyData[] => {
        if (frequencies && frequencies.length > 0) {
            let totalInvoiceCost: number = 0
            let totalProjectedWIPCost: number = 0
            let preparedData = frequencies?.map((invData) => {
                totalInvoiceCost = totalInvoiceCost + (invData?.InvoiceAmount != null ? invData.InvoiceAmount : 0);
                totalProjectedWIPCost = totalProjectedWIPCost + (invData?.ProjectedWIPEffortCost != null ? invData.ProjectedWIPEffortCost : 0);
                let modData: IInvoiceFrequencyData = {
                    ...invData,
                    TotalInvoiceScheduleCost: totalInvoiceCost,
                    TotalProjectedWIPCost: totalProjectedWIPCost,
                    ProjectedVsInvoiceDiff: (totalProjectedWIPCost ?? 0) - (totalInvoiceCost ?? 0)
                }
                return modData
            });

            setInvoiceScheduleData({ ...invoiceScheduleData, ScheduleFrequencies: (preparedData ?? []), MaximumWIPAccumulation: calculateMaxWIPAccumulation(preparedData) });
            return preparedData;
        }
        return [];
    }

    const calculateMaxWIPAccumulation = (frequencies: IInvoiceFrequencyData[]): number => {
        const validFrequencies = frequencies?.filter(data => data?.ProjectedVsInvoiceDiff && data?.ProjectedVsInvoiceDiff > 0);

        const maxTotalProjectedWIPCost = validFrequencies?.reduce((max, data) =>
            (data?.ProjectedVsInvoiceDiff != null && data.ProjectedVsInvoiceDiff > max ? data.ProjectedVsInvoiceDiff : max), 0
        );

        setMaximumWIPAccumulation(maxTotalProjectedWIPCost);
        return maxTotalProjectedWIPCost;
    }

    const calculateCostBetweenDateRange = (fromDate: Date, toDate: Date): number => {
        const totalCost = allInitialEffortHours.reduce((totalCost, effort) => {
            if (effort.DateFrom && effort.DateTo && fromDate && toDate) {
                let totalHours = 0;
                if ((compareDatesOnly(effort.DateFrom, fromDate) >= 0 && compareDatesOnly(toDate, effort.DateFrom) > 0)
                    && (compareDatesOnly(effort.DateTo, fromDate) > 0 && compareDatesOnly(toDate, effort.DateTo) >= 0)) {
                    totalHours = effort.EffortHours;
                } else if (compareDatesOnly(effort.DateFrom, fromDate) >= 0 && compareDatesOnly(toDate, effort.DateFrom) >= 0 && compareDatesOnly(effort.DateTo, toDate) > 0) { //unmatched will be split sharing
                    let weekDays = getWeekdaysInRange(effort.DateFrom, toDate);
                    if (weekDays && weekDays.length > 0) {
                        totalHours = Math.round((effort.EffortHours * weekDays.length) / 5);
                    }
                } else if (compareDatesOnly(effort.DateTo, fromDate) >= 0 && compareDatesOnly(effort.DateTo, fromDate) >= 0 && compareDatesOnly(toDate, effort.DateTo) > 0) { //unmatched will be split sharing
                    let weekDays = getWeekdaysInRange(fromDate, effort.DateTo);
                    if (weekDays && weekDays.length > 0) {
                        totalHours = Math.round((effort.EffortHours * weekDays.length) / 5);
                    }
                }
                if (totalHours >= 0) {
                    let matchedResource = allInitialRoleResourseEstimation.filter((resource) => resource.RoleResourceEstimateId == effort.RoleResourceEstimateId)[0];
                    if (matchedResource && matchedResource?.PrjectRoleResourceDetail && matchedResource?.PrjectRoleResourceDetail?.OfferedRate > 0) {
                        totalCost = totalCost + matchedResource?.PrjectRoleResourceDetail?.OfferedRate * totalHours;
                    }
                }
            }

            return totalCost;
        }, 0);

        return totalCost;
    }

    const fetchProjectHeader = async () => {
        if (currentProjectId !== undefined && currentProjectId > 0 && !currentProjectInfoGblCtx) {
            setLoadingIconEffortGrid(true);
            const data = await getProjectHeader(currentProjectId);
            if (data) {
                setCurrentProjectIdGblCtx(currentProjectId ?? undefined);
                setCurrentProjectInfoGblCtx(data);
                setRefreshProjectStatusGblCtx(getRandomNumber());
                setRefreshInitialDataLoad(getRandomNumber());
            }
        }
        setLoadingIconEffortGrid(false);
    };

    const fetchWeeklyEfforts = async () => {
        setLoadingIconEffortGrid(true);
        let projectId = currentProjectId != undefined && currentProjectId > 0 ? currentProjectId : currentProjectIdGblCtx;
        const data = await getAllWeeklyEffort(projectId);

        setLoadingIconEffortGrid(false);
        if (data) {
            setAllInitialEffortHours(data);
        } else {
            setAllInitialEffortHours([]);
        }
        setRefreshInitialDataLoad(getRandomNumber());
    };

    const fetchAllMasterFrequencies = async () => {
        try {
            setLoadingIconEffortGrid(true);
            let data = await getAllMasterInvoiceFrequency();
            if (data) {
                setAllMasterFrequencies(data);
                setRefreshInvoiceScheduleCost(getRandomNumber());
            }
        } catch (error) {
            console.error('Error fetching frequencies:', error);
        }
        setLoadingIconEffortGrid(false);
        setRefreshInitialDataLoad(getRandomNumber());
    };

    const fetchProjectInvoiceSchedule = async () => {
        try {
            setLoadingIconEffortGrid(true);
            let initialSchedule = await getInvoiceSchedule(currentProjectId ?? 0);
            if (initialSchedule) {
                setDisabledDownloadReport(false);
                setInitialInvoiceScheduleData(initialSchedule);
                setInvoiceScheduleData(initialSchedule);
            } else {
                setDisabledDownloadReport(true);
            }
        } catch (error) {
            console.error('Error fetching project invoice schedule:', error);
        }
        setRefreshInitialDataLoad(getRandomNumber());
        setLoadingIconEffortGrid(false);
    };

    const fetchAllRoleResourceEffortEstimations = async () => {
        setLoadingIconEffortGrid(true);
        setAllInitialRoleResourseEstimation([]);
        let projId = currentProjectId != undefined && currentProjectId > 0 ? currentProjectId : currentProjectIdGblCtx;
        if (projId != undefined && projId > 0) {
            setLoadingIconEffortGrid(true);
            const data = await getProjectAllRoleResourceEstimation(projId);
            setLoadingIconEffortGrid(false);
            if (data) {
                setAllInitialRoleResourseEstimation(data);
            }
        }
        setRefreshInitialDataLoad(getRandomNumber());
        setLoadingIconEffortGrid(false);
    };

    const confirmFrequencyChange = () => {
        if (tempSelectedFrequencies) {
            setFrequency(tempSelectedFrequencies);
        }
    };

    const setFrequency = (tempSelectedFrequencies: IInvoiceFrequency | null) => {
        setSelectedFrequency(tempSelectedFrequencies);
        setTotalInvoiceCost(0);
        setInvoiceScheduleData({
            ...invoiceScheduleData,
            Frequency: tempSelectedFrequencies,
            ScheduleFrequencies: []
        });

        setRefreshInitialDataLoad(getRandomNumber());
        setShowChangeFrequencyModal(false);
    }

    const cancelFrequencyChange = () => {
        setShowChangeFrequencyModal(false);
    };

    const handleFrequencyDrpInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setValidationErrors({});
        let frequencyId = parseInt(value);
        let matchedFrequency = allMasterFrequencies?.filter((f) => f.FrequencyId == frequencyId)[0];
        if (selectedFrequency && matchedFrequency && matchedFrequency.FrequencyId != selectedFrequency?.FrequencyId) {
            setTempSelectedFrequencies(matchedFrequency);
            setShowChangeFrequencyModal(true);
        } else {
            setFrequency(matchedFrequency ?? null);
        }
    }

    const calculateTotalInvoiceAmount = (frequencies: IInvoiceFrequencyData[]) => {
        const sumFrequencyInvoiceCost = frequencies?.reduce((sum, data) => {
            if (data.InvoiceAmount !== null && !isNaN(data.InvoiceAmount ?? 0)) {
                return sum + (data.InvoiceAmount ?? 0);
            }
            return sum;
        }, 0) ?? 0;
        setTotalInvoiceCost(sumFrequencyInvoiceCost);
    }

    const handleInputChangeInvoiceCost = (event: any, invoiceFrequencyData: IInvoiceFrequencyData) => {
        const { name, value } = event.target;
        let cost = parseInt(value);
        if (isNaN(parseInt(value))) {
            cost = 0;
        }
        if (cost >= 0) {
            calculateTotalInvoiceAmount(invoiceScheduleData?.ScheduleFrequencies ?? []);
            let total = totalInvoiceCost + cost - (invoiceFrequencyData.InvoiceAmount ?? 0);
            let isValid = total <= (currentProjectInfoGblCtx?.OfferedPrice ?? 0);
            if (isValid) {
                let updatedFrequency = invoiceScheduleData?.ScheduleFrequencies?.map((frequency, index) => {
                    if (frequency.FrequencyColumnNumber == invoiceFrequencyData.FrequencyColumnNumber) {
                        return {
                            ...frequency,
                            InvoiceAmount: cost > 0 ? cost : null
                        };
                    }
                    return frequency;
                });
                setInvoiceScheduleData({
                    ...invoiceScheduleData,
                    ScheduleFrequencies: updatedFrequency
                });
                setValidationErrors({});
            } else {
                let message = `Total invoice schedule cost(${formatToUSCurrency(total)}) should be less than/equal project offered cost(${formatToUSCurrency(currentProjectInfoGblCtx?.OfferedPrice ?? 0)})`;
                errors[name] = message;
                setValidationErrors(errors);
                toast.error(message, { toastId: 'error1', style: { backgroundColor: 'white' }, autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
            setRefreshInvoiceScheduleCost(getRandomNumber());
        } else {
            let message = `Invoice schedule cost(${formatToUSCurrency(cost ?? 0)}) should be greater than 0`;
            errors[name] = message;
            setValidationErrors(errors);
            toast.error(message, { toastId: 'error1', style: { backgroundColor: 'white' }, autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
    }

    const handleDoubleClick = () => {
        setShowOfferedPriceModal(true);
    };

    const resetInvoiceSchedule = () => {
        setInvoiceScheduleData(initialInvoiceScheduleData);
        setTotalInvoiceCost(0);
        setSelectedFrequency(undefined);
        setMaximumWIPAccumulation(0);
        setRefreshInitialDataLoad(getRandomNumber());
    };

    const handleDownloadInvoiceSchedule = async () => {
        const invoiceReport = new InvoiceScheduleReport();
        const doc = await invoiceReport.create(initialInvoiceScheduleData);
        Packer.toBlob(doc).then(blob => {
            saveAs(blob, `invoice_schedule_${currentProjectInfoGblCtx?.Title}.docx`);
        });
    };

    const saveOrUpdateInvoiceSchedule = async () => {
        if (invoiceScheduleData) {
            let [isSuccess, message] = [false, ''];
            //setLoadingProjectCreate(true);
            toast.info('Saving invoice schedules...', { toastId: 'info1', autoClose: 1000, position: toast.POSITION.TOP_CENTER });

            if (invoiceScheduleData.InvoiceScheduleId && invoiceScheduleData.InvoiceScheduleId > 0) {
                [isSuccess, message] = await updateInvoiceSchedule(currentProjectId ?? 0, invoiceScheduleData.InvoiceScheduleId, invoiceScheduleData);
            }
            else {
                let createdInvSchId = 0;
                [isSuccess, message] = await saveNewInvoiceSchedule(currentProjectId ?? 0, invoiceScheduleData);
                if (isSuccess) {
                    let invoiceScheduleId = parseInt(message);
                    setInvoiceScheduleData({ ...invoiceScheduleData, InvoiceScheduleId: invoiceScheduleId });
                }
            }
            if (isSuccess) {
                setInitialInvoiceScheduleData(invoiceScheduleData)
                toast.success("Successfully saved", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
            }
            else {
                toast.error("Error occurred", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
        }
    };

    const handleInvoiceScheduleSave = () => {
        if (invoiceScheduleData) {
            saveOrUpdateInvoiceSchedule();
        }
    };

    return (
        <>
            <div>
                <ConfirmationModal
                    show={showChangeFrequencyModal}
                    title="Confirm Change Frequency"
                    message="You will loose all the data. Save the data. Are you sure to change frequency?"
                    onClose={() => setShowChangeFrequencyModal(false)}
                    onCancel={cancelFrequencyChange}
                    onConfirm={confirmFrequencyChange}
                />
            </div>
            <div>
                <ModifyOfferedPriceModal show={showOfferedPriceModal} onClose={() => setShowOfferedPriceModal(!showOfferedPriceModal)} projectId={currentProjectIdGblCtx} />
            </div>
            <div className="row">
                <div className="col-xl-1 col-md-1 mb-1">
                    <p style={{ fontSize: '12pt', fontWeight: 'bold' }}>Select Frequency: </p>
                </div>
                <div className="col-xl-1 col-md-1 mb-1">
                    <Form.Control as="select" name="Role"
                        isInvalid={false}
                        disabled={readonlyUIDisabled}
                        value={selectedFrequency?.FrequencyId ?? -1}
                        title={`${invoiceScheduleData?.Frequency}`}
                        onChange={handleFrequencyDrpInputChange}>
                        <option value="-1" disabled>Select frequency..</option>
                        {
                            allMasterFrequencies?.map((frequency, index) => (
                                <option key={index} value={frequency.FrequencyId ?? -1} title={frequency.Frequency ?? ''}>
                                    {frequency.Frequency}
                                </option>
                            ))
                        }
                    </Form.Control>
                </div>
                <div className="col-xl-2 col-md-2 mb-1" onDoubleClick={handleDoubleClick}>
                    <div className="card border-left-primary shadow h-40 py-1" style={{ height: '65px' }}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase">
                                        Offered Price</div>
                                    <div className="h6 mb-0 font-weight-bold text-gray-800">{formatToUSCurrency(currentProjectInfoGblCtx?.OfferedPrice ?? 0)}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-2 col-md-2 mb-1">
                    <div className="card border-left-primary shadow h-40 py-1" style={{ height: '65px' }}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Total Invoice Cost</div>
                                    <div className="h6 mb-0 font-weight-bold text-gray-800">{formatToUSCurrency(totalInvoiceCost)}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-md-2 mb-1">
                    <div title={formatToUSCurrency(currentProjectInfoGblCtx?.EstimatedServicesFeesAltRate ?? 0)} className="card border-left-primary shadow h-40 py-1" style={{ height: '65px' }}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Total Project(ALT) Cost</div>
                                    <div className="h6 mb-0 font-weight-bold text-gray-800">{formatToUSCurrency(totalALTCost)}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-md-2 mb-1">
                    <div className="card border-left-primary shadow h-40 py-1" style={{ height: '65px' }}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Remaining Cost
                                    </div>
                                    <div className="h6 mb-0 font-weight-bold text-gray-800">{formatToUSCurrency(totalALTCost - totalInvoiceCost)}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-md-2 mb-1">
                    <div className="card border-left-primary shadow h-40 py-1" style={{ height: '65px' }}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Maximum WIP Accumulation
                                    </div>
                                    <div className="h6 mb-0 font-weight-bold text-gray-800">{formatToUSCurrency(maximumWIPAccumulation)}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="card shadow mb-0">
                    <div className="card-header py-1 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">Invoice Schedule</h6>
                        {initialInvoiceScheduleData && initialInvoiceScheduleData.InvoiceScheduleId &&
                            initialInvoiceScheduleData.InvoiceScheduleId > 0 && initialInvoiceScheduleData.ScheduleFrequencies
                            && initialInvoiceScheduleData.ScheduleFrequencies?.length > 0 &&
                            <div className="dropdown no-arrow">
                                <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                    aria-labelledby="dropdownMenuLink">
                                    <div className="dropdown-header">Invoice Schedule</div>
                                    <a className="dropdown-item" onClick={handleDownloadInvoiceSchedule}>Download Invoice</a>
                                </div>
                            </div>
                        }
                    </div>

                    <div className='card-body show' style={{ height: 'auto', overflowX: 'scroll' }}>
                        {loadingIconEffortGrid ? (<LoadingIcon />) : ''}
                        <table style={{ width: 'auto' }}>
                            <thead>
                                <tr>
                                    <td style={{ width: '90px', height: '25px', fontSize: '11pt', fontWeight: 'bold' }}></td>
                                    {invoiceScheduleData && invoiceScheduleData?.ScheduleFrequencies?.map((frequency) => {
                                        return (<th key={frequency.FrequencyColumnNumber} className="text-center" style={{ width: '100px', backgroundColor: '#0078D4', color: 'white' }}>
                                            <div title={`${formatDateAsString(frequency.FromDate)}-${formatDateAsString(frequency.ToDate)}`} style={{ alignItems: 'center' }}>
                                                <p style={{ width: '120px', fontSize: '14px', marginTop: '0', marginBottom: '0.1rem' }}>{`${frequency.LabelText == 'null' ? 'N/A' : frequency.LabelText}`}</p>

                                                <p style={{ fontSize: '12px', marginTop: '0', marginBottom: '0.1rem' }}>{parseDateAsStringFormat(frequency.FromDate)}</p>
                                            </div>
                                        </th>);
                                    })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ height: '25px', textAlign: 'left', backgroundColor: 'lightyellow', color: 'blue' }}>
                                    <td style={{ textAlign: 'left', height: '25px', fontSize: '11pt', fontWeight: 'bold' }}>WIP</td>
                                    {invoiceScheduleData && invoiceScheduleData?.ScheduleFrequencies?.map((frequency) => {
                                        return (
                                            <td style={{}} key={frequency.FrequencyColumnNumber} align="center">
                                                {formatToUSCurrency(frequency.ProjectedWIPEffortCost ?? 0)}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr style={{ height: '25px', textAlign: 'left', fontSize: '10pt', fontWeight: 'bold' }}>
                                    <td style={{ textAlign: 'left' }}>Total WIP</td>
                                    {invoiceScheduleData && invoiceScheduleData?.ScheduleFrequencies?.map((frequency) => {
                                        return (
                                            <td key={frequency.FrequencyColumnNumber} style={{ backgroundColor: '#918991', color: 'white' }} align="center">
                                                {formatToUSCurrency(frequency.TotalProjectedWIPCost ?? 0)}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <td style={{ height: '25px', textAlign: 'left', fontSize: '10pt', fontWeight: 'bold' }}>Invoice</td>
                                    {invoiceScheduleData && invoiceScheduleData?.ScheduleFrequencies?.map((frequency) => {
                                        let controlName = `cost${frequency.FrequencyColumnNumber}`;
                                        return (
                                            <td style={{}} key={frequency.FrequencyColumnNumber} align="center">
                                                <Form.Control
                                                    type="text"
                                                    disabled={readonlyUIDisabled}
                                                    isInvalid={!!validationErrors[controlName]}
                                                    name={controlName}
                                                    placeholder={``}
                                                    value={frequency.InvoiceAmount ?? ''}
                                                    style={{ height: '35px', width: '100px', fontSize: '9pt', marginLeft: '0px' }}
                                                    onChange={(e) => handleInputChangeInvoiceCost(e, frequency)}

                                                />
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr style={{ height: '25px', textAlign: 'left', fontSize: '10pt', fontWeight: 'bold' }}>
                                    <td style={{}}>Total Invoice</td>
                                    {invoiceScheduleData && invoiceScheduleData?.ScheduleFrequencies?.map((frequency) => {
                                        return (
                                            <td key={frequency.FrequencyColumnNumber} style={{ backgroundColor: 'blue', color: 'white' }} align="center">
                                                {formatToUSCurrency(frequency.TotalInvoiceScheduleCost ?? 0)}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr style={{ height: '25px', fontSize: '11pt', fontWeight: 'bold' }}>
                                    <td style={{}}>Diff(+/-)</td>
                                    {invoiceScheduleData && invoiceScheduleData?.ScheduleFrequencies?.map((frequency) => {

                                        let fontColor = frequency.ProjectedVsInvoiceDiff && frequency.ProjectedVsInvoiceDiff > 0 ? 'red' : 'green';

                                        return (
                                            <td key={frequency.FrequencyColumnNumber} style={{ backgroundColor: fontColor, color: 'white' }} align="center">
                                                {formatToUSCurrency(frequency.ProjectedVsInvoiceDiff ?? 0)}
                                            </td>
                                        )
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {!readonlyUIDisabled && <div className="row">
                <div style={{ width: '250px', marginBottom: '10px', marginLeft: '40%' }} className="d-flex justify-content-between">
                    <Button disabled={saveButtonDisabled} style={{ marginLeft: '10px', marginTop: '15px' }} variant="secondary" onClick={resetInvoiceSchedule}>
                        <i className="fas fa-undo"></i> Reset
                    </Button>
                    <Button style={{ marginLeft: '1px', marginRight: '1px' }}
                        disabled={saveButtonDisabled} variant="primary"
                        onClick={(e) => handleInvoiceScheduleSave()}>
                        <i className="fas fa-save"></i> Save Data
                    </Button>
                </div>
            </div>
            }
            <div className="row">
                <div className="card shadow mb-0">
                    <div className="card-header py-1 d-flex justify-content-between flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary"> Invoice Schedule Bar Chart</h6>
                    </div>
                    <div className='card-body show' style={{ width: '1200px', height: '700px' }}>
                        <IInvoiceVerticleChart invoiceSchedule={invoiceScheduleData ?? null} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvoiceScheduleManage;