import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import ProjectTitleManage from './project-title-manage';
import { patchProjectData } from '../_services/dashboard-svc';
import { useProjectDataContext } from '../../data-context/project-data-context';
import { IAPIRequestFilter, IProjectHeaderDetails } from '../../data-model';
import { toast } from 'react-toastify';
import { getRandomNumber } from '../../utilities/helper-function';

const ProjectTitle: React.FC<{ projectId?: number }> = ({ projectId }) => {
  const [projectTitle, setProjectTitle] = useState<string>('');

  const { currentProjectIdGblCtx, currentProjectInfoGblCtx, setCurrentProjectInfoGblCtx,
    setRefreshProjectHeaderGblCtx } = useProjectDataContext();

  useEffect(() => {
    setProjectTitle(currentProjectInfoGblCtx?.Title ?? '');
  }, [projectId, currentProjectInfoGblCtx?.Title]);

  const fetchProjectHeader = async () => {
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
    if (projId != undefined && projId > 0) {

    } else {
      setProjectTitle('');
    }
  };

  const handleTitleChange = async (newTitle: string) => {
    setProjectTitle(newTitle);
    let patchData: IAPIRequestFilter[] = [{
      Field: "Title",
      Operator: '',
      Value: newTitle,
      Text: ''
    }];
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
    let [isSuccess, message] = await patchProjectData(projId ?? 0, patchData);
    if (isSuccess) {
      if (currentProjectInfoGblCtx) {
        currentProjectInfoGblCtx.Title = newTitle;
        setCurrentProjectInfoGblCtx(currentProjectInfoGblCtx)
        setRefreshProjectHeaderGblCtx(getRandomNumber());
      }
      toast.success("successfully saved", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
    }
    else {
      toast.error("Error occurred:" + message, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
    }
  };

  return (
    <Container>
      <ProjectTitleManage projectId={projectId} title={projectTitle} onTitleChange={handleTitleChange} />
    </Container>
  );
}

export default ProjectTitle;
