import { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useBlocker, useActionData, BlockerFunction } from "react-router-dom";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import ConfirmationModal from "../../common/confirmation-dialog";


const UseUnsavedChanges: React.FC<{ isFormDirty: boolean }> = ({ isFormDirty }) => {
    const [unSavedChanges, setUnSavedChanges] = useState(isFormDirty);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    let actionData = useActionData() as { ok: boolean } | undefined;

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [unSavedChanges]);

    const handleBeforeUnload = (e: any) => {
        if (unSavedChanges) {
            e.preventDefault();
            e.returnValue = ""; // required for chrome
            return true; // Return a truthy value
        }
        return null; // Allow navigation if no conditions met
    };

    let shouldBlock = useCallback<BlockerFunction>(
        ({ currentLocation, nextLocation }) =>
            unSavedChanges && currentLocation.pathname !== nextLocation.pathname,
        [unSavedChanges]
    );
    let blocker = useBlocker(shouldBlock);

    useEffect(() => {
        if (actionData?.ok) {
            setUnSavedChanges(false)
        }
    }, [actionData]);

    useEffect(() => {
        setUnSavedChanges(isFormDirty)
        if (blocker.state === "blocked" && !isFormDirty) {
            blocker.reset();
        }
        setShowConfirmationDialog(blocker.state === "blocked" && isFormDirty);
    }, [blocker, isFormDirty]);

    return (
        <>
            <div>
                <ConfirmationModal
                    show={showConfirmationDialog}
                    onClose={() => setShowConfirmationDialog(false)}
                    title="Unsaved Data"
                    message={`You have unsaved changes. Are you sure you want to leave?`}
                    onCancel={() => blocker.reset?.()}
                    onConfirm={() => blocker.proceed?.()}
                    confirmText={`Proceed without Save`}
                />
            </div>
        </>
    )
}

export default UseUnsavedChanges;