
const HelpDocument: React.FC<{}> = ({ }) => {

    return (
        <>
            <h3>Keyboard Shortcut Guide</h3>
            <p>Users may now utilize keyboard shortcuts to navigate the Pricing Tool. A list of shortcut commands is below.</p>
            <ul>
                <li>Select multiple cells: Hold <strong>Shift</strong> + move arrow keys in any direction</li>
                <li>Drag the same value across rows or columns: Select a cell, hold <strong>Shift</strong> + move arrow keys in any direction. Press <strong>Enter</strong> (while still holding shift) to populate the same value across all selected cells.</li>
                <li>Create a new column (week): <strong>Ctrl</strong> + <strong>I</strong></li>
                <li>Create a new row: <strong>Ctrl</strong> + <strong>Enter</strong></li>
                <li>Clear all values from selected cells: Select multiple cells, then press <strong>Ctrl</strong> + <strong>Del</strong></li>
                <li>Copy: <strong>Ctrl</strong> + <strong>C</strong></li>
                <li>Paste: <strong>Ctrl</strong> + <strong>V</strong></li>
                <li>Cut: <strong>Ctrl</strong> + <strong>X</strong></li>
                <li>Undo: <strong>Ctrl</strong> + <strong>Z</strong></li>
                <li>Save: <strong>Ctrl</strong> + <strong>S</strong></li>
                <li>Undo: <strong>Ctrl</strong> + <strong>Z</strong>: Only the last change will be undone. This is an incomplete feature, i.e., a work in progress(WIP).</li>
            </ul>
            <h3>Mouse Dragging Feature</h3>
            <ul>
                <li> <strong>Drag the same value across multiple cells: </strong> Press and hold left mouse button (your cursor should change to a "drag" cursor), pull cursor to desired cell, then release mouse button.</li>
                <li> <strong>Select multiple cells: </strong> Click any cell, press and hold down the left mouse button, pull cursor to desired cell, then release mouse button.</li>
                <li> <strong>De-Select cell: </strong> Double-left click any new cell to reset.</li>
            </ul>
        </>
    );
};

export default HelpDocument;
