
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface ConfirmWithCommentModalProps {
    show: boolean;
    title: string;
    message: string;
    onCancel: () => void;
    onConfirm: (message?: string) => void;
    onClose?: () => void;
    showCloseButton?: boolean | false;
    cancelText?: string | null;
    confirmText?: string | null;
}

const ConfirmWithComment: React.FC<ConfirmWithCommentModalProps> = ({
    show,
    title,
    message,
    onCancel,
    onConfirm,
    onClose,
    showCloseButton,
    cancelText,
    confirmText
}) => {
    const [validComment, setValidComment] = useState<boolean>(false);
    const [comment, setComment] = useState<string>();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setComment(value);
        setValidComment(value?.trim()?.length > 0);
    }

    return (
        <Modal show={show} onHide={() => onClose && onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>{message}</div>
                <div className="row">
                    <div className="col-md-11">
                        <Form.Group controlId="fromUserComment">
                            <Form.Label>Comment:</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="userComment"
                                value={comment}
                                onChange={handleInputChange}
                                rows={3}
                                cols={55}
                                placeholder="Enter your comment here..."
                            />
                        </Form.Group>
                    </div>
                    {
                        !validComment && <p style={{ color: 'red' }} className="error-message">Provide comment*</p>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                {showCloseButton && <Button variant="secondary" onClick={() => onClose && onClose()}>
                    <i className="fas fa-times"></i> Close
                </Button>}
                <Button variant="secondary" onClick={onCancel}>
                    <i className="fas fa-times"></i> {cancelText ?? 'Cancel'}
                </Button>
                <Button variant="danger" onClick={() => validComment && onConfirm(comment)}>
                    <i className="fas fa-check"></i> {confirmText ?? 'Confirm'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmWithComment;
