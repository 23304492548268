import React from 'react';
import './construction-banner.scss';
import { Link } from 'react-router-dom';
import { useProjectDataContext } from '../../data-context/project-data-context';

const ConstructionBanner: React.FC = () => {

  const { currentProjectIdGblCtx} = useProjectDataContext();

  return (<>
    <div className="construction-banner">
      <span>Feature is unavailable</span>
    </div>
    <Link to={`/project-dashboard/${currentProjectIdGblCtx}`}>
      <span>Go Back Project Home</span>
    </Link>
  </>

  );
};

export default ConstructionBanner;
