import React, { useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import ProjectRoleUtilizationReport from '../report/role-utilization';

const ProjectReport: React.FC<{}> = ({ }) => {

    return (
        <div>
            <div className="row" style={{ paddingBottom: '1px' }}>
                {/* <div className="col-xl-6 col-lg-6 col-md-6">
                    <ProjectRoleUtilizationReport displayProperty={(item) => item.CostUtilize} title='Role Utilization' />
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6">
                    <ProjectRoleUtilizationReport displayProperty={(item) => item.TimeUtilize} title='Role Utilization'/>
                </div> */}
            </div>
        </div>
    )
}

export default ProjectReport;