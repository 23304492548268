import { useEffect, useState } from "react";
import { IAuditTransaction } from "../../data-model";
import { getAllProjectAuditTransaction } from "../_services/audit-transaction-svc";
import { toast } from "react-toastify";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { useDelayDebounce } from "../../common/hooks/debounce";
import { Form, Tabs, Tab } from "react-bootstrap";
import AuditInfo from "./audit-info";

const AuditDetails: React.FC<{ projectId: number }> = ({ projectId }) => {
    const [auditTransactionInitialData, setAuditTransactionInitialData] = useState<IAuditTransaction[]>();
    const [selectedAuditEntity, setSelectedAuditEntity] = useState<string>('1');

    const [loading, setLoading] = useState(true);
    const delayDebouncedValue = useDelayDebounce(100)

    useEffect(() => {
        if (delayDebouncedValue > 0) {
            fetchAllProjects();
        }
    }, [delayDebouncedValue, selectedAuditEntity]);

    const fetchAllProjects = async () => {
        try {
            setLoading(true);
            let [totalRecords, data] = await getAllProjectAuditTransaction(projectId, selectedAuditEntity);
            if (data && totalRecords > 0) {
                setAuditTransactionInitialData(data);
            } else {
                setAuditTransactionInitialData([]);
            }
        } catch (e) {
            toast.error("Error occurred. Unable to connect API server", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setLoading(false);
    };

    const handleSelect = (selectedEntity: string | null) => {
        if (selectedEntity) {
            setSelectedAuditEntity(selectedEntity);
        }
    };

    return (<>
        {loading ? (<LoadingIcon />) : ''}
        <div className="container mt-0">
            <Tabs activeKey={selectedAuditEntity} onSelect={handleSelect} defaultActiveKey="1" id="plan-audit" className="mb-1">
                <Tab eventKey="1" title="Header Audit">
                </Tab>
                <Tab eventKey="2" title="Role Rate Audit">
                </Tab>
                {/* <Tab eventKey="3" title="Resource Audit">
                </Tab>
                <Tab eventKey="4" title="Effort Audit">
                </Tab> */}
            </Tabs>
            <AuditInfo auditData={auditTransactionInitialData ?? []} />
        </div>
    </>
    );
};

export default AuditDetails;