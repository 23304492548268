import { useEffect, useState } from "react";
import { useProjectDataContext } from "../../data-context/project-data-context";
import { useDelayDebounce } from "../../common/hooks/debounce";
import { IAPIRequestFilter, IProjectStatus } from "../../data-model";
import { getAllMasterProjectStatus } from "../_services/master-data-svc";
import { toast } from "react-toastify";
import { getAllProjectStatusByProjectId, patchProjectData } from "../_services/dashboard-svc";
import { getRandomNumber, isProjectReadOnlyStatus, isProjectStatusChangeReadOnly, isUserRoleReadOnly } from "../../utilities/helper-function";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import ConfirmationModal from "../../common/confirmation-dialog";
import { getAllApprover } from "../_services/approver-svc";
import ConfirmWithComment from "../../common/confirmation-dialog/confirm-with-comment";

const ManageProjectStatus: React.FC<{ projectId?: number }> = ({ projectId }) => {
    const { currentProjectIdGblCtx, currentProjectInfoGblCtx,
        currentProjectParentIdGblCtx, setCurrentProjectInfoGblCtx, setRefreshProjectHeaderGblCtx,
        setAllMasterStatusGblCtx, refreshProjectStatusGblCtx, userAccessInfoGblCtx,
        setRefreshProjectStatusGblCtx, setRefreshProjectVersionGblCtx } = useProjectDataContext();

    const [allStatus, setAllStatus] = useState<IProjectStatus[]>([]);
    const [selectedProjectStatus, setSelectedProjectStatus] = useState<IProjectStatus | null>();
    const [displayParentLink, setDisplayParentLink] = useState<boolean>();
    const delayDebouncedValue = useDelayDebounce(10);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [disabledStatusChange, setDisabledStatusChange] = useState<boolean>(false);
    const [statusChangeMessage, setStatusChangeMessage] = useState<string>('');
    const [showStatusChangeConfirmationModal, setShowStatusChangeConfirmationModal] = useState(false);
    const [showRejectStatusChangeConfirmationModal, setShowRejectStatusChangeConfirmationModal] = useState(false);

    useEffect(() => {
        if (allStatus && allStatus.length == 0 && delayDebouncedValue && delayDebouncedValue > 0) {
            getAllProjectStatus();
        }
    }, [delayDebouncedValue]);

    useEffect(() => {
        if (currentProjectParentIdGblCtx && currentProjectParentIdGblCtx > 0) {
            setDisplayParentLink(true);
        } else {
            setDisplayParentLink(false);
        }
    }, [currentProjectParentIdGblCtx]);

    useEffect(() => {
        //if (allStatus && allStatus.length == 0) {
        getAllProjectStatus().then(resStatusList => {
            if (resStatusList.length > 0) {
                let status = resStatusList.filter(f => f.StatusId == currentProjectInfoGblCtx?.StatusId)[0];
                setSelectedProjectStatus(status);
            }
            if (currentProjectInfoGblCtx && currentProjectInfoGblCtx.Status) {
                setDisabledStatusChange(isProjectStatusChangeReadOnly(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) || isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles));
            }
        });
        //}
        // if (allStatus && allStatus.length > 0 && currentProjectInfoGblCtx?.Status) {
        //     setSelectedProjectStatus(null);
        //     let status = allStatus.filter(f => f.StatusId == currentProjectInfoGblCtx?.StatusId)[0];
        //     setSelectedProjectStatus(status);
        // }
        // if (currentProjectInfoGblCtx && currentProjectInfoGblCtx.Status) {
        //     setDisabledStatusChange(isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status));
        // }
    }, [refreshProjectStatusGblCtx]);

    const fetchAllMasterStatus = async () => {
        try {
            let data = await getAllMasterProjectStatus();
            if (data) {
                setAllMasterStatusGblCtx(data);
            }
        } catch (error) {
            console.error('Error fetching status:', error);
        }
    };

    const getAllProjectStatus = async (): Promise<IProjectStatus[]> => {
        try {
            let data = await getAllProjectStatusByProjectId(currentProjectIdGblCtx ?? 0);
            if (data) {
                setAllStatus(data);
                return data;
            }
        } catch (error) {
            console.error('Error fetching status:', error);
        }
        return [];
    };

    const getAllApproverName = async (): Promise<[boolean, string]> => {
        let approver = '';
        let [status, approvers, error] = await getAllApprover(projectId);
        if (status == true && approvers?.length > 0) {
            approver = approvers.map(approver => `'${approver.FullName}'`).join(', ');
        } else {
            toast.error(`Error occurred: ${error}`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        return [status, status ? approver : error];
    }

    const handleProjectStatusChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        let status = allStatus.filter(f => f.StatusId == +value)[0];
        let displayLatestStatus = true;

        if ((value == "200" || value == "300" || value == "400" || value == "500" || value == "600") && !(currentProjectInfoGblCtx && currentProjectInfoGblCtx.HubspotClientId && currentProjectInfoGblCtx.HubspotClientId > 0 && currentProjectInfoGblCtx.HubspotDealId && currentProjectInfoGblCtx.HubspotDealId > 0)) {
            displayLatestStatus = false;
            toast.error(`Unable to update status. Please connect the plan to a valid (and open) HubSpot client and deal by opening the project header and inputting “Client Name” and “Select HubSpot Deal”`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        } else if ((value == "200" || value == "300" || value == "400" || value == "500" || value == "600") && (currentProjectInfoGblCtx && currentProjectInfoGblCtx.RiskMultiple == undefined)) {
            displayLatestStatus = false;
            toast.error(`Risk questionnaire must be filled out before submitting for approval.`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        else if ((value == "200" || value == "300" || value == "400" || value == "500" || value == "600") && !(currentProjectInfoGblCtx && currentProjectInfoGblCtx.EstimatedServicesFeesAltRate && currentProjectInfoGblCtx.EstimatedServicesFeesAltRate > 0)) {
            displayLatestStatus = false;
            toast.error(`Unable to change status. Please add roles and effort to the plan.`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        else {
            if (value == "100") { //Draft
                setShowStatusChangeConfirmationModal(true);
                setStatusChangeMessage(`Are you sure to change the project plan status to '${status.Description}'?`);
            } else if (value == "200") { //SubmittedForApproval
                let [resStatus, approvers] = await getAllApproverName();

                if (resStatus == true && approvers?.length > 0) {
                    setShowStatusChangeConfirmationModal(true);
                    setStatusChangeMessage(`Are you sure you want to change the plan to '${status.Description}'? A workflow will be triggered, notifying plan following approvers ${approvers}.`);
                } else {
                    displayLatestStatus = false;
                    setShowStatusChangeConfirmationModal(false);
                    toast.error(`Error occurred: ${approvers}`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
                }
            } else if (value == "299" || value == "300") { //Approved               
                setShowStatusChangeConfirmationModal(true);
                setStatusChangeMessage(`Are you sure to change the project plan status to '${status.Description}'?`);
            } else if (value == "50") {//Rejected
                setShowRejectStatusChangeConfirmationModal(true);
                setStatusChangeMessage(`Are you sure to change the project plan status to '${status.Description}'?`);
            } else if (value == "400") { //ProposalDelivered 
                displayLatestStatus = false;
                toast.error(`Unable to update status. Missing Hubspot client and deal name. Please update.`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            } else if (value == "500" || value == "600" || value == "700") { //"Deal Won, Deal Lost, Archive"
                setShowStatusChangeConfirmationModal(true);
                setStatusChangeMessage(`Are you sure you want to change the plan status to '${status.Description}'? Once a plan has been archived, it may not be edited.`);
            }
        }

        if (displayLatestStatus) {
            setSelectedProjectStatus(status);
        }
    };

    const changeProjectStatus = async (rejectMessage?: string) => {
        toast.info('Saving status...', { toastId: 'info1', autoClose: 500, position: toast.POSITION.TOP_CENTER });
        setLoadingStatus(true);
        let patchData: IAPIRequestFilter[] = [{
            Field: "Status",
            Operator: '',
            Value: '' + selectedProjectStatus?.StatusId,
            Text: ''
        }
        ];

        if (selectedProjectStatus?.StatusId == 50) { //Add for reject status
            patchData.push({
                Field: "RejectComment",
                Operator: '',
                Value: rejectMessage,
                Text: ''
            });
        }

        let [isSuccess, message] = await patchProjectData(currentProjectIdGblCtx ?? 0, patchData);
        if (isSuccess) {
            if (currentProjectInfoGblCtx) {
                await getAllProjectStatus();
                currentProjectInfoGblCtx.Status = selectedProjectStatus?.Description;
                currentProjectInfoGblCtx.StatusId = selectedProjectStatus?.StatusId;
                setCurrentProjectInfoGblCtx(currentProjectInfoGblCtx);
                setRefreshProjectHeaderGblCtx(getRandomNumber());
                setRefreshProjectStatusGblCtx(getRandomNumber());
                setRefreshProjectVersionGblCtx(getRandomNumber());
                toast.success("successfully saved", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
            }
        }
        else {
            setSelectedProjectStatus(allStatus.filter(f => f.StatusId == currentProjectInfoGblCtx?.StatusId)[0]);
            toast.error("Error:" + message, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setLoadingStatus(false);
    }

    const confirmStatusChange = async () => {
        if (selectedProjectStatus) {
            await changeProjectStatus();
            setShowStatusChangeConfirmationModal(false);
        }
    };

    const confirmRejectStatusChange = async (message?: string) => {
        if (selectedProjectStatus) {
            await changeProjectStatus(message);
            setShowRejectStatusChangeConfirmationModal(false);
        }
    };

    const cancelStatusChangeDialog = () => {
        setShowStatusChangeConfirmationModal(false);
        setSelectedProjectStatus(allStatus.filter(f => f.StatusId == currentProjectInfoGblCtx?.StatusId)[0]);
    };

    const cancelRejectStatusChangeDialog = () => {
        setShowRejectStatusChangeConfirmationModal(false);
        setSelectedProjectStatus(allStatus.filter(f => f.StatusId == currentProjectInfoGblCtx?.StatusId)[0]);
    };

    return (
        <>
            <div>
                <ConfirmationModal
                    show={showStatusChangeConfirmationModal}
                    title="Confirm Status Change"
                    message={statusChangeMessage}
                    onClose={() => cancelStatusChangeDialog()}
                    onCancel={cancelStatusChangeDialog}
                    onConfirm={confirmStatusChange}
                />
                <ConfirmWithComment
                    show={showRejectStatusChangeConfirmationModal}
                    title="Confirm Reject Status Change"
                    message={statusChangeMessage}
                    onClose={() => cancelRejectStatusChangeDialog()}
                    onCancel={cancelRejectStatusChangeDialog}
                    onConfirm={(message) => confirmRejectStatusChange(message)}
                />

            </div>
            {currentProjectIdGblCtx && currentProjectIdGblCtx > 0 && (
                <div className='d-flex justify-content-center'>
                    <label style={{ fontWeight: 'bold', fontSize: '12pt' }} htmlFor="status">Status:</label>
                    {loadingStatus ? <LoadingIcon /> :
                        <select style={{ marginLeft: '10px' }} id="status" name="status"
                            disabled={disabledStatusChange}
                            value={selectedProjectStatus?.StatusId}
                            onChange={handleProjectStatusChange}>
                            <option disabled value="">-Select-</option>
                            {allStatus.map((status) => (
                                <option style={{ fontWeight: 'bold', color: status.StatusId == 50 ? 'red' : (status.StatusId == 299 || status.StatusId == 300) ? 'green' : '' }}
                                    key={status.StatusId} value={status.StatusId}>
                                    {status.Description}
                                </option>
                            ))}
                        </select>
                    }
                </div>
            )}
        </>
    );
}

export default ManageProjectStatus;