import { deleteApiData, getApiData, postApiData, putApiData } from "../../app-services";
import { IProjectEffortHours } from "../../data-model";
import { parseAndCastDate } from "../../utilities/helper-function";
import { getBaseFTTAPIURLS } from "./common-svc";

export const getAllWeeklyEffort = async (projectId?: number): Promise<IProjectEffortHours[] | null> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let effortHours: IProjectEffortHours[] = [];
  let url = `${baseAPIUrls}/weeklyeffort/get/all/${projectId}`;
  var apiResponse = await getApiData(url);
  if (apiResponse?.Success && apiResponse.Result?.length > 0) {
    effortHours = mapAllWeeklyEffortAPIResponse(apiResponse.Result);
  }
  return effortHours;
}

function mapAllWeeklyEffortAPIResponse(apiResponse: any): IProjectEffortHours[] {
  return apiResponse.map((result: any) => ({
    ...result,
    DateFrom: parseAndCastDate(result.DateFrom),
    DateTo: parseAndCastDate(result.DateTo)
  }));
}

export const saveWeeklyEfforts = async (projectId: number, updatedWeeklyEfforts: IProjectEffortHours[]): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/weeklyeffort/update/all/${projectId}`;
  var apiResponse = await postApiData(url, updatedWeeklyEfforts);
  return [apiResponse?.Success, apiResponse?.Result];
}

export const deleteWeeklyEfforts = async (projectId: number, weekNumber: number): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/weeklyeffort/delete/byweeknumber/${projectId}/${weekNumber}`;
  var apiResponse = await deleteApiData(url);
  return [apiResponse?.Success, apiResponse?.Result];
}

export const AddWeekInsideEfforts = async (projectId: number, weekNumber: number): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/weeklyeffort/add/week/${projectId}/${weekNumber}`;
  var apiResponse = await putApiData(url);
  return [apiResponse?.Success, apiResponse?.Result];
}
