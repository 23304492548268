import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { downloadCommentAttachment, saveUserCommentAndAttachment, updateUserCommentAndAttachment } from "../_services/user-comment-svc";
import { toast } from "react-toastify";
import IUserComment from "../../data-model/user-comment";

const CreateUserComments: React.FC<{
  projectId: number;
  onClose: (isSuccessfullyCreatedRec: boolean) => void;
  initialData?: IUserComment | null;
}> = ({ projectId, onClose, initialData }) => {
  const [comment, setComment] = useState<string>(initialData?.Comment ?? '');
  const [attachment, setAttachment] = useState<File | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

  const maxFileSize = 50 * 1024 * 1024; // 50MB
  const allowedFileTypes = [
    'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp',
    'application/pdf',
    'text/plain', 'application/json',
    'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ];

  const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const validateFormData = () => {
    const errors: { [key: string]: string } = {};
    setValidationErrors({});
    if (!comment) {
      errors.Comment = 'Comment is required';
    }

    setValidationErrors(errors);

    return errors;
  }

  const handleAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (!allowedFileTypes.includes(file.type)) {
        setAttachment(null);
        return toast.error(`File type is not allowed. Please upload an image, PDF, or Microsoft document.`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
      }

      if (file.size > maxFileSize) {
        setAttachment(null);
        return toast.error(`File size exceeds the 50MB limit`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
      }

      setError(null);
      setAttachment(file);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const errors = validateFormData();
    if (Object.keys(errors).length === 0) {
      const formData = new FormData();
      formData.append('Comment', comment);
      if (attachment && !initialData) {
        formData.append('attachment', attachment);
      }

      try {
        let [isSuccess, message] = [false, ''];
        if (initialData && initialData?.Id && initialData?.Id > 0) {
          formData.append('Comment', comment);
          formData.append('Id', initialData.Id.toString());
          [isSuccess, message] = await updateUserCommentAndAttachment(projectId, formData);
        } else {
          [isSuccess, message] = await saveUserCommentAndAttachment(projectId, formData);
        }
        if (isSuccess) {
          toast.success("Saved Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
          onClose(true)
        } else {
          return toast.error(`Error occurred:${message}`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
      } catch (error) {
        return toast.error(`Error occurred:${error}`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
      }
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-md-center">
          <Col md="6">
            <Form.Group controlId="formComment">
              <Form.Label>Comment<span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comment}
                onChange={handleCommentChange}
                placeholder="Enter your comment"
              />
              {
                validationErrors.Comment && <p style={{ color: 'red' }} className="error-message">{validationErrors.Comment}*</p>
              }
            </Form.Group>
          </Col>

          <Col md="6">
            {!initialData && <Form.Group controlId="formFile">
              <Form.Label>Attachment</Form.Label>
              <Form.Control
                type="file"
                onChange={handleAttachmentChange}
              />
            </Form.Group>}
          </Col>
        </Row>
        <Row className="d-flex justify-content-right">
          <Col >
            <Button style={{ marginTop: '15px', marginRight: '5px' }} variant="secondary" type="button" onClick={() => onClose(false)}>
              <i className="fas fa-times"></i> Close
            </Button>
            <Button variant="primary" type="submit">
              <i className="fas fa-save"></i> Submit
            </Button>
          </Col>
        </Row >
      </Form>
    </Container >
  );
};

export default CreateUserComments;