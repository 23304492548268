import React from 'react';
import './loading-icon.scss';

const LoadingIcon: React.FC = () => {
  return (
    <div className="loading-icon">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingIcon;
