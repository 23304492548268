import React, { useEffect, useState } from "react";
import { IProjectRolesResource } from '../../data-model'
import RoleResourceCreate from './role-resource-create';
import ConfirmationModal from "../../common/confirmation-dialog";
import './role-resource-create.scss'
import { useProjectDataContext } from "../../data-context/project-data-context";
import { mapToProjectEffortList } from "../../data-model/data-mapping";
import { calculateDiscount, formatToUSCurrency, isProjectReadOnlyStatus, isUserRoleReadOnly } from "../../utilities/helper-function";

const RolesResourceDetails: React.FC<{
  projectId?: number;
  allRoleResources: IProjectRolesResource[];
  onDeleteSave: (deleteRecord: IProjectRolesResource) => boolean;
  onEditSave: (editedRecord: IProjectRolesResource, copyResourceAtEffort: boolean) => boolean;
}> = ({ projectId, onDeleteSave, onEditSave, allRoleResources }) => {

  const { allProjectRoleUtilizationGblCtx, setRefreshLevelOfEstimationGblCtx, currentProjectInfoGblCtx,
    refreshProjectStatusGblCtx, userAccessInfoGblCtx } = useProjectDataContext();

  const [showModal, setShowModal] = useState(false);
  const [currentRoleResource, setCurrentRoleResource] = useState<IProjectRolesResource | null>();
  const [recordToDeleteMessage, setRecordToDeleteMessage] = useState<string>('');
  const [showRoleDeleteModal, setShowRoleDeleteModal] = useState<boolean>(false);
  const [showEditLink, setShowEditLink] = useState<boolean>(true);
  const [hideDeleteLink, setHideDeleteLink] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<'role' | ''>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  let isSubContractorRoleExist = allRoleResources.some(f => f.IsSubContractor && f.SubContractorFees && f.SubContractorFees > 0);
  let isNoteExist = allRoleResources.some(f => f.Notes?.trim()?.length && f.Notes?.trim()?.length > 0);

  const sortedRoleResources = [...allRoleResources].sort((a, b) => {
    if (sortBy === 'role') {
      const nameA = a.PCRRoleRate?.Role.Name.toLowerCase();
      const nameB = b.PCRRoleRate?.Role.Name.toLowerCase();
      if (nameA && nameB && nameA < nameB) return sortOrder === 'asc' ? -1 : 1;
      if (nameA && nameB && nameA > nameB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  useEffect(() => {
    if (refreshProjectStatusGblCtx && refreshProjectStatusGblCtx > 0) {
      setHideDeleteLink(!isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) && !isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles));
    }
  }, [refreshProjectStatusGblCtx]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const editResource = (resource: IProjectRolesResource) => {
    setShowModal(!showModal);
    if (projectId) {
      resource.ProjectId = projectId;
    }
    setCurrentRoleResource({
      ...resource,
      Discount: calculateDiscount(resource.STDRate, resource.OfferedRate)
    });
  };

  const deleteResource = (resource: IProjectRolesResource) => {
    setShowRoleDeleteModal(true);
    setCurrentRoleResource(resource);
    setRecordToDeleteMessage(`Are you sure you want to delete role '${resource.PCRRoleRate?.Role.Name}'?`);
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSortByName = () => {
    setSortBy('role');
    toggleSortOrder();
  };

  const confirmDelete = () => {
    if (currentRoleResource != undefined) {
      onDeleteSave(currentRoleResource);
    }
    setCurrentRoleResource(null);
    setShowRoleDeleteModal(false);
  };

  const cancelDelete = () => {
    setCurrentRoleResource(null);
    setShowRoleDeleteModal(false);
  };

  return (<>
    <div>
      <ConfirmationModal
        show={showRoleDeleteModal}
        title="Confirm Deletion of Role"
        message={recordToDeleteMessage}
        onCancel={cancelDelete}
        onClose={() => setShowRoleDeleteModal(false)}
        onConfirm={confirmDelete}
        confirmText="Delete"
      />
    </div>
    <div>
      {showModal && currentRoleResource && (
        <RoleResourceCreate show={showModal} onClose={toggleModal} onSave={onEditSave} initialRoleResource={currentRoleResource} />
      )}
    </div>
    <div className="table-container" style={{ minHeight: '376px', maxHeight: '376px', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #ccc' }}>
      <table className="table" style={{ fontSize: '12px', margin: '0px' }}>
        <thead style={{ position: 'sticky', top: '0' }}>
          <tr>
            {/* <th>Role</th> */}
            <th onClick={handleSortByName} style={{ cursor: 'pointer' }}>
              Role {sortBy === 'role' && (sortOrder === 'asc' ? '▲' : '▼')}
            </th>
            <th>Code</th>
            <th style={{ minWidth: '120px' }}>Resource</th>
            {isNoteExist && <th>Notes</th>}
            <th>STD Rate</th>
            <th style={{ minWidth: '70px' }}>ALT Rate</th>
            <th style={{ minWidth: '90px' }}>Offered Rate</th>
            <th title="Discount (-) Markup(+)">Diff(- / +)</th>
            {isSubContractorRoleExist && <th>Contractor Rate</th>}
            <th>Time(%)</th>
            <th>Fees(%)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedRoleResources.map((resource, index) => {
            let timeUtilize = allProjectRoleUtilizationGblCtx?.filter((t) => t.ProjectRolesResourceId == resource.RoleResourceId)[0]?.TimeUtilize?.toFixed(2) ?? '0';
            let costUtilize = allProjectRoleUtilizationGblCtx?.filter((t) => t.ProjectRolesResourceId == resource.RoleResourceId)[0]?.CostUtilize?.toFixed(2) ?? '0';

            let appearanceOrder = (resource.Resource || resource.Resource == "") && resource.AppearanceOrder ? `(${resource.AppearanceOrder ?? ''})` : ''

            let altFontColor = ''
            if (resource.STDRate != resource.OfferedRate && resource.ALTRate && resource.ALTRate > 0) {
              altFontColor = resource.STDRate > resource.ALTRate ? 'red' : 'green';
            }
            return (
              <tr key={index}>
                <td title={"PCR Year: " + resource.PCRRoleRate?.Year ?? ''} style={{ border: '1px solid #ccc' }}>{resource.PCRRoleRate?.Role.Name}  {appearanceOrder}</td>
                <td title={resource.PCRRoleRate?.Role?.Description ?? ''} style={{ border: '1px solid #ccc' }}>{resource.PCRRoleRate?.Role.Code}</td>
                <td title={`Notes: ${resource.Notes ?? ''}`} style={{ width: '70px', border: '1px solid #ccc' }}>{resource.Resource}</td>
                {isNoteExist && <td title={`Notes: ${resource.Notes ?? ''}`} style={{ border: '1px solid #ccc' }}>{resource.Notes}</td>}
                <td style={{ width: '70px', border: '1px solid #ccc' }}>{formatToUSCurrency(resource.STDRate)}</td>
                <td style={{ width: '65px', border: '1px solid #ccc', color: altFontColor }}>{formatToUSCurrency(resource.ALTRate)}</td>
                <td style={{ width: '85px', border: '1px solid #ccc', color: altFontColor }}>{formatToUSCurrency(resource.OfferedRate)}</td>
                <td style={{ maxWidth: '100px', border: '1px solid #ccc', color: altFontColor }}>{calculateDiscount(resource.STDRate, resource.OfferedRate)}%</td>
                {isSubContractorRoleExist && <td style={{ maxWidth: '100px', color: 'brown', border: '1px solid #ccc' }}>{formatToUSCurrency(resource.SubContractorFees)}</td>}
                <td style={{ maxWidth: '100px', border: '1px solid #ccc' }}>{timeUtilize}%</td>
                <td style={{ maxWidth: '100px', border: '1px solid #ccc' }}>{costUtilize}%</td>
                <td style={{ width: '50px', border: '1px solid #ccc' }}>
                  {showEditLink && <a onClick={() => editResource(resource)} className="">
                    <i className="fas fa-edit"></i>
                  </a>
                  }
                  {hideDeleteLink && <a style={{ marginLeft: '5px', color: 'red' }} onClick={() => deleteResource(resource)} className="">
                    <i className="fas fa-trash"></i>
                  </a>
                  }
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {allRoleResources.length == 0 && (
        <div style={{ paddingTop: 'center', textAlign: 'center' }}>No record found. Please add new role.</div>
      )}
    </div>
  </>
  );
};

export default RolesResourceDetails;
