import { getApiData, postApiData, putApiData } from "../../app-services";
import { IInvoiceFrequencyData, IInvoiceSchedule } from "../../data-model";
import { parseAndCastDate } from "../../utilities/helper-function";
import { getBaseFTTAPIURLS } from "./common-svc";

export const getInvoiceSchedule = async (projectId: number): Promise<IInvoiceSchedule | null> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/invoiceschedule/get/${projectId}`;
    var apiResponse = await getApiData(url);
    if (apiResponse?.Success && apiResponse?.Result) {
        let invoiceSchedule = mapPCRRateApiResponse(apiResponse.Result);
        return invoiceSchedule;
    }
    return null;
}

function mapPCRRateApiResponse(apiResponse: any): IInvoiceSchedule {
    return {
        ...apiResponse,
        ScheduleFrequencies: (apiResponse.ScheduleFrequencies as any[])?.map((sch) => {
            return {
                ...sch,
                FromDate: parseAndCastDate(sch?.FromDate),
                ToDate: parseAndCastDate(sch?.ToDate)
            }
        })
    };
}

export const saveNewInvoiceSchedule = async (projectId: number, invoiceSchedule: IInvoiceSchedule): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/invoiceschedule/create/${projectId}`;
    var apiResponse = await postApiData(url, invoiceSchedule);
    return [apiResponse?.Success, apiResponse?.Result];
}

export const updateInvoiceSchedule = async (projectId: number, invoiceScheduleId: number, invoiceSchedule: IInvoiceSchedule): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/invoiceschedule/update/${projectId}/${invoiceScheduleId}`;
    var apiResponse = await putApiData(url, invoiceSchedule);
    return [apiResponse?.Success, apiResponse?.Result];
}