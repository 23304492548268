import { AlignmentType, Document, HeadingLevel, Packer, Paragraph, TabStopPosition, TabStopType, TextRun, Table, TableRow, TableCell, BorderStyle, VerticalAlign } from "docx";
import { IProjectEffortEstimation, IProjectEffortHours, IProjectEffortSummary, IProjectPhaseUtilization, IProjectRoleUtilization, IProjectRolesResource } from "../../data-model";
import { calculatePercent, formatToUSCurrency } from "../../utilities/helper-function";

export class PhaseEffort {
    public getProjectPhaseUtilization = (allRoleResourseEstimation: IProjectEffortEstimation[] | null | undefined, allInitialEffortHours: IProjectEffortHours[] | null | undefined): IProjectPhaseUtilization[] | null | undefined => {
        if (allRoleResourseEstimation) {
            let groupProjectPhases = this.groupRoleByPhase(allRoleResourseEstimation);

            let phaseUtilization: IProjectPhaseUtilization[] = [];
            Object.keys(groupProjectPhases).map((phase: string) => {
                let totalPhaseCost = 0;
                let totalPhaseHours = 0;
                groupProjectPhases[phase].map((resource) => {
                    totalPhaseCost = totalPhaseCost + resource.TotalFees;
                    totalPhaseHours = totalPhaseHours + resource.TotalHours;
                });
                const allRoleResourceIds = [...new Set(groupProjectPhases[phase].map(effortHour => effortHour.RoleResourceEstimateId))];
                phaseUtilization.push({
                    ProjectPhase: phase,
                    TotalCostUtilize: totalPhaseCost,
                    TotalTimeUtilize: totalPhaseHours,
                    PhaseStartDate: this.calculatePhaseStartDate(allRoleResourceIds, allInitialEffortHours),
                    PhaseEndDate: this.calculatePhaseEndDate(allRoleResourceIds, allInitialEffortHours),
                });

            });
            return phaseUtilization;
        }
    }

    public getAllPhase = (resourceEstimations: IProjectEffortEstimation[]): string[] => {
        if (!resourceEstimations) return [];

        const uniquePhaseNames = [...new Set(resourceEstimations.map(p => p.Phase))];
        return uniquePhaseNames;
    };

    public getRoleUtilizationByPhase = (projectEffortSummary: IProjectEffortSummary, allProjectRoleResourses: IProjectRolesResource[], allProjectEffortEstimation: IProjectEffortEstimation[], selectedPhases: string[] | null): IProjectRoleUtilization[] => {
        let roleUtilization: IProjectRoleUtilization[] = allProjectRoleResourses.map((eachRole) => {
            let [roleTotalTime, totalTime] = this.calculateTimeUtilization(projectEffortSummary, allProjectEffortEstimation, eachRole, selectedPhases ?? []);
            let [roleTotalCost, totalCost] = this.calculateCostUtilization(projectEffortSummary, allProjectEffortEstimation, eachRole, selectedPhases ?? []);

            return ({
                ProjectRolesResourceId: eachRole.RoleResourceId,
                ProjectRolesResource: eachRole,
                TimeUtilize: calculatePercent(totalTime, roleTotalTime),
                TotalTime: totalTime,
                CostUtilize: calculatePercent(totalCost, roleTotalCost),
                TotalCost: totalCost
            })
        })

        return roleUtilization;
    }

    public async createRoleUtilizationDocument(projectRoleUtilizations?: IProjectRoleUtilization[] | null): Promise<Document> {
        let utilizationData: TableRow[] = [];

        let tableHeader = new TableRow({
            children: [
                this.getTableHeaderCell("Role"),
                this.getTableHeaderCell("Total Time"),
                this.getTableHeaderCell("Time %"),
                this.getTableHeaderCell("Total Cost"),
                this.getTableHeaderCell("Cost %")
            ],
        })

        utilizationData.push(tableHeader);

        projectRoleUtilizations?.map((eachUtilization, index) => {
            if (eachUtilization.ProjectRolesResource?.PCRRoleRate?.Role?.Name) {
                let tableRow = new TableRow({
                    children: [
                        this.getTableDataCell(`${eachUtilization.ProjectRolesResource?.PCRRoleRate?.Role?.Name}`),
                        this.getTableDataCell(`${eachUtilization.TotalTime?.toFixed(2)}`),
                        this.getTableDataCell(`${eachUtilization.TimeUtilize?.toFixed(2)}%`),
                        this.getTableDataCell(`${formatToUSCurrency(eachUtilization.TotalCost ?? null)}`),
                        this.getTableDataCell(`${eachUtilization.CostUtilize?.toFixed(2)}%`),
                    ],
                });
                utilizationData.push(tableRow);
                return tableRow;
            }
        });

        const document = new Document({
            sections: [
                {
                    children: [
                        new Table({
                            rows: utilizationData,
                        }),
                    ],
                },
            ],
        });

        return document;
    }

    private calculateTimeUtilization = (projectEffortSummary: IProjectEffortSummary, allProjectEffortEstimation: IProjectEffortEstimation[], projectRole: IProjectRolesResource, selectedPhases: string[]): [number, number] => {
        let roleTotalTime: number = 0;
        let totalTime: number = 0;
        if (selectedPhases?.length > 0) {
            allProjectEffortEstimation.filter(item => item.ProjectRoleResourceId === projectRole.RoleResourceId && selectedPhases?.some(ph => ph?.trim()?.toLowerCase() == item.Phase?.trim()?.toLowerCase()))
                .map((res) => {
                    roleTotalTime = roleTotalTime + res.TotalHours;
                    return roleTotalTime;
                });
            allProjectEffortEstimation.filter(item => selectedPhases?.some(ph => ph?.trim()?.toLowerCase() == item.Phase?.trim()?.toLowerCase()))
                .map((res) => {
                    totalTime = totalTime + res.TotalHours;
                    return totalTime;
                });
        } else {
            allProjectEffortEstimation.filter(item => item.ProjectRoleResourceId === projectRole.RoleResourceId)
                .map((res) => {
                    roleTotalTime = roleTotalTime + res.TotalHours;
                    return roleTotalTime;
                })
            totalTime = projectEffortSummary.TotalHours;
        }
        return [totalTime, roleTotalTime];
    }

    private calculateCostUtilization = (projectEffortSummary: IProjectEffortSummary, allProjectEffortEstimation: IProjectEffortEstimation[], projectRole: IProjectRolesResource, selectedPhases: string[]): [number, number] => {
        let roleTotalCost: number = 0;
        let totalCost: number = 0;
        if (selectedPhases?.length > 0) {
            allProjectEffortEstimation.filter(item => item.ProjectRoleResourceId === projectRole.RoleResourceId && selectedPhases?.some(ph => ph?.trim()?.toLowerCase() == item.Phase?.trim()?.toLowerCase()))
                .map((res) => {
                    roleTotalCost = roleTotalCost + res.TotalFees;
                    return roleTotalCost;
                });
            allProjectEffortEstimation.filter(item => selectedPhases?.some(ph => ph?.trim()?.toLowerCase() == item.Phase?.trim()?.toLowerCase()))
                .map((res) => {
                    totalCost = totalCost + res.TotalFees;
                    return totalCost;
                })
        } else {
            allProjectEffortEstimation.filter(item => item.ProjectRoleResourceId === projectRole.RoleResourceId)
                .map((res) => {
                    roleTotalCost = roleTotalCost + res.TotalFees;
                    return roleTotalCost;
                })
            totalCost = projectEffortSummary.ProjectCost;
        }
        return [totalCost, roleTotalCost];
    }

    private groupRoleByPhase = (resourceEstimations: IProjectEffortEstimation[] | undefined) => {
        if (!resourceEstimations) return {};

        return resourceEstimations.reduce<{ [key: string]: IProjectEffortEstimation[] }>((acc, rEstimation) => {
            const { Phase } = rEstimation;
            if (!acc[Phase]) {
                acc[Phase] = [];
            }
            acc[Phase].push(rEstimation);
            return acc;
        }, {});
    };

    private calculatePhaseEndDate = (roleResourceEstimateIds: number[], allInitialEffortHours: IProjectEffortHours[] | null | undefined): Date | null => {
        let maxDateTo: Date | null = null;
        if (allInitialEffortHours) {
            const filteredEffortHours = allInitialEffortHours.filter(effortHour => roleResourceEstimateIds.includes(effortHour.RoleResourceEstimateId));
            if (filteredEffortHours.length === 0) {
                return null;
            }
            maxDateTo = filteredEffortHours.reduce((max: Date | null, effortHour) => {
                const currentDateTo: Date | null = effortHour.DateTo || null;

                if (!max || (currentDateTo && currentDateTo > max)) {
                    return currentDateTo;
                }

                return max;
            }, null);
        }
        return maxDateTo;
    }

    private calculatePhaseStartDate = (roleResourceEstimateIds: number[], allInitialEffortHours: IProjectEffortHours[] | null | undefined): Date | null => {
        let maxDateFrom: Date | null = null;
        if (allInitialEffortHours) {
            const filteredEffortHours = allInitialEffortHours.filter(effortHour => roleResourceEstimateIds.includes(effortHour.RoleResourceEstimateId));
            if (filteredEffortHours.length === 0) {
                return null;
            }
            maxDateFrom = filteredEffortHours.reduce((max: Date | null, effortHour) => {
                const currentDateFrom: Date | null = effortHour.DateFrom || null;

                if (!max || (currentDateFrom && currentDateFrom < max)) {
                    return currentDateFrom;
                }

                return max;
            }, null);
        }
        return maxDateFrom;
    }

    private getTableHeaderCell(name: string): TableCell {
        let cell = new TableCell({
            children: [new Paragraph({
                text: name,
                heading: HeadingLevel.HEADING_3,
                //thematicBreak: true
            })],
            borders: {
                top: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
                bottom: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
                left: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
                right: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
            },
        });

        return cell;
    }

    private getTableDataCell(data: string): TableCell {
        let cell = new TableCell({
            children: [new Paragraph({
                text: data,
                alignment: AlignmentType.RIGHT,
            })],
            borders: {
                top: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
                bottom: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
                left: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
                right: { style: BorderStyle.SINGLE, color: 'AAAAAA' },
            },
        })

        return cell;
    }
}