import { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";

interface InfoModalProps extends React.HTMLAttributes<HTMLDivElement> {
    show: boolean;
    title: string;
    htmlBody: ReactNode;
    onClose?: () => void;
    showCloseButton?: boolean | false;
    modalSize?: "modal-sm" | "" | "modal-lg" | "modal-xl";
}

const InfoModal: React.FC<InfoModalProps> = ({
    show,
    title,
    htmlBody,
    onClose,
    showCloseButton,
    modalSize
}) => {
    let modalSizeClass = 'modal-lg';

    if (modalSize) {
        modalSizeClass = modalSize;
    }

    const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
        <Modal onClick={handleModalClick} dialogClassName={modalSizeClass} show={show} onHide={() => onClose && onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {htmlBody}
            </Modal.Body>
            <Modal.Footer>
                {showCloseButton && <Button variant="secondary" onClick={() => onClose && onClose()}>
                    <i className="fas fa-times"></i>  Close
                </Button>}
            </Modal.Footer>
        </Modal>
    );
};

export default InfoModal;
