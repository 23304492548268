import { useEffect, useState } from "react";
import { ISkillBase } from "../../data-model";
import { useDelayDebounce } from "../../common/hooks/debounce";
import ConfirmationModal from "../../common/confirmation-dialog";
import { deleteSkillBase, getAllSkills, saveNewSkillBase, updateSkillBase } from "../_services/pcr-rates-svc";
import { toast } from "react-toastify";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import SkillBaseCreate from "./skill-base-create";
import { initialDefaultSkillBase } from "../_services/initial-default-data";

const SkillBaseSummary: React.FC<{}> = () => {
    const [showModal, setShowModal] = useState(false);
    const [allSkillBases, setAllSkillBases] = useState<ISkillBase[]>();
    const [loading, setLoading] = useState(true);

    const [currentSkillBase, setCurrentSkillBase] = useState<ISkillBase | null>();
    const [recordToDeleteMessage, setRecordToDeleteMessage] = useState<string>('');
    const [showRoleDeleteModal, setShowRoleDeleteModal] = useState(false);
    const delayDebouncedValue = useDelayDebounce(100)

    useEffect(() => {
        if (delayDebouncedValue) {
            fetchAllRoles();
        }
    }, [delayDebouncedValue]);

    const fetchAllRoles = async () => {
        setLoading(true);
        const data = await getAllSkills();
        if (data) {
            setAllSkillBases(data);
        } else {
            setAllSkillBases([]);
        }
        setLoading(false);
    };


    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const editRole = (skillObj: ISkillBase) => {
        setShowModal(!showModal);

        setCurrentSkillBase({
            ...skillObj
        });
    };

    const saveRole = async (skillObj: ISkillBase) => {
        let [isSuccess, message] = [false, ''];
        if (skillObj.SkillBaseId != null) {
            [isSuccess, message] = await updateSkillBase(skillObj);
        }
        else {
            [isSuccess, message] = await saveNewSkillBase(skillObj);
        }

        if (isSuccess) {
            toast.success("Saved New Role Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
            await fetchAllRoles();
        }
        else {
            toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setLoading(false);
        return [isSuccess, message]
    };

    const handleDeleteRole = (role: ISkillBase) => {
        setShowRoleDeleteModal(true);
        setCurrentSkillBase(role);
        setRecordToDeleteMessage(`Are you sure to delete role: '${role.Skill}'`);
    };

    const confirmDelete = async () => {
        if (currentSkillBase != undefined && currentSkillBase.SkillBaseId != null) {
            let [isSuccess, message] = await deleteSkillBase(currentSkillBase?.SkillBaseId);

            if (isSuccess) {
                toast.success("Role Deleted Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                await fetchAllRoles();
            }
            else {
                toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
        }
        setCurrentSkillBase(null);
        setShowRoleDeleteModal(false);
    };

    const cancelDelete = () => {
        setCurrentSkillBase(null);
        setShowRoleDeleteModal(false);
    };

    return (
        <div>
            <div>
                <ConfirmationModal
                    show={showRoleDeleteModal}
                    title="Confirm Deletion of Role"
                    message={recordToDeleteMessage}
                    onClose={() => setShowRoleDeleteModal(false)}
                    onCancel={cancelDelete}
                    onConfirm={confirmDelete}
                />
            </div>
            <div>
                {showModal && (
                    <SkillBaseCreate show={showModal} onClose={toggleModal} onSave={saveRole} initialRole={currentSkillBase ?? initialDefaultSkillBase} />
                )}
            </div>
            <div className={loading ? 'card-body table-fade' : 'card-body'}>
                <div className="table-container" style={{ minHeight: '650px', maxHeight: '650px', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #ccc' }}>
                    {loading ? (<LoadingIcon />) : ''}
                    <h2>All Skill(s)</h2>
                    <div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Skill</th>
                                    {/* <th>Description</th> */}
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allSkillBases?.map((skill) => (
                                    <tr key={skill.SkillBaseId}>
                                        <td>
                                            <Link to={`/skill-details/${skill.SkillBaseId}`}>
                                                {skill.Skill}
                                            </Link>
                                        </td>
                                        {/* <td>{role.}</td> */}
                                        <td>{skill.IsActive ? "Active" : "In-Active"}</td>
                                        <td style={{ width: '55px', border: '1px solid #ccc' }}>
                                            <a onClick={() => editRole(skill)} className="">
                                                <i className="fas fa-edit"></i>
                                            </a>
                                            <a style={{ marginLeft: '5px', color: 'red' }} onClick={() => handleDeleteRole(skill)} className="">
                                                <i className="fas fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center', paddingTop: '10px', cursor: 'pointer' }}>
                {<a onClick={() => setShowModal(true)} className="w-100">
                    <i className="fas fa-plus"></i> Add New Skill
                </a>
                }
            </div>
        </div>
    )
};

export default SkillBaseSummary;