import { useProjectDataContext } from "../../data-context/project-data-context"
import DefaultAndHSLink from "../project-header-manage/default-plan-hs-link"
import ManageProjectVersion from "../project-header-manage/manage-project-version"
import ManageProjectStatus from "../project-header-manage/project-status-manage"
import ProjectTitle from "../project-title-manage"
import CreateProjectFromTemplate from "./project-template"

const DashboardHeaderStatusTitleVersion: React.FC<{ projectId?: number }> = ({ projectId }) => {
    const { currentProjectIdGblCtx, currentProjectInfoGblCtx } = useProjectDataContext();

    return (
        <>
            <div className="row w-100">
                <div className="d-sm-flex align-items-center justify-content-between mb-2">
                    <div className="col-xl-5 col-lg-5">
                        <ProjectTitle projectId={currentProjectIdGblCtx} />
                    </div>                    
                    <div className="col-xl-2 col-lg-2" style={{textAlign:'left'}}>
                        <ManageProjectStatus projectId={currentProjectIdGblCtx} />
                    </div>
                    <div className="col-xl-3 col-lg-3">
                        {currentProjectIdGblCtx && currentProjectIdGblCtx > 0 ?
                            (<ManageProjectVersion projectId={currentProjectIdGblCtx} />) :
                            (<CreateProjectFromTemplate />)
                        }
                    </div>
                    {currentProjectInfoGblCtx?.DisplayDefaultPlanLink 
                    && currentProjectInfoGblCtx.StatusId == 100
                    && <div className="col-xl-2 col-lg-2" style={{textAlign:'right'}}>
                        <DefaultAndHSLink />
                    </div>}
                    {/* <div className="col-xl-1 col-lg-1">
                    {currentProjectInfoGblCtx?.ProjectId && currentProjectInfoGblCtx.ProjectId > 0 && (
                        <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                            <i className="fas fa-download fa-sm text-white-2"></i> Download
                        </a>
                    )}
                </div> */}
                </div>
            </div>
        </>
    )
}

export default DashboardHeaderStatusTitleVersion;