import { deleteApiData, getApiData, postApiData, putApiData } from "../../app-services";
import { IProjectEffortEstimation, IProjectRolesResource } from "../../data-model";
import { parseAndCastDate } from "../../utilities/helper-function";
import { getBaseFTTAPIURLS } from "./common-svc";

export const getProjectAllRoleResources = async (projectId: number): Promise<IProjectRolesResource[] | null> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let resources: IProjectRolesResource[] = [];
    let url = `${baseAPIUrls}/roleresource/all/${projectId}`;
    var apiResponse = await getApiData(url);
    if (apiResponse?.Success && apiResponse.Result?.length > 0) {
        resources = mapAllRoleResourcesResponse(apiResponse.Result);
    }
    return resources;
}

function mapAllRoleResourcesResponse(apiResponse: any): IProjectRolesResource[] {
    return apiResponse.map((result: any) => ({
        ...result,
        DateFrom: parseAndCastDate(result.DateFrom),
        DateTo: parseAndCastDate(result.DateTo)
    }));
}

export const getProjectAllRoleResourceEstimation = async (projectId: number): Promise<IProjectEffortEstimation[] | null> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let resources: IProjectEffortEstimation[] = [];
    let url = `${baseAPIUrls}/resource/estimation/all/${projectId}`;
    var apiResponse = await getApiData(url);
    if (apiResponse?.Success && apiResponse.Result?.length > 0) {
        resources = mapProjectAllRoleResourceEstimation(apiResponse.Result);
    }
    return resources;
}

function mapProjectAllRoleResourceEstimation(apiResponse: any): IProjectEffortEstimation[] {
    return apiResponse.map((result: any) => ({
        ...result,
        // DateFrom: parseAndCastDate(result.DateFrom),
        // DateTo: parseAndCastDate(result.DateTo)
    }));
}

export const getProjectRoleResourceEstimationByIds = async (projectId: number, roleResourceEstimationIds: number[]): Promise<IProjectEffortEstimation[] | null> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let resources: IProjectEffortEstimation[] = [];
    let url = `${baseAPIUrls}/resource/estimation/details/${projectId}`;
    var apiResponse = await postApiData(url, roleResourceEstimationIds);
    if (apiResponse?.Success && apiResponse.Result?.length > 0) {
        resources = mapProjectAllRoleResourceEstimation(apiResponse.Result);
    }
    return resources;
}

export const saveProjectRoleResource = async (roleresource: IProjectRolesResource, copyResourceAtEffort: boolean): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/roleresource/create/${copyResourceAtEffort}`;
    var apiResponse = await postApiData(url, roleresource);
    return [apiResponse?.Success, apiResponse?.Result];
}

export const applyRoleRateDiscount = async (projectId: number, rateDiscount: number): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/roleresource/apply/rate/discount/${projectId}/${rateDiscount}`;
    var apiResponse = await postApiData(url);
    return [apiResponse?.Success, apiResponse?.Result];
}

export const updateProjectRoleResource = async (roleresource: IProjectRolesResource): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/roleresource/update`;
    var apiResponse = await putApiData(url, roleresource);
    return [apiResponse?.Success, apiResponse?.Result];
}

export const deleteProjectRoleResource = async (projectRolesResource: IProjectRolesResource): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/roleresource/delete/${projectRolesResource.ProjectId}/${projectRolesResource.RoleResourceId}`;
    var apiResponse = await deleteApiData(url);
    return [apiResponse?.Success, apiResponse?.Result];
}

export const saveProjectRoleResourceEstimation = async (roleresourceEstimation: IProjectEffortEstimation): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/resource/estimation/create`;
    var apiResponse = await postApiData(url, roleresourceEstimation);
    return [apiResponse?.Success, apiResponse?.Result];
}

export const updateProjectRoleResourceEstimationOrder = async (projectId: number, roleresourceEstimations: IProjectEffortEstimation[]): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/resource/estimation/order/update/${projectId}`;
    var apiResponse = await putApiData(url, roleresourceEstimations);
    return [apiResponse?.Success, apiResponse?.Result];
}

export const updateProjectRoleResourceEstimationsInfo = async (projectId: number, roleresourceEstimations: IProjectEffortEstimation[]): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/resource/estimation/update/all/${projectId}`;
    var apiResponse = await putApiData(url, roleresourceEstimations);
    return [apiResponse?.Success, apiResponse?.Result];
}

export const duplicateProjectRoleResourceEstimationsInfo = async (roleresourceEstimations: IProjectEffortEstimation[], projectId: number, isCopyRowData: boolean | false): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let resources: IProjectEffortEstimation[] = [];
    let url = `${baseAPIUrls}/resource/estimation/duplicate/${projectId}/${isCopyRowData}`;
    var apiResponse = await putApiData(url, roleresourceEstimations);
    return [apiResponse?.Success, apiResponse?.Result];
}

export const updateProjectRoleResourceEstimation = async (roleresourceEstimation: IProjectEffortEstimation): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/resource/estimation/update`;
    var apiResponse = await putApiData(url, roleresourceEstimation);
    return [apiResponse?.Success, apiResponse?.Result];
}

export const deleteProjectRoleResourceEstimation = async (roleresourceEstimation: IProjectEffortEstimation): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/resource/estimation/delete/${roleresourceEstimation.PrjectRoleResourceDetail?.ProjectId}/${roleresourceEstimation.ProjectRoleResourceId}/${roleresourceEstimation.RoleResourceEstimateId}`;
    var apiResponse = await deleteApiData(url);
    return [apiResponse?.Success, apiResponse?.Result];
}

export const deleteProjectRoleResourceEstimations = async (projectId: number, roleresourceEstimations: IProjectEffortEstimation[]): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/resource/estimation/delete/all/${projectId}`;
    var apiResponse = await putApiData(url, roleresourceEstimations);
    return [apiResponse?.Success, apiResponse?.Result];
}





