import React, { useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { getAllPCRRateSummary } from '../_services/pcr-rates-svc';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IPCRRoleRate, IPCRRoleRateSummary } from '../../data-model';
import LoadingIcon from '../../common/loading-icon/loading-icon';
import { useDelayDebounce } from '../../common/hooks/debounce';
import { formatDateAsString } from '../../utilities/helper-function';

const IPCRRatesGrid: React.FC<{}> = () => {
  const delayDebouncedValue = useDelayDebounce(100)
  const [loading, setLoading] = useState(true);
  const [allPCRRates, setAllPCRRates] = useState<IPCRRoleRateSummary[]>([]);

  useEffect(() => {
    if (delayDebouncedValue > 0) {
      fetchAllPCRRates();
    }
  }, [delayDebouncedValue]);

  const fetchAllPCRRates = async () => {
    setLoading(true);
    const data = await getAllPCRRateSummary();
    if (data) {
      setAllPCRRates(data);
    }
    setLoading(false);
  };

  return (
    <div>
      <h2>PCR Rates</h2>
      <div className={loading ? 'card-body table-fade' : 'card-body'}>
        <div>
          <Link to={`/import-pcr-rates`}>
            <i className="fas fa-project"></i>
            <span>Import PCR Rate from Previous Year</span>
          </Link>
        </div>
        <div className="table-container" style={{ minHeight: '350px', maxHeight: '350px', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #ccc' }}>
          {loading ? (<LoadingIcon />) : ''}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Year</th>
                <th>Valid From</th>
                <th>Valid To</th>
                <th>Default?</th>
              </tr>
            </thead>
            <tbody>
              {allPCRRates.map((item) => (
                <tr key={item.Year}>
                  <td>
                    <Link to={`/pcr-rates-detail/${item.Year}`}>
                      {item.Year}
                    </Link>
                  </td>
                  <td>{formatDateAsString(item.ValidFrom)}</td>
                  <td>{formatDateAsString(item.ValidTo)}</td>
                  <td>{item.IsDefault ? "Yes" : "No"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default IPCRRatesGrid;