import { useState } from "react";
import { Button } from "react-bootstrap";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { toast } from "react-toastify";
import { clearCache, createAllApplicationMasterData } from "../_services/master-data-svc";

const ManageMasterData: React.FC<{}> = ({ }) => {

    const [loadingCreateMasterData, setLoadingCreateMasterData] = useState<boolean>(false);
    const [disabledCreateMasterDataButton, setDisabledCreateMasterDataButton] = useState<boolean>(false);

    const handleCreateMasterData = async () => {
        setLoadingCreateMasterData(true);
        setDisabledCreateMasterDataButton(true);
        let isSuccess = await createAllApplicationMasterData();
        if (isSuccess) {
            toast.success("Successfully Created All Master Data. See Seq for more information.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
        }
        else {
            toast.error("Error occurred", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setDisabledCreateMasterDataButton(false);
        setLoadingCreateMasterData(false);
    }

    return (
        <>
            <div style={{ marginLeft: '30%', minHeight: '410px', maxHeight: '410px' }}>
                {loadingCreateMasterData && <LoadingIcon />}
                <div className="d-flex justify-content-between">
                    <Button disabled={disabledCreateMasterDataButton} variant="primary" onClick={handleCreateMasterData}>
                        Create All Master Data
                    </Button>
                </div>
            </div>
        </>
    );
}


export default ManageMasterData;