import { deleteApiData, getApiData, postApiData, putApiData } from "../../app-services";
import IUserComment from "../../data-model/user-comment";
import { parseAndCastDate } from "../../utilities/helper-function";
import { getBaseFTTAPIURLS } from "./common-svc";

export const getProjectAllUserComments = async (projectId: number): Promise<IUserComment[] | null> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let resources: IUserComment[] = [];
    let url = `${baseAPIUrls}/userComments/get/all/${projectId}`;
    var apiResponse = await getApiData(url);
    if (apiResponse?.Success && apiResponse.Result?.length > 0) {
        resources = mapAllRoleResourcesResponse(apiResponse.Result);
    }
    return resources;
}

function mapAllRoleResourcesResponse(apiResponse: any): IUserComment[] {
    return apiResponse.map((result: any) => ({
        ...result,
        CreatedDate: parseAndCastDate(result.CreatedDate)
    }));
}

export const saveUserCommentAndAttachment = async (projectId: number, fromData: FormData): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/usercomments/create/comment/${projectId}`;
    var apiResponse = await postApiData(url, fromData, 'multipart/form-data');
    return [apiResponse?.Success, apiResponse?.Result];
}

export const updateUserCommentAndAttachment = async (projectId: number, fromData: FormData): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/usercomments/update/comment/${projectId}`;
    var apiResponse = await putApiData(url, fromData, 'multipart/form-data');
    return [apiResponse?.Success, apiResponse?.Error];
}

export const deleteUserCommentAndAttachment = async (projectId: number, commentId: number): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/usercomments/delete/comment/${projectId}/${commentId}`;
    var apiResponse = await deleteApiData(url);
    return [apiResponse?.Success, apiResponse?.Result];
}

export const downloadCommentAttachment = async (projectId: number, commentId: number): Promise<[boolean, any]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/usercomments/download/attachment/${projectId}/${commentId}`;
    var apiResponse = await getApiData(url, null, undefined, "blob");
    return [apiResponse?.Success, apiResponse];
}

export const getUserCommentAttachmentBlobUrl = async (projectId: number, commentId: number): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/usercomments/get/bloburl/${projectId}/${commentId}`;
    var apiResponse = await getApiData(url);
    return [apiResponse?.Success, apiResponse?.Result];
}