import React from 'react'
import { Navigate, useLocation } from "react-router-dom"
import { useProjectDataContext } from '../../data-context/project-data-context';
import { IsStringExists } from '../../utilities/helper-function';

interface PrivateRouteProps {
  roles: string[];
  children: JSX.Element
}

const ProtectedRoute: React.FC<PrivateRouteProps> = ({ roles, children }) => {
  let location = useLocation();
  const { userAccessInfoGblCtx } = useProjectDataContext();
  let isValidAccess = IsStringExists(roles, userAccessInfoGblCtx?.AssignedRoles);
  if (!isValidAccess) {
    return <Navigate to="/login-access-denied" state={{ from: location }} replace />
  }

  return children
};

export default ProtectedRoute;