import { deleteApiData, getApiData, patchApiData, postApiData, putApiData } from '../../app-services'
import { IAPIRequestFilter, ILandingDashboard, IProjectHeaderDetails, IProjectStatus, IProjectSummary, IProjectVersion } from '../../data-model';
import { parseAndCastDate } from '../../utilities/helper-function';
import { getBaseFTTAPIURLS } from './common-svc';
import { APIRequestParam } from './service-request-model/api-request-param';

const getAllProjectStatusByProjectId = async (projectId: number): Promise<IProjectStatus[]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let allProjectStatus: IProjectStatus[] = [];
  try {
    let url = `${baseAPIUrls}/project/status/all/${projectId}`;
    var apiResponse = await getApiData(url);
    if (apiResponse?.Success && apiResponse.Result?.length > 0) {
      allProjectStatus = mapProjectStatusResponse(apiResponse.Result);
    }
  } catch (e) {
    console.error('Unable to connect API server: ', baseAPIUrls);
  }
  return allProjectStatus;
}

function mapProjectStatusResponse(apiResponse: any): IProjectStatus[] {
  return apiResponse.map((result: any) => ({
    ...result
  }));
}

const getAllProjectStatus = async (): Promise<IProjectStatus[]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let allProjectStatus: IProjectStatus[] = [];
  try {
    let url = `${baseAPIUrls}/masterdata/project/all/status`;
    var apiResponse = await getApiData(url);
    if (apiResponse?.Success && apiResponse.Result?.length > 0) {
      allProjectStatus = mapProjectStatusResponse(apiResponse.Result);
    }
  } catch (e) {
    console.error('Unable to connect API server: ', baseAPIUrls);
  }
  return allProjectStatus;
}

const getAllProjectSummary = async (apiRequestParam: APIRequestParam): Promise<[number, IProjectSummary[] | null]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let projectAllSummary: IProjectSummary[] = [];
  let totalRecords: number = 0;
  try {
    let url = `${baseAPIUrls}/project/summary/all`;
    var apiResponse = await postApiData(url, apiRequestParam);

    if (apiResponse?.Success && apiResponse.Result?.length > 0) {
      projectAllSummary = mapProjectsSummaryResponse(apiResponse.Result);
      totalRecords = +apiResponse?.TotalRecord;
    }
  } catch (e) {
    throw 'Unable to connect API server: ' + baseAPIUrls;
  }
  return [totalRecords, projectAllSummary];
}

function mapProjectsSummaryResponse(apiResponse: any): IProjectSummary[] {
  return apiResponse.map((result: any) => ({
    ...result,
    CreateDate: parseAndCastDate(result.CreateDate),
    ModifiedDate: parseAndCastDate(result.ModifiedDate ?? '')
  }));
}

const getProjectHeader = async (projectId: number): Promise<IProjectHeaderDetails | null> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/project/details/${projectId}`;

  var apiResponse = await getApiData(url);
  if (apiResponse?.Success && apiResponse?.Result) {
    return mapProjectHeaderApiResponse(apiResponse.Result);
  }
  return null;
}

function mapProjectHeaderApiResponse(apiResponse: any): IProjectHeaderDetails {
  return {
    ...apiResponse,
    DatePrepared: parseAndCastDate(apiResponse.DatePrepared),
    StartDate: parseAndCastDate(apiResponse.StartDate),
    EndDate: parseAndCastDate(apiResponse.EndDate),
  };
}

const saveNewProjectHeader = async (projectHeader: IProjectHeaderDetails): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/project/create`;
  var apiResponse = await postApiData(url, projectHeader);
  return [apiResponse?.Success, apiResponse?.Result];
}

const createNewProjectFromTemplate = async (templateProjectId: number, isCopyResourceEstimation: boolean, isCopyEffort: boolean, isCopyRisk: boolean): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/project/create/template`;

  let req = {
    ProjectId: templateProjectId,
    IsCopyResourceEstimation: isCopyResourceEstimation,
    IsCopyEffort: isCopyEffort,
    IsCopyRisk: isCopyRisk
  }
  var apiResponse = await postApiData(url, req);
  return [apiResponse?.Success, apiResponse?.Result];
}

const createProjectVersion = async (projectId: number, userComment: string, versionName: string, useLatestPCRRate: boolean, parentProjectId?: number | null): Promise<[boolean, string, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/project/create/version`;
  let req = {
    ProjectId: projectId,
    parentProjectId: parentProjectId,
    Comment: userComment,
    VersionName: versionName,
    UseLatestPCRRate: useLatestPCRRate
  }
  var apiResponse = await postApiData(url, req);
  return [apiResponse?.Success, apiResponse?.Result, apiResponse?.Error];
}

const updateProjectHeader = async (projectHeader: IProjectHeaderDetails): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/project/update`;
  var apiResponse = await putApiData(url, projectHeader);
  return [apiResponse?.Success, apiResponse?.Result];
}

const deleteProject = async (projectId: number): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/project/delete/${projectId}`;
  var apiResponse = await deleteApiData(url);
  return [apiResponse?.Success, apiResponse?.Result];
}

const patchProjectData = async (projectId: number, pathProperty: IAPIRequestFilter[]): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/project/patch/${projectId}`;
  let message: string = '';
  var apiResponse = await patchApiData(url, pathProperty);
  if (apiResponse?.Success && apiResponse?.Result) {
    message = apiResponse.Result;
  } else {
    message = apiResponse.Error;
  }
  return [apiResponse?.Success, message];
}

const getAllProjectVersion = async (projectId: number): Promise<[number, IProjectVersion[] | null]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let projectAllVersion: IProjectVersion[] = [];
  let totalRecords: number = 0;
  try {
    let url = `${baseAPIUrls}/project/version/all/${projectId}`;
    var apiResponse = await postApiData(url, projectId);
    if (apiResponse?.Success && apiResponse.Result?.length > 0) {
      projectAllVersion = mapProjectVersionResponse(apiResponse.Result);
      totalRecords = +apiResponse?.TotalRecord;
    }
  } catch (e) {
    throw 'Unable to connect API server: ' + baseAPIUrls;
  }
  return [totalRecords, projectAllVersion];
}

function mapProjectVersionResponse(apiResponse: any): IProjectVersion[] {
  return apiResponse.map((result: any) => ({
    ...result
  }));
}

const getLandingProjectSummary = async (): Promise<ILandingDashboard | null> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/project/summary/my/details`;

  var apiResponse = await getApiData(url);
  if (apiResponse?.Success && apiResponse?.Result) {
    return apiResponse?.Result;
  }
  return null;
}

export {
  getAllProjectStatusByProjectId, getAllProjectStatus, getProjectHeader, saveNewProjectHeader,
   updateProjectHeader, patchProjectData, deleteProject, getAllProjectSummary, createProjectVersion, 
   getAllProjectVersion, createNewProjectFromTemplate, getLandingProjectSummary
};