import { deleteApiData, getApiData, postApiData, putApiData } from "../../app-services";
import { IApprover } from "../../data-model/approver";
import IApproverBUUnits from "../../data-model/approver-buunits";
import { getBaseFTTAPIURLS } from "./common-svc";

const getAllApproverBUUnits = async (): Promise<IApproverBUUnits[]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let allBUUnits: IApproverBUUnits[] = [];
    try {
        let url = `${baseAPIUrls}/approver/buunits`;
        var apiResponse = await getApiData(url);
        if (apiResponse?.Success && apiResponse.Result?.length > 0) {
            allBUUnits = mapApproverBUUnitResponse(apiResponse.Result);
        }
    } catch (e) {
        console.error('Unable to connect API server: ', baseAPIUrls);
    }
    return allBUUnits;
}

function mapApproverBUUnitResponse(apiResponse: any): IApproverBUUnits[] {
    return apiResponse.map((result: any) => ({
        ...result
    }));
}

const getAllApprovers = async (): Promise<IApprover[] | null> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/approver/get/all`;
    var apiResponse = await getApiData(url);
    let allSkills: IApprover[] = [];
    if (apiResponse?.Success && apiResponse.Result?.length > 0) {
        allSkills = mapAllSkillBaseResponse(apiResponse.Result);
    }
    return allSkills;
}

function mapAllSkillBaseResponse(apiResponse: any): IApprover[] {
    return apiResponse.map((result: any) => ({
        ...result
    }));
}


const saveNewApprover = async (approver: IApprover): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/approver/create`;
    var apiResponse = await postApiData(url, approver);
    return [apiResponse?.Success, apiResponse?.Success == true ? apiResponse?.Result : apiResponse?.Error];
}

const updateApprover = async (approver: IApprover): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/approver/update`;
    var apiResponse = await putApiData(url, approver);
    return [apiResponse?.Success, apiResponse?.Result];
}

const deleteApprover = async (approverId: number): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/approver/delete/${approverId}`;
    var apiResponse = await deleteApiData(url);
    return [apiResponse?.Success, apiResponse?.Result];
}

const getCurrentUserIsApprover = async (): Promise<boolean> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let isApprover: boolean = false;
    try {
      let url = `${baseAPIUrls}/approver/check/current/user`;
      var apiResponse = await getApiData(url);
      if (apiResponse?.Success) {
        isApprover = apiResponse.Result;
      }
    } catch (e) {
      console.error('Unable to connect API server: ', baseAPIUrls);
    }
    return isApprover;
  }

export { getAllApproverBUUnits, getAllApprovers, saveNewApprover, updateApprover, deleteApprover,
    getCurrentUserIsApprover
 }