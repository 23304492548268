import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { IPCRRoleRate, IRole } from "../../data-model";
import { attachedPCRRateData, getAllAvailableRoles, updatePCRRateData } from "../_services/pcr-rates-svc";
import IPCRRoleRateReq from "../../data-model/pcr-rates-req";
import { areObjectsEqual } from "../../utilities/helper-function";

const ModifyPCRRateModal: React.FC<{
    show: boolean;
    year: number;
    onClose: (isSuccess: boolean) => void;
    inputPCR: IPCRRoleRateReq | undefined;
    isAttachRole?: boolean | false;
}> = ({ show, year, onClose, inputPCR, isAttachRole }) => {

    const [currentPCRRateReq, setCurrentPCRRateReq] = useState<IPCRRoleRateReq | undefined>();
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const [disabledModifyButton, setDisabledModifyButton] = useState<boolean>(false);
    const [allAvailableRoles, setAllAvailableRoles] = useState<IRole[]>([]);

    useEffect(() => {
        if (show && inputPCR) {
            setCurrentPCRRateReq(inputPCR);
        }
    }, [show]);

    useEffect(() => {
        setDisabledModifyButton(areObjectsEqual(inputPCR ?? {}, currentPCRRateReq ?? {}))
    }, [currentPCRRateReq]);

    useEffect(() => {
        setAllAvailableRoles([])
        if (isAttachRole && show) {
            fetchAllAvailableRoles()
        }
    }, [isAttachRole, show]);

    const fetchAllAvailableRoles = async () => {
        let data = await getAllAvailableRoles(year);
        if (data) {
            setAllAvailableRoles(data);
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name == "Rate" && currentPCRRateReq) {
            let p = parseInt(value);
            setCurrentPCRRateReq({ ...currentPCRRateReq, Rate: p });
        }
        if (name == "Role" && currentPCRRateReq) {
            let p = parseInt(value);
            setCurrentPCRRateReq({ ...currentPCRRateReq, RoleId: p });
        }

        if (validationErrors[name]) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }

        var errors = validateFormData(name, value);
        if (Object.keys(errors).length > 0) {
            setDisabledModifyButton(true)
        } else {
            setDisabledModifyButton(false)
        }
    };

    const validateFormData = (name: string, value: string): { [key: string]: string } => {
        const errors: { [key: string]: string } = {};
        if (isAttachRole && !currentPCRRateReq?.RoleId) {
            errors.Role = 'Role is required';
        }

        if (!currentPCRRateReq?.Rate) {
            errors.Rate = 'Rate is required';
        }

        if (!(currentPCRRateReq?.Rate && currentPCRRateReq?.Rate >= 0 && currentPCRRateReq?.Rate <= 999)) {
            errors.Rate = 'Rate should be greater $0.00 and less than $999.00';
        }

        if (validationErrors[name]) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
        return errors;
    }

    const handleAddOrModifyPCRRateClick = async () => {
        setDisabledModifyButton(true);
        const errors = validateFormData("", "");
        if (Object.keys(errors).length == 0) {
            toast.info('Saving PCR Rate...', { toastId: 'info1', autoClose: 1000, position: toast.POSITION.TOP_CENTER });

            if (!isAttachRole) {
                if (inputPCR?.PCRRoleRateId && inputPCR?.PCRRoleRateId > 0) {
                    let [isSuccess, message] = await updatePCRRateData(currentPCRRateReq)
                    if (isSuccess) {
                        toast.success("Rate Updated Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                        onClose(isSuccess);
                    }
                    else {
                        toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
                    }
                }
            } else {

                let [isSuccess, message] = await attachedPCRRateData(currentPCRRateReq)
                if (isSuccess) {
                    toast.success("Rate Added Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                    onClose(isSuccess);
                }
                else {
                    toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
                }
            }
        } else {
            setValidationErrors(errors);
        }
        setDisabledModifyButton(false);
    };

    const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
        <div id="auditTransaction" className='auditTransaction'>
            <Modal onClick={handleModalClick} show={show} onHide={() => onClose(false)} onClose={() => onClose(false)} centered>
                <Modal.Header closeButton>
                    {isAttachRole ? `Attach New Role` : `Modify PCR Rate: ${inputPCR?.RoleName}`}
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {isAttachRole && <div className="row">
                            <div className="col-md-4">
                                <Form.Group controlId="formPCRRate">
                                    <Form.Label>Select Role</Form.Label>
                                    <Form.Control as="select" name="Role"
                                        isInvalid={!!validationErrors.Role}
                                        disabled={!isAttachRole}
                                        value={currentPCRRateReq?.RoleId ?? -1}
                                        onChange={handleInputChange}>
                                        <option value="-1" disabled>Select a Role</option>
                                        {
                                            allAvailableRoles?.map((role, index) => (
                                                <option key={index} value={role.RoleId} title={role.Description}>
                                                    {role.Name}
                                                </option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            {
                                validationErrors.Role && <p style={{ color: 'red' }} className="error-message">{validationErrors.Role}*</p>
                            }
                        </div>
                        }
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group controlId="formPCRRate">
                                    <Form.Label>PCR Rate</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="Rate"
                                        value={currentPCRRateReq?.Rate}
                                        isInvalid={!!validationErrors.Rate}
                                        onChange={handleInputChange}
                                        placeholder="Enter your rate..."
                                    />
                                </Form.Group>
                            </div>
                            {
                                validationErrors.Rate && <p style={{ color: 'red' }} className="error-message">{validationErrors.Rate}*</p>
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group controlId="formRoleIsActive">
                                    <Form.Check
                                        type="checkbox"
                                        label="Is Active"
                                        name="IsActive"
                                        checked={currentPCRRateReq?.IsActive ?? false}
                                        onChange={(e) => {
                                            setCurrentPCRRateReq((prevData) => ({
                                                ...prevData,
                                                IsActive: e.target.checked,
                                            }))
                                        }
                                        }
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ marginTop: '15px' }} variant="secondary" onClick={() => onClose(false)}>
                        Close
                    </Button>
                    <Button style={{ marginLeft: '10px', marginRight: '10px' }} disabled={disabledModifyButton} variant="primary" onClick={handleAddOrModifyPCRRateClick}>
                        {isAttachRole ? 'Attach Role' : 'Update Rate'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ModifyPCRRateModal;