import { useProjectDataContext } from "../../data-context/project-data-context";
import { calculateDiscount, formatDateAsString, formatToUSCurrency, getRandomNumber } from "../../utilities/helper-function";

const ProjectPhaseTimeUtilizationReport: React.FC<{}> = ({ }) => {
    const { allProjectPhaseUtilizationGblCtx, projectEffortSummaryGblCtx } = useProjectDataContext();
    let title = 'Phase/Time Utilization';

    return (
        <>
            <div style={{ overflowY: 'scroll' }}>
                <table>
                    <thead>
                        <tr style={{ height: '10px', width: '45%', color: 'white', backgroundColor: '#1DB1AF', textAlign: 'center', fontWeight: 'bold' }}>
                            <td colSpan={3}>
                                {title}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {allProjectPhaseUtilizationGblCtx.map((item) => {
                            let phaseCostPercent = 0;
                            if (projectEffortSummaryGblCtx.ProjectCost > 0 && item.TotalCostUtilize >= 0) {
                                phaseCostPercent = (item.TotalCostUtilize * 100) / projectEffortSummaryGblCtx.ProjectCost;
                            }

                            let phaseTimePercent = 0;
                            if (projectEffortSummaryGblCtx.TotalHours > 0 && item.TotalTimeUtilize >= 0) {
                                phaseTimePercent = (item.TotalTimeUtilize * 100) / projectEffortSummaryGblCtx.TotalHours
                            }

                            return (
                                <tr key={`phasetime${getRandomNumber()}`}>
                                    <td style={{ fontWeight: 'bold', width: '80%' }} title={formatDateAsString(item.PhaseStartDate) + ' - ' + formatDateAsString(item.PhaseEndDate)}>{(item.ProjectPhase == 'null' || item.ProjectPhase == null || item.ProjectPhase == '') ? 'N/A' : item.ProjectPhase}</td>
                                    {<td style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey', paddingRight: '3px' }}>{phaseTimePercent?.toFixed(2)}%</td>}
                                    {<td style={{ fontWeight: 'bold', paddingLeft: '3px' }}>{item.TotalTimeUtilize?.toFixed(2)}</td>}
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )

}

export default ProjectPhaseTimeUtilizationReport;