import { getApiData } from "../../app-services";
import { IApprover } from "../../data-model/approver";
import { getBaseFTTAPIURLS } from "./common-svc";

export const getAllApprover = async (projectId?: number): Promise<[boolean, IApprover[], string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let effortHours: IApprover[] = [];
  let url = `${baseAPIUrls}/approver/get/all/${projectId}`;
  var apiResponse = await getApiData(url);

  return [apiResponse?.Success, apiResponse?.Result, apiResponse.Error]
}