import React, { useEffect, useState } from 'react';
import './project-layout-shared.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import APPRoute from '../../router/app-routes';
import { useMsal } from '@azure/msal-react';
import AutocompleteSearch from '../../common/auto-complete/auto-complete-search';
import { IProjectTemplate } from '../../data-model';
import { fetchAllMatchedProjectTemplateData } from '../_services/master-data-svc';
import { useProjectDataContext } from '../../data-context/project-data-context';
import resultantLogo from '../../../assets/RES_R_White_250px.png';
import resultantWordLogo from '../../../assets/RES_Word_w_Tag_Blue_250px.png';
import DashboardHeaderStatusTitleVersion from '../project-manage-home/dashboard-title-status-version-display';
import { getPathFromUrl } from '../../utilities/helper-function';

const ProjectLandingLayout: React.FC<{}> = ({ }) => {
    const { currentProjectIdGblCtx, currentProjectInfoGblCtx, userAccessInfoGblCtx } = useProjectDataContext();

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    let className = "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion";
    const [style, setStyle] = useState<string>(className + " toggled");
    const [sideMenu, setSideMenu] = useState<boolean>(true);
    const [slectedProjectTemplate, setSlectedProjectTemplate] = useState<IProjectTemplate | undefined>();

    const location = useLocation();
    let buildEnv = (window as any).REACT_APP_DEPLOYMENT_ENVIRONMENT != 'PROD' ? (window as any).REACT_APP_DEPLOYMENT_ENVIRONMENT : ''
    let basePath = getPathFromUrl(location.pathname);
    const handleLogout = async () => {
        await instance.logoutRedirect({
            account: activeAccount
        }).catch((error) =>
            console.error("logout error: ", error)
        );
    }
    
    let [historyIndex, setHistoryIndex] = useState(
        window.history.state?.idx
    );
    
    useEffect(() => {
        setHistoryIndex(window.history.state?.idx);
    }, [location]);

    const rootMenuClicked = (isMainmenu?: boolean) => {
        if (isMainmenu) {
            setSideMenu(false)
        } else {
            setSideMenu(true)
        }
    }

    const subMenuClicked = () => {
        setSideMenu(!sideMenu)
    }

    const changeStyle = () => {
        if (style === className) {
            setStyle(className + " toggled")
        }
        else {
            setStyle(className);
        }
    };

    const changeStyle1 = () => {
        if (style === className) {
            setStyle(className + " toggled1");
        }
        else {
            setStyle(className)
        }
    };

    const handleProjectTemplateSelected = (item: IProjectTemplate | undefined, selectedText: string) => {
        setSlectedProjectTemplate(item);
    };

    return (
        <div>
            <ToastContainer autoClose={60000} toastStyle={{ backgroundColor: "white" }} />
            {/*  <!-- Page Wrapper --> */}
            <div id="wrapper">
                {/*  <!-- Sidebar --> */}
                <ul className={style} id="accordionSidebar">

                    {/*  <!-- Sidebar - Brand --> */}
                    <Link style={{ marginTop: '20px', textAlign: 'center' }} className="nav-link collapsed" to={`/home`}>
                        <img src={resultantLogo} style={{ width: '35px', height: '50px' }} className="card-img-top" title={`Financial Tool`} />
                    </Link>

                    {/*   <!-- Divider --> */}
                    <hr className="sidebar-divider my-0" />

                    {/*  <!-- Nav Item - Dashboard --> */}
                    {/* <li style={{ marginTop: '20px', textAlign:'center' }} className="nav-item active">
                        <img src={resultantLogo} style={{ width: '35px', height: '50px' }} className="card-img-top" title={`Financial Tool`} />
                    </li> */}
                    <li style={{ marginTop: '20px' }} className="nav-item active">
                        <Link onClick={(e) => rootMenuClicked(true)} className="nav-link collapsed" to={`/home`}>
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>

                    {/*  <!-- Divider --> */}
                    <hr className="sidebar-divider" />

                    {/*   <!-- Heading --> */}
                    <div className="sidebar-heading">
                        Interface
                    </div>

                    {/*  <!-- Nav Item - Pages Collapse Menu --> */}
                    {currentProjectIdGblCtx && currentProjectIdGblCtx > 0 && <li className="nav-item">
                        <a onClick={(e) => rootMenuClicked(false)} className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSS"
                            aria-expanded="true" aria-controls="collapseSS">
                            <i className="fas fa-landmark"></i>
                            <span>Project Home</span>
                        </a>
                        {sideMenu && <div id="collapseSS" className='collapse' aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <h6 className="collapse-header">Menu:</h6>
                                <Link onClick={subMenuClicked} className="collapse-item" to={`/project-dashboard/${currentProjectIdGblCtx}`}>
                                    <span>Project Home</span>
                                </Link>
                                <Link onClick={subMenuClicked} className="collapse-item" to={`/project-summary`}>
                                    <span>Project Summary</span>
                                </Link>
                                <Link onClick={subMenuClicked} className="collapse-item" to={`/page-in-construction`}>
                                    <span>Baseline</span>
                                </Link>
                                <Link onClick={subMenuClicked} className="collapse-item" to={`/page-in-construction`}>
                                    <span>Forecast</span>
                                </Link>
                                <Link onClick={subMenuClicked} className="collapse-item" to={`/page-in-construction`}>
                                    <span>Actual</span>
                                </Link>
                                <Link onClick={subMenuClicked} className="collapse-item" to={`/invoice-schedule/${currentProjectIdGblCtx}`}>
                                    <span>Invoice Schedule</span>
                                </Link>
                                {/* <Link onClick={subMenuClicked} className="collapse-item" to={`/project-template-new`}>
                                    <span>New Project Template</span>
                                </Link> */}
                                {/* <Link onClick={subMenuClicked} className="collapse-item" to={`/page-in-construction`}>
                                    <span>Risk Estimation</span>
                                </Link> */}
                            </div>
                        </div>
                        }
                    </li>
                    }
                    {/* <!-- Nav Item - Utilities Collapse Menu --> */}
                    {userAccessInfoGblCtx?.IsSuperAdminUser && <li className="nav-item">
                        <a onClick={(e) => rootMenuClicked(false)} className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
                            aria-expanded="true" aria-controls="collapseUtilities">
                            <i className="fas fa-fw fa-wrench"></i>
                            <span>Manage Rates</span>
                        </a>
                        {sideMenu && (<div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
                            data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <h6 className="collapse-header">Rates </h6>
                                <Link onClick={subMenuClicked} className="collapse-item" to={`/pcr-rates-manage`}>
                                    <span>PCR Rates</span>
                                </Link>
                                <Link onClick={subMenuClicked} className="collapse-item" to={`/role-summary`}>
                                    <span>All Roles</span>
                                </Link>
                                <Link onClick={subMenuClicked} className="collapse-item" to={`/skill-base-summary`}>
                                    <span>Skill Base</span>
                                </Link>
                            </div>
                        </div>
                        )}
                    </li>
                    }

                    {/*  <!-- Nav Item - Pages Collapse Menu --> */}
                    {userAccessInfoGblCtx?.IsSuperAdminUser && <li className="nav-item">
                        <a onClick={(e) => rootMenuClicked(false)} className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
                            aria-expanded="true" aria-controls="collapseTwo">
                            <i className="fas fa-fw fa-cog"></i>
                            <span>Manage Settings</span>
                        </a>
                        {sideMenu && (<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <h6 className="collapse-header">Manage Settings:</h6>
                                <Link onClick={subMenuClicked} className="collapse-item" to={`/approver-summary`}>
                                    <span>Approvers</span>
                                </Link>
                                <Link onClick={subMenuClicked} className="collapse-item" to={`/clear-cache`}>
                                    <span>Refresh Cache</span>
                                </Link>
                                <Link onClick={subMenuClicked} className="collapse-item" to={`/create-master-data`}>
                                    <span>Create All Master Data</span>
                                </Link>
                                <a onClick={subMenuClicked} className="collapse-item" href="">Set Time-Zone</a>
                            </div>
                        </div>
                        )}
                    </li>
                    }
                </ul>

                {/*  <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">

                    {/*  <!-- Main Content --> */}
                    <div id="content">

                        {/*  <!-- Topbar --> */}
                        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-2 static-top shadow" style={{ height: '60px', boxShadow: 'none' }}>

                            {/*  <!-- Sidebar Toggle (Topbar) --> */}
                            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={changeStyle1}>
                                <i className="fa fa-bars"></i>
                            </button>
                            {(location.pathname?.includes('project-dashboard')
                                || location.pathname?.includes('project-template-new'))
                                && <DashboardHeaderStatusTitleVersion />
                            }
                            {(basePath === 'home' || basePath === '')
                                && <div className="d-flex justify-content-center" style={{ width: '100%', marginLeft: '20px' }}>
                                    <img src={resultantWordLogo} style={{ width: '175px', height: '50px' }} className="card-img-top" title={`Financial Tool`} />
                                    <span style={{ marginTop: '3px', fontStyle: 'italic', fontWeight: 'bolder', fontSize: '19pt', color: '#0075C9' }}>Pricing Tool </span>
                                </div>
                            }
                            {(currentProjectInfoGblCtx && currentProjectIdGblCtx && location.pathname?.includes('invoice-schedule'))
                                && <div style={{ marginLeft: '50px', fontWeight: 'bold' }}>{currentProjectInfoGblCtx?.Title} ({currentProjectInfoGblCtx?.VersionName ?? 'Main Version'})</div>
                            }
                            {/*  <!-- Topbar Navbar --> */}
                            <ul className="navbar-nav ml-auto">

                                {/*  <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
                                <li className="nav-item dropdown no-arrow d-sm-none">
                                    <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-search fa-fw"></i>
                                    </a>
                                    {/*   <!-- Dropdown - Messages --> */}
                                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                                        aria-labelledby="searchDropdown">
                                        <form className="form-inline mr-auto w-100 navbar-search">
                                            <div className="input-group">
                                                <input type="text" className="form-control bg-light border-0 small"
                                                    placeholder="Search for..." aria-label="Search"
                                                    aria-describedby="basic-addon2" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button">
                                                        <i className="fas fa-search fa-sm"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </li>

                                <div className="topbar-divider d-none d-sm-block"></div>

                                {/* <!-- Nav Item - User Information --> */}
                                <li className="nav-item dropdown no-arrow">
                                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{activeAccount?.name}</span>
                                        <img className="img-profile rounded-circle"
                                            src="/img/undraw_profile.svg" />
                                    </a>
                                    {/*  <!-- Dropdown - User Information --> */}
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                        aria-labelledby="userDropdown">
                                        <a className="dropdown-item" target="_blank" href="https://myaccount.microsoft.com/?ref=MeControl">
                                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Profile
                                        </a>
                                        <a className="dropdown-item" target="_blank" href="https://myaccount.microsoft.com/settingsandprivacy/language">
                                            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Settings
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Activity Log
                                        </a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={handleLogout} href="#" data-toggle="modal" data-target="#logoutModal">
                                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Logout
                                        </a>
                                    </div>
                                </li>

                            </ul>

                        </nav>
                        {/*  <!-- End of Topbar --> */}

                        {/* /*   <!-- End of Main Content --> */}

                        <div className="container-fluid">
                            <div className='row'>
                                <Outlet />
                            </div>
                        </div>
                    </div>

                    {/*   <!-- /.container-fluid --> */}

                    {/* <!-- Footer -->  */}
                    <footer className="sticky-footer bg-white">
                        <div className="container my-auto">
                            <div style={{ paddingLeft: '40%' }} className="copyright text-center my-auto d-flex justify-content-between">
                                <span className=''>Copyright &copy; Resultant, {new Date().getFullYear()}</span>
                                <span className='text-sm'>{buildEnv} Build Number: <strong>{(window as any).REACT_APP_DEPLOYMENT_BUILD_NUMBER}</strong></span>
                            </div>
                        </div>
                    </footer>
                    {/* <!-- End of Footer --> */}

                    {/*  <!-- End of Content Wrapper --> */}
                </div>
            </div>
        </div>
    )
}

export default ProjectLandingLayout;
