import { useState } from "react";
import AutocompleteSearch from "../../common/auto-complete/auto-complete-search";
import { useProjectDataContext } from "../../data-context/project-data-context";
import { IProjectTemplate } from "../../data-model";
import { fetchAllMatchedProjectTemplateData } from "../_services/master-data-svc";
import { Button } from "react-bootstrap";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { createNewProjectFromTemplate } from "../_services/dashboard-svc";
import { toast } from "react-toastify";

const CreateProjectFromTemplate: React.FC<{}> = ({ }) => {
    const { currentProjectIdGblCtx, setCurrentProjectIdGblCtx, currentProjectInfoGblCtx } = useProjectDataContext();

    const [slectedProjectTemplate, setSlectedProjectTemplate] = useState<IProjectTemplate | undefined>();
    const [disabledProjectNewButton, setDisabledProjectNewButton] = useState<boolean>(true);
    const [loadingProjectTemplate, setLoadingProjectTemplate] = useState<boolean>(false);
    const [copyResourceEstimation, setCopyResourceEstimation] = useState<boolean>(true);
    const [copyEffort, setCopyEffort] = useState<boolean>(true);
    const [copyRisk, setCopyRisk] = useState<boolean>(true);

    const handleCreateFromTemplate = async () => {
        setLoadingProjectTemplate(true);
        setDisabledProjectNewButton(true);
        if (slectedProjectTemplate?.ProjectId && slectedProjectTemplate?.ProjectId > 0) {
            let [isSuccess, message] = await createNewProjectFromTemplate(slectedProjectTemplate.ProjectId, copyResourceEstimation, copyEffort, copyRisk);
            if (isSuccess) {
                let newProjectId = parseInt(message);
                setCurrentProjectIdGblCtx(newProjectId);
                toast.success("Successfully copied. Please update header title, deal name etc.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
            }
            else {
                toast.error("Error occurred", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
                setDisabledProjectNewButton(true);
            }
        }
        setLoadingProjectTemplate(false);
    }

    const handleProjectTemplateSelected = (item: IProjectTemplate | undefined, selectedText: string) => {
        setSlectedProjectTemplate(item);
        if (item?.ProjectId && item?.ProjectId > 0) {
            setDisabledProjectNewButton(false);
        } else {
            setDisabledProjectNewButton(true);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = event.target;
        if (name === 'Effort') {
            setCopyEffort(checked);
        }
        else if (name === 'ResourceEstimation') {
            setCopyResourceEstimation(checked);
        }
        else if (name === 'Risk') {
            setCopyRisk(checked);
        }
    };

    return (
        <>{loadingProjectTemplate && <LoadingIcon />}
            <table>
                <tr>
                    <td style={{ paddingTop: '15px', width: '80%' }}>
                        <AutocompleteSearch
                            OnItemSelected={handleProjectTemplateSelected}
                            SelectedInitialItem={slectedProjectTemplate?.DisplayText ?? ''}
                            PlaceHolder='Select existing project.....'
                            APICallback={(searchText) => fetchAllMatchedProjectTemplateData(searchText)}
                            AllowFreeText={true}
                            ShowVaidationError={false}
                            DisplayProperty={(item) => item.DisplayText ?? 'Title'}
                        />
                    </td>
                    <td style={{ width: '20%' }}>
                        <Button style={{ fontSize: '9pt' }} disabled={disabledProjectNewButton} variant="primary" onClick={handleCreateFromTemplate}>
                            Create
                        </Button>
                    </td>
                </tr>
                <tr>
                    <div className="d-flex justify-content-between">
                        <label className="pl-2 fw-bold">
                            <input
                                type="checkbox"
                                name="Risk"
                                disabled={disabledProjectNewButton}
                                checked={copyRisk}
                                onChange={(e) => handleInputChange(e)}
                            />
                            {'Risk'}
                        </label>
                        <label className="fw-bold">
                            <input
                                type="checkbox"
                                name="ResourceEstimation"
                                disabled={disabledProjectNewButton}
                                checked={copyResourceEstimation}
                                onChange={(e) => handleInputChange(e)}
                            />
                            {'Resource Estimation'}
                        </label>
                        <label className="pr-2 fw-bold">
                            <input
                                type="checkbox"
                                name="Effort"
                                disabled={disabledProjectNewButton}
                                checked={copyEffort}
                                onChange={(e) => handleInputChange(e)}
                            />
                            {'Effort'}
                        </label>
                    </div>
                </tr>
            </table >
        </>
    );
}

export default CreateProjectFromTemplate;