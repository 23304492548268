import { useEffect, useState } from 'react'
import { getRandomNumber } from '../../utilities/helper-function'

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

export function useDelayDebounce(delay?: number): number {
  const [debouncedValue, setDebouncedValue] = useState<number>(0)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(getRandomNumber()), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [delay])

  return debouncedValue
}
