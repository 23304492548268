import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form } from 'react-bootstrap';

const ExcelGridPOC: React.FC = () => {
  const rows = 20;
  const cols = 20;
  const textBoxRefs = useRef<Array<HTMLInputElement | null>>([]);
  const textBoxRef = useRef<HTMLInputElement | null>(null);

  const [isCellClicked, setIsCellClicked] = useState<boolean>(false);


  const [selectedCells, setSelectedCells] = useState<Array<number>>([]);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const [isNearBorder, setIsNearBorder] = useState(false);
  const [isNearRightBottomBorder, setIsNearRightBottomBorder] = useState(false);
  // const [clickCount, setClickCount] = useState(0);
  // const [lastClickTime, setLastClickTime] = useState(0);
  // const [cursorStyle, setCursorStyle] = useState<string>('');
  const [clickedCellDetails, setClickedCellDetails] = useState<CellDetails | null | undefined>();

  interface CellDetails {
    index: number | 0;
    clickCount: number | 0;
    lastClickTime: number | 0;
    cursorStyle: string | 'auto';
  }

  // useEffect(() => {
  //   if (clickedCellDetails) {
  //     if (clickedCellDetails?.clickCount >= 2) {
  //       setClickedCellDetails({ ...clickedCellDetails, cursorStyle: 'text' });
  //     } else {
  //       setClickedCellDetails({ ...clickedCellDetails, cursorStyle: 'default' });
  //     }
  //   }
  // }, [clickedCellDetails?.clickCount]);

  const handleCellSelection = (index: number) => {
    if (isMouseDown && !selectedCells.includes(index)) {
      setSelectedCells([...selectedCells, index]);
    }
  };

  const handleMouseDown = (index: number) => {
    setIsMouseDown(true);
    setSelectedCells([index]);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseEnter = (index: number) => {
    handleCellSelection(index);
  };

  const handleOnMouseMove = (e: React.MouseEvent<HTMLInputElement>, index: number) => {
    if (textBoxRefs && textBoxRefs.current[index] != null) {
      let boundary = textBoxRefs.current[index]?.getBoundingClientRect();
      if (boundary && boundary.left && boundary.height && boundary.top && boundary.width) {
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const nearLeftBorder = mouseX > boundary?.left && mouseX < boundary?.left + 10; // Check proximity to the left border
        const nearRightBorder = mouseX < boundary?.left + boundary?.width && mouseX > boundary?.left + boundary?.width - 10; // Check proximity to the right border

        // const nearTopBorder = mouseY > boundary?.top && mouseY < boundary?.top + 10; // Check proximity to the top border
        // const nearBottomBorder = mouseY < boundary?.top + boundary?.height && mouseY > boundary?.top + boundary?.height - 10; // Check proximity to the bottom border
        const isNear = nearLeftBorder || nearRightBorder;
        setIsNearBorder(isNear);
      }
    }
  }

  const handleEffortKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    let targetIndex = index;
    switch (event.key) {
      case 'ArrowRight':
        targetIndex = (index + 1) % (rows * cols);
        break;
      case 'ArrowLeft':
        targetIndex = (index - 1 + rows * cols) % (rows * cols);
        break;
      case 'ArrowDown':
        targetIndex = (index + cols) % (rows * cols);
        break;
      case 'ArrowUp':
        targetIndex = (index - cols + rows * cols) % (rows * cols);
        break;
      default:
        break;
    }

    if (textBoxRefs.current[targetIndex]) {
      textBoxRefs.current[targetIndex]?.focus();
    }
  };

  const getCurrentCursorStyle = (index: number): string => {
    let cursorStyle = 'default';
    if (clickedCellDetails?.index == index) {
      if (clickedCellDetails?.clickCount >= 2) {
        setClickedCellDetails({ ...clickedCellDetails, cursorStyle: 'text' });
      } else if (clickedCellDetails?.clickCount == 1) {
        setClickedCellDetails({ ...clickedCellDetails, cursorStyle: 'move' });
      }
      cursorStyle = clickedCellDetails.cursorStyle;
    }
    return cursorStyle
  }

  const handleOnClick = (e: React.MouseEvent<HTMLInputElement>, index: number) => {
    const now = new Date().getTime();
    if (clickedCellDetails == null || clickedCellDetails == undefined || clickedCellDetails?.index != index) {
      let click = {
        clickCount: 0,
        cursorStyle: '',
        index: index,
        lastClickTime: now
      };
      setClickedCellDetails(click);
    } else {
      if (clickedCellDetails) {
        if ((clickedCellDetails?.lastClickTime ?? 0) - now <= 200) {
          setClickedCellDetails({ ...clickedCellDetails, clickCount:  1 });
        } else {
          //setClickedCellDetails({ ...clickedCellDetails, clickCount: 0 });
        }
        setClickedCellDetails({ ...clickedCellDetails, lastClickTime: now });
      }
    }
  };


  const renderGrid = () => {
    const grid = [];
    for (let i = 0; i < rows; i++) {
      const row = [];
      for (let j = 0; j < cols; j++) {
        const index = i * cols + j;
        row.push(
          // <div
          //   key={`cell-${i}-${j}`}
          //   className="col"
          //   onMouseDown={() => handleMouseDown(index)}
          //   onMouseUp={handleMouseUp}
          //   onMouseEnter={() => handleMouseEnter(index)}
          //   style={{ paddingBottom: '1px' }}
          // >

          // <EfforHoursTextbox
          //   onKeyDown={(e: any) => handleEffortKeyDown(e, index)}
          //   refTest={(el: any) => {
          //     textBoxRefs.current[index] = el;
          //     //textBoxRef.current = el;
          //   }}
          //   onClick={(e) => handleOnClick(e)}
          //   onMouseMove={(e) => handleOnMouseMove(e, index)}
          //   onFocus={() => handleCellSelection(index)}
          //   style={{ cursor: isNearBorder ? "move" : cursorStyle, width: '80px' }}
          // />


          <Form.Control
            type="text"
            //id={id}
            ref={(el: any) => {
              textBoxRefs.current[index] = el;
              //textBoxRef.current = el;
            }}
            name={'N' + index}
            // value={value}
            // isInvalid={isInvalid}
            onKeyDown={(e: any) => handleEffortKeyDown(e, index)}
            onClick={(e: any) => handleOnClick(e, index)}
            onMouseMove={(e: any) => handleOnMouseMove(e, index)}
            onFocus={() => handleCellSelection(index)}
            style={{ cursor: getCurrentCursorStyle(index), width: '80px' }}
          >
          </Form.Control>


          // </div>
        );
      }
      grid.push(
        <div key={`row-${i}`} className="row">
          {row}
        </div>
      );
    }
    return grid;
  };

  return (
    <div style={{ width: '100%' }} className="container">
      <h1>Excel-like Grid</h1>
      {renderGrid()}
      {/* <ExcelGrid /> */}
    </div>
  );
};

export default ExcelGridPOC;
