import { getApiData } from "../../app-services";
import { IAuditTransaction } from "../../data-model";
import { parseAndCastDate } from "../../utilities/helper-function";
import { getBaseFTTAPIURLS } from "./common-svc";

export const getAllProjectAuditTransaction = async (projectId: number, entityTypeId: string): Promise<[number, IAuditTransaction[] | null]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let projectAllSummary: IAuditTransaction[] = [];
    let totalRecords: number = 0;
    try {
        let url = `${baseAPIUrls}/audit/get/${entityTypeId}/${projectId}`;
        var apiResponse = await getApiData(url);

        if (apiResponse?.Success && apiResponse.Result?.length > 0) {
            projectAllSummary = mapProjectsAuditTransactionResponse(apiResponse.Result);
            totalRecords = +apiResponse?.TotalRecord;
        }
    } catch (e) {
        throw 'Unable to connect API server: ' + baseAPIUrls;
    }
    return [totalRecords, projectAllSummary];
}

function mapProjectsAuditTransactionResponse(apiResponse: any): IAuditTransaction[] {
    return apiResponse.map((result: any) => ({
        ...result,
        ExecutionTime: parseAndCastDate(result.ExecutionTime),
    }));
}