import { useEffect, useState } from "react"
import { getPCRRateSummary } from "../_services/pcr-rates-svc";
import { IPCRRoleRate, IPCRRoleRateSummary, IRole } from "../../data-model";
import { Link, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { useDelayDebounce } from "../../common/hooks/debounce";
import ModifyPCRRateModal from "./pcr-rate-modify-modal";
import { formatDateAsString, formatDateTimeAsString } from "../../utilities/helper-function";
import PCRRateHistoryModal from "./pcr-history-modal";
import IPCRRoleRateReq from "../../data-model/pcr-rates-req";

const PCRRatesDetails: React.FC<{}> = () => {
    const delayDebouncedValue = useDelayDebounce(100)
    const [pcrRateSummary, setPCRRateSummary] = useState<IPCRRoleRateSummary>();
    const [loading, setLoading] = useState<boolean>(true);
    const [pcrRoleRate, setPCRRoleRate] = useState<IPCRRoleRate>();
    const [pcrRoleRateReq, setPCRRoleRateReq] = useState<IPCRRoleRateReq>();
    const [isAttachNewRole, setIsAttachNewRole] = useState<boolean>(false);

    const [showPCRRateModifyModal, setShowPCRRateModifyModal] = useState<boolean>(false);
    const [showPCRRateHistoryModal, setShowPCRRateHistoryModal] = useState<boolean>(false);
    const { PCRRateId } = useParams<{ PCRRateId: string }>();
    const [displayAttachRole, setDisplayAttachRole] = useState<boolean>(false);

    let pcrRateYear: number | undefined = undefined;
    if (PCRRateId != undefined) {
        pcrRateYear = parseInt(PCRRateId);
    }

    useEffect(() => {
        if (delayDebouncedValue > 0) {
            fetchAllPCRRates();
        }
    }, [delayDebouncedValue]);

    const fetchAllPCRRates = async () => {
        setLoading(true);
        setDisplayAttachRole(false);
        const data = await getPCRRateSummary(pcrRateYear ?? 0);
        if (data) {
            setPCRRateSummary(data);
            let length = data?.AllPCRRoleRate?.filter(f => f.IsDefault == true)?.length ?? 0;
            setDisplayAttachRole((length > 0));
        }
        setLoading(false);
    };

    const handleModifyPCRRate = (isAttachNewRole: boolean, pcr: IPCRRoleRate | undefined) => {
        if (isAttachNewRole) {
            let pr: IPCRRoleRateReq = {
                Year: pcrRateYear,
                IsActive: true
            }
            setPCRRoleRateReq(pr);

        } else if (pcr) {
            let req: IPCRRoleRateReq = {
                Rate: pcr.Rate,
                PCRRoleRateId: pcr.PCRRoleRateId,
                RoleId: pcr.Role?.RoleId,
                RoleName: pcr.Role?.Name,
                IsActive: pcr.IsActive,
                Year: pcr.Year
            }
            setPCRRoleRateReq(req);
        }
        setShowPCRRateModifyModal(true);
        setIsAttachNewRole(isAttachNewRole);
    }

    const handlePCRRateHistory = (pcr: IPCRRoleRate) => {
        setPCRRoleRate(pcr);
        setShowPCRRateHistoryModal(true);
    }

    const handleAfterModifyPCRRate = (isSuccess: boolean) => {
        setShowPCRRateModifyModal(false);
        if (isSuccess) {
            fetchAllPCRRates();
        }
    }

    return (
        <>
            <div>
                <ModifyPCRRateModal
                    show={showPCRRateModifyModal}
                    year={pcrRateYear ?? new Date().getFullYear()}
                    onClose={(isSuccess) => handleAfterModifyPCRRate(isSuccess)}
                    inputPCR={pcrRoleRateReq}
                    isAttachRole={isAttachNewRole}
                />
                <PCRRateHistoryModal show={showPCRRateHistoryModal} onClose={() => setShowPCRRateHistoryModal(false)} pcrRoleId={pcrRoleRate?.Role.RoleId} />
            </div>
            <div className={loading ? 'card-body table-fade' : 'card-body'}>
                <div className="table-container" style={{ minHeight: '650px', maxHeight: '650px', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #ccc' }}>
                    {loading ? (<LoadingIcon />) : ''}
                    <h2>PCR Rates for Year: {pcrRateSummary?.Year}</h2>
                    <div>
                        <Link to={`/pcr-rates-manage`}>
                            <span>Home PCR Rates</span>
                        </Link>
                        {displayAttachRole && <a style={{ marginLeft: '10px' }} onClick={() => handleModifyPCRRate(true, undefined)}>
                            <i className="fas fa-edit" title="Edit PCR Rate" style={{ fontSize: '15px', color: 'blue' }}></i>
                            Attach New Role
                        </a>
                        }
                    </div>

                    <div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Role</th>
                                    <th>Rate</th>
                                    <th>Default</th>
                                    <th>Active?</th>
                                    <th>Valid From</th>
                                    <th>Valid To</th>
                                    <th>Created By</th>
                                    <th>Create Date</th>
                                    <th>Modified By</th>
                                    <th>Modified Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pcrRateSummary?.AllPCRRoleRate.map((item) => (
                                    <tr key={item.Role.Name} title={item.Role.Description}>
                                        <td>
                                            <Link to={`/role-details/${item.Role.RoleId}`}>
                                                {item.Role.Name}
                                            </Link>
                                        </td>
                                        <td>{item.Rate}</td>
                                        <td>{item.IsDefault ? "Yes" : "No"}</td>
                                        <td>{item.IsActive ? "Yes" : "No"}</td>
                                        <td>{formatDateAsString(new Date(item.DateFrom))}</td>
                                        <td>{formatDateAsString(new Date(item.DateTo))}</td>
                                        <td>{item.CreatedBy}</td>
                                        <td>{formatDateTimeAsString(item.CreateDate)}</td>
                                        <td>{item.ModifiedBy}</td>
                                        <td>{formatDateTimeAsString(item.ModifiedDate)}</td>
                                        <td>
                                            {<a onClick={() => handlePCRRateHistory(item)}>
                                                <i className="fas fa-info-circle pr-2" title="PCR History" style={{ fontSize: '15px', color: 'blue' }}></i>
                                            </a>
                                            }
                                            {item.IsDefault && <a onClick={() => handleModifyPCRRate(false, item)}>
                                                <i className="fas fa-edit" title="Edit PCR Rate" style={{ fontSize: '15px', color: 'blue' }}></i>
                                                Edit
                                            </a>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <Link to={`/pcr-rates-manage`}>
                <span>Home PCR Rates</span>
            </Link>
        </>
    )
};

export default PCRRatesDetails;
