import React, { useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import { IProjectRolesResource } from '../../data-model';
import ProjectEffortDetails from './project-effort-details';

const ProjectEffortManage: React.FC<{ projectId?: number }> = ({ projectId }) => {

  return (
    <div>
      <ProjectEffortDetails projectId={projectId} />
    </div>
  )
}

export default ProjectEffortManage;