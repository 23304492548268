import axios from 'axios';
import { msalInstance } from '../..';

let deleteApiData = async (url: string, params?: any, contentType?: string) => {
    const account = await msalInstance.getAllAccounts()[0];
    const accessTokenRequest = {
        scopes: (window as any).REACT_APP_AZURE_AD_SCOPE?.split(','),
        account: account,
    };
    let accTokenObj = await msalInstance.acquireTokenSilent(accessTokenRequest);
    let accessToken = await accTokenObj.accessToken;
    if (!contentType)
        contentType = "application/json";

    return axios.delete(url, {
        headers: { 'Content-Type': contentType, Authorization: `Bearer ${accessToken}` },
        params: params
    })
        .then(function (response: any) {
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            throw error;
        });
}

export default deleteApiData;