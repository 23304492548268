import React, { useEffect, useState } from 'react';
import './project-dashboard.scss';
import ProjectHeaderManage from '../project-header-manage';
import RoleResourceManage from '../project-role-resource-manage';
import ProjectEffortManage from '../project-effort-estimate-manage';
import { useProjectDataContext } from '../../data-context/project-data-context';
import { useParams } from 'react-router-dom';
import { calculateDiscount, formatToUSCurrency, getRandomNumber } from '../../utilities/helper-function';
import { defaultProjectEffortSummary } from '../_services/initial-default-data';
import ProjectRoleUtilizationReport from '../report/role-utilization';
import ProjectPhaseCostUtilizationReport from '../report/phase-utilization-cost';
import { getAllMasterProjectStatus } from '../_services/master-data-svc';
import ProjectPhaseTimeUtilizationReport from '../report/phase-utilization-time';
import InfoModal from '../../common/confirmation-dialog/information-dialog';
import HelpDocument from './help-doc';
import { IProjectHeaderDetails } from '../../data-model';

const ProjectDashboard: React.FC<{}> = ({ }) => {
    const { setCurrentProjectIdGblCtx, setAllProjectRoleResoursesGblCtx,
        projectEffortSummaryGblCtx, setProjectEffortSummaryGblCtx, setRefreshProjectHeaderGblCtx,
        setCurrentProjectInfoGblCtx, setCurrentProjectParentIdGblCtx, setAllProjectRoleUtilizationGblCtx,
        currentProjectIdGblCtx, currentProjectInfoGblCtx, setAllCurrentPCRRoleRateGblCtx,
        setAllProjectPhaseUtilizationGblCtx, setAllMasterStatusGblCtx, setAllPhaseRoleUtilizationGblCtx,
        setSelectedPhaseGblCtx } = useProjectDataContext();

    const [isEffortOpen, setIsEffortOpen] = useState<boolean>(true);
    const [isDisplayHelpDoc, setIsDisplayHelpDoc] = useState<boolean>(false);
    const [isDisplayRoleResource, setIsDisplayRoleResource] = useState<boolean>(false);
    const [isProjectHeaderOpen, setIsProjectHeaderOpen] = useState<boolean>(true);
    const [offeredColor, setOfferedColor] = useState<string>('');
    const { id } = useParams<{ id: string }>();
    const [projectId, setProjectId] = useState<number | undefined>(undefined);

    let deploymentEnv = (window as any).REACT_APP_DEPLOYMENT_ENVIRONMENT;

    useEffect(() => {
        if (id != undefined && parseInt(id) > 0) {
            setProjectId(parseInt(id));
            setCurrentProjectIdGblCtx(parseInt(id));
        } else {
            setCurrentProjectIdGblCtx(undefined);
            setCurrentProjectInfoGblCtx(undefined);
            setProjectId(undefined);

            setAllProjectRoleUtilizationGblCtx([]);
            setAllPhaseRoleUtilizationGblCtx([]);
            setCurrentProjectParentIdGblCtx(undefined);
            setAllCurrentPCRRoleRateGblCtx([]);
            setAllProjectPhaseUtilizationGblCtx([]);
            setRefreshProjectHeaderGblCtx(getRandomNumber())
            setSelectedPhaseGblCtx([])
        }
    }, [id]);

    useEffect(() => {
        setCurrentProjectIdGblCtx(projectId);
        setProjectEffortSummaryGblCtx(defaultProjectEffortSummary);
        setAllProjectRoleResoursesGblCtx([]);
        setAllProjectRoleUtilizationGblCtx([]);
        setAllPhaseRoleUtilizationGblCtx([]);
        setSelectedPhaseGblCtx([])
        setCurrentProjectParentIdGblCtx(undefined);
        setAllCurrentPCRRoleRateGblCtx([]);
        setAllProjectPhaseUtilizationGblCtx([]);
        setRefreshProjectHeaderGblCtx(getRandomNumber())
        setOfferedColor(getOfferedColor(currentProjectInfoGblCtx))
    }, [projectId]);

    useEffect(() => {
        if (!isProjectHeaderOpen) {
            setOfferedColor(getOfferedColor(currentProjectInfoGblCtx))
        }
    }, [isProjectHeaderOpen]);

    const onCloseHelpDialog = () => {
        setIsDisplayHelpDoc(false);
    };

    const onCloseRoleResourcesDialog = () => {
        setIsDisplayRoleResource(false);
    };

    const getOfferedColor = (pHeaderInfo: IProjectHeaderDetails | undefined): string => {
        let color = 'black';
        if (pHeaderInfo) {
            if (pHeaderInfo?.OfferedPrice && pHeaderInfo?.RiskAdjustedServicesFees && pHeaderInfo?.EstimatedServicesFeesAltRate
                && pHeaderInfo?.OfferedPrice >= pHeaderInfo?.EstimatedServicesFeesAltRate
                && pHeaderInfo?.OfferedPrice >= pHeaderInfo?.RiskAdjustedServicesFees) {
                color = 'green';
            } else if (pHeaderInfo?.OfferedPrice && pHeaderInfo?.RiskAdjustedServicesFees && pHeaderInfo?.EstimatedServicesFeesAltRate
                && pHeaderInfo?.OfferedPrice >= pHeaderInfo?.EstimatedServicesFeesAltRate
                && pHeaderInfo?.OfferedPrice < pHeaderInfo?.RiskAdjustedServicesFees) {
                color = 'orange';
            } else if (pHeaderInfo?.OfferedPrice && pHeaderInfo?.RiskAdjustedServicesFees && pHeaderInfo?.EstimatedServicesFeesAltRate
                && pHeaderInfo?.OfferedPrice < pHeaderInfo?.EstimatedServicesFeesAltRate
                && pHeaderInfo?.OfferedPrice < pHeaderInfo?.RiskAdjustedServicesFees) {
                color = 'red';
            }
        }
        return color;
    }

    return (
        <>
            {<InfoModal
                show={isDisplayHelpDoc}
                title='Help'
                onClose={onCloseHelpDialog}
                htmlBody={<HelpDocument />}
            />}

            {<InfoModal
                show={isDisplayRoleResource}
                title='Manage Role Resources'
                onClose={onCloseRoleResourcesDialog}
                htmlBody={<RoleResourceManage />}
                modalSize='modal-xl'
            />}

            {(currentProjectIdGblCtx && currentProjectIdGblCtx > 0) &&
                <div className="row">

                    <div className="col-xl-1 col-md-1 mb-1">
                        <div className="card border-left-dark shadow h-80 py-1">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Project Cost</div>
                                        <div style={{ fontSize: '9pt', fontWeight: 'bold' }} className="mb-0 font-weight-bold text-gray-800">{formatToUSCurrency(projectEffortSummaryGblCtx?.ProjectCost)}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-1x text-gray-200"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-3 mb-1">
                        <div className="card border-left-info" style={{ minHeight: '100px', maxHeight: '100px' }}>
                            <ProjectPhaseTimeUtilizationReport />
                        </div>
                    </div>

                    <div className="col-xl-1 col-md-1 mb-1">
                        <div className="card border-left-success shadow h-80 py-1">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-0">
                                            Hours</div>
                                        <div className="h7 mb-0 font-weight-bold text-gray-800">{projectEffortSummaryGblCtx?.TotalHours?.toFixed(2)}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-clock fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-3 mb-1">
                        <div className="card border-left-primary" style={{ minHeight: '100px', maxHeight: '100px' }}>
                            <ProjectPhaseCostUtilizationReport />
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-2 mb-1">
                        <div className="card border-left-info" style={{ minHeight: '100px', maxHeight: '100px' }}>
                            <ProjectRoleUtilizationReport
                                title='Role/Time Utilization'
                                displayPropertyColumn2={(item) => item.TimeUtilize}
                                displayPropertyColumn3={(item) => item.TotalTime}
                            />
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-2 mb-1">
                        <div className="card border-left-secondary" style={{ minHeight: '100px', maxHeight: '100px' }}>
                            <ProjectRoleUtilizationReport
                                title='Role/Cost Utilization'
                                displayPropertyColumn2={(item) => item.CostUtilize}
                                displayPropertyColumn3={(item) => item.TotalCost}
                            />
                        </div>
                    </div>
                </div>
            }
            <div className="row mt-1">
                <div className="card shadow mb-0">
                    <div className="card-header py-0 d-flex justify-content-between flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary"></h6>
                        {deploymentEnv != 'PROD' && isProjectHeaderOpen && <div id='mm' className="marquee">
                            <span>This is a '{deploymentEnv}'/Testing Environment (Not Production).</span>
                        </div>
                        }
                        {deploymentEnv != 'PROD' && new Date() <= new Date('09/13/2024') && isProjectHeaderOpen && <div id='mm123' className="marquee_notification">
                            <span>2025 PCR rates have been added to the Tool. Please review plans created before this week to ensure the correct rate is being used.</span>
                        </div>
                        }
                        {!isProjectHeaderOpen && <div className='d-flex justify-content-between'>
                            <div>Offered Price: <strong style={{ color: offeredColor }}>{formatToUSCurrency(currentProjectInfoGblCtx?.OfferedPrice)}</strong></div>
                            <div style={{ marginLeft: '3px' }}> | Estimated Services(ALT) Fees: <strong style={{}}>{formatToUSCurrency(currentProjectInfoGblCtx?.EstimatedServicesFeesAltRate)}</strong></div>
                            <div style={{ marginLeft: '3px' }}>| Risk-Adjusted(ALT) Fees: <strong style={{}}>{formatToUSCurrency(currentProjectInfoGblCtx?.RiskAdjustedServicesFees)}</strong></div>
                            <div style={{ marginLeft: '3px' }}>| Buffer (+) or Requested Discount (-): <strong style={{ color: offeredColor }}>{calculateDiscount(currentProjectInfoGblCtx?.OfferedPrice ?? 0, currentProjectInfoGblCtx?.RiskAdjustedServicesFees ?? 0)?.toFixed(2)}%</strong></div>
                            <div style={{ marginLeft: '3px' }}>| Estimated STD Fees: <strong>{formatToUSCurrency(currentProjectInfoGblCtx?.EstimatedServicesFeesSTDRate)}</strong></div>
                            {projectEffortSummaryGblCtx?.TotalSubContractorFees != undefined && projectEffortSummaryGblCtx?.TotalSubContractorFees > 0 &&
                                <div style={{ marginLeft: '3px' }}>| Total Sub Contractor Cost:
                                    <strong style={{ color: 'brown' }}>
                                        {formatToUSCurrency(projectEffortSummaryGblCtx?.TotalSubContractorFees)}
                                    </strong>
                                </div>
                            }
                        </div>
                        }
                        <a
                            href="#"
                            style={{ width: '20px' }}
                            className="btn btn-link btn-block text-left p-0"
                            onClick={() => setIsProjectHeaderOpen(!isProjectHeaderOpen)}
                            aria-expanded={isProjectHeaderOpen ? 'true' : 'false'}
                        >
                            <i className={`fas ${isProjectHeaderOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </a>
                    </div>
                    <div className={isProjectHeaderOpen ? 'card-body show' : 'card-body collapse'} style={{ height: 'auto' }}>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <ProjectHeaderManage projectId={currentProjectIdGblCtx} />
                            </div>

                            <div className="col-xl-8 col-lg-8 col-md-8">
                                <RoleResourceManage projectId={currentProjectIdGblCtx} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row  mt-1'>
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-0">
                        <div className="card-header py-1 d-flex justify-content-between flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">Level of Effort Estimate</h6>
                            <div>
                                <a
                                    href="#"
                                    style={{ width: '20px' }}
                                    className="btn btn-link btn-block text-left p-0"
                                    onClick={() => setIsDisplayHelpDoc(!isDisplayHelpDoc)}
                                    aria-expanded={isEffortOpen ? 'true' : 'false'}
                                >
                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                </a>
                            </div>
                            {isEffortOpen && !isProjectHeaderOpen && <div>
                                <a
                                    href="#"
                                    style={{ textAlign: 'right' }}
                                    className="btn btn-link btn-block text-left p-0"
                                    onClick={() => setIsDisplayRoleResource(!isDisplayRoleResource)}
                                    aria-expanded={isEffortOpen && !isProjectHeaderOpen ? 'true' : 'false'}
                                >
                                    <i className="fas fa-plus"></i> Manage Role
                                </a>
                            </div>
                            }
                            <a
                                href="#"
                                style={{ width: '20px' }}
                                className="btn btn-link btn-block text-left p-0"
                                onClick={() => setIsEffortOpen(!isEffortOpen)}
                                aria-expanded={isEffortOpen ? 'true' : 'false'}
                            >
                                <i className={`fas ${isEffortOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                            </a>
                        </div>
                        <div className={isEffortOpen ? 'card-body show' : 'card-body collapse'}>
                            <ProjectEffortManage projectId={currentProjectIdGblCtx} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectDashboard;

