import React from 'react';
import { Pagination } from 'react-bootstrap';

interface PaginationProps {
    totalRecords: number;
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

const GridPagination: React.FC<PaginationProps> = ({ totalRecords, currentPage, totalPages, onPageChange }) => {

    const pagesToShow = [];
    const numAdjacentPages = 1;

    for (let i = currentPage - numAdjacentPages; i <= currentPage + numAdjacentPages; i++) {
        if (i > 0 && i <= totalPages) {
            pagesToShow.push(i);
        }
    }

    return (
        <>
            <div style={{ width: '60%' }} className='d-flex justify-content-between'>
                <div>
                    <Pagination>
                        <Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1} />
                        <Pagination.Prev
                            onClick={() => onPageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        />

                        {/* {Array.from({ length: totalPages }, (_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => onPageChange(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))} */}
                        {pagesToShow.map((page, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={page === currentPage}
                                onClick={() => onPageChange(page)}
                            >
                                {page}
                            </Pagination.Item>
                        ))}

                        <Pagination.Next
                            onClick={() => onPageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        />
                        <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />
                    </Pagination>
                </div>
                <div className='d-flex justify-content-between'>
                    <p style={{ fontWeight: 'bold' }}>Page</p>  : {currentPage} <p style={{ fontWeight: 'bold', marginLeft: '5px', marginRight: '5px' }}> of </p>  {totalPages}
                    <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>, Total Record(s)</p>  : {totalRecords}
                </div>
            </div>
        </>
    );
};

export default GridPagination;
