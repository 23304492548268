import { deleteApiData, getApiData, postApiData, putApiData } from "../../app-services";
import { IRisk } from "../../data-model";
import { getBaseFTTAPIURLS } from "./common-svc";

export const getAllDefaultMasterRisk = async (): Promise<IRisk[] | null> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/risk/get/masterdata/all`;
    var apiResponse = await getApiData(url);

    let allPCRRoleRates: IRisk[] = [];
    if (apiResponse?.Success && apiResponse.Result?.length > 0) {
        allPCRRoleRates = apiResponse.Result.map((result: any) => ({ ...result }));
    }
    return allPCRRoleRates;
}

export const getProjectAllRisk = async (projectId: number): Promise<IRisk[] | null> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/risk/project/get/${projectId}`;
    var apiResponse = await getApiData(url);

    let allPCRRoleRates: IRisk[] = [];
    if (apiResponse?.Success && apiResponse.Result?.length > 0) {
        allPCRRoleRates = apiResponse.Result.map((result: any) => ({ ...result }));
    }
    return allPCRRoleRates;
}

export const updateRiskEstimation = async (projectId: number, risk: IRisk[]): Promise<[boolean, string]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/risk/estimation/update/${projectId}`;
    var apiResponse = await postApiData(url, risk);
    return [apiResponse?.Success, apiResponse?.Result];
}