import { useEffect, useState } from "react"
import { getAllRoles, getPCRRateSummary, saveNewRole, updateRole, deleteRole } from "../_services/pcr-rates-svc";
import { IRole } from "../../data-model";
import { Link, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import ConfirmationModal from "../../common/confirmation-dialog";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import RoleCreate from "./role-create";
import { toast } from "react-toastify";
import { useDebounce, useDelayDebounce } from "../../common/hooks/debounce";
import { initialDefaultRole } from "../_services/initial-default-data";

const RoleSummary: React.FC<{}> = () => {
    const [showModal, setShowModal] = useState(false);
    const [allRoles, setAllRoles] = useState<IRole[]>();
    const [loading, setLoading] = useState(true);

    const [currentRole, setCurrentRole] = useState<IRole | null>();
    const [recordToDeleteMessage, setRecordToDeleteMessage] = useState<string>('');
    const [showRoleDeleteModal, setShowRoleDeleteModal] = useState(false);
    const delayDebouncedValue = useDelayDebounce(100);

    useEffect(() => {
        if (delayDebouncedValue) {
            fetchAllRoles();
        }
    }, [delayDebouncedValue]);

    const fetchAllRoles = async () => {
        setLoading(true);
        const data = await getAllRoles();
        if (data) {
            setAllRoles(data);
        } else {
            setAllRoles([]);
        }
        setLoading(false);
    };

    const toggleModal = () => {
        setCurrentRole(null);
        setShowModal(!showModal);
    };

    const editRole = (role: IRole) => {
        setShowModal(!showModal);

        setCurrentRole({
            ...role
        });
    };

    const saveRole = async (role: IRole) => {
        let [isSuccess, message] = [false, ''];
        if (role.RoleId > 0) {
            [isSuccess, message] = await updateRole(role);
        }
        else {
            [isSuccess, message] = await saveNewRole(role);
        }

        if (isSuccess) {
            toast.success("Saved New Role Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
            await fetchAllRoles();
        }
        else {
            toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setLoading(false);
        return [isSuccess, message]
    };

    const handleDeleteRole = (role: IRole) => {
        setShowRoleDeleteModal(true);
        setCurrentRole(role);
        setRecordToDeleteMessage(`Are you sure to delete role: '${role.Name}'`);
    };

    const confirmDelete = async () => {
        if (currentRole != undefined && currentRole.RoleId > 0) {
            let [isSuccess, message] = await deleteRole(currentRole?.RoleId);

            if (isSuccess) {
                toast.success("Role Deleted Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                await fetchAllRoles();
            }
            else {
                toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
        }
        setCurrentRole(null);
        setShowRoleDeleteModal(false);
    };

    const cancelDelete = () => {
        setCurrentRole(null);
        setShowRoleDeleteModal(false);
    };

    return (
        <div>
            <div>
                <ConfirmationModal
                    show={showRoleDeleteModal}
                    title="Confirm Deletion of Role"
                    message={recordToDeleteMessage}
                    onClose={() => setShowRoleDeleteModal(false)}
                    onCancel={cancelDelete}
                    onConfirm={confirmDelete}
                />
            </div>
            <div>
                {showModal && (
                    <RoleCreate show={showModal} onClose={toggleModal} onSave={saveRole} initialRole={currentRole ?? initialDefaultRole} />
                )}
            </div>
            <div className={loading ? 'card-body table-fade' : 'card-body'}>
                <div className="table-container" style={{ paddingRight: '20px', minHeight: '650px', maxHeight: '650px', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #ccc' }}>
                    {loading ? (<LoadingIcon />) : ''}
                    <h2>All Role</h2>
                    <div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Role</th>
                                    <th>Code</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allRoles?.map((role) => (
                                    <tr key={role.Name}>
                                        <td style={{ width: '200px' }}>
                                            <Link to={`/role-details/${role.RoleId}`}>
                                                {role.Name}
                                            </Link>
                                        </td>
                                        <td>{role.Code}</td>
                                        <td>{role.Description}</td>
                                        <td>{role.IsActive ? "Active" : "In-Active"}</td>
                                        <td style={{ width: '55px' }}>
                                            <a onClick={() => editRole(role)} className="">
                                                <i className="fas fa-edit"></i>
                                            </a>
                                            <a style={{ marginLeft: '5px', color: 'red' }} onClick={() => handleDeleteRole(role)} className="">
                                                <i className="fas fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center', paddingTop: '10px', cursor: 'pointer' }}>
                {<a onClick={() => setShowModal(true)} className="w-100">
                    <i className="fas fa-plus"></i> Add New Role
                </a>
                }
            </div>
        </div>
    )
};

export default RoleSummary;
