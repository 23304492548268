// ConfirmModal.tsx
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ConfirmModalProps {
  show: boolean;
  title: string;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  onClose?: () => void;
  showCloseButton?: boolean | false;
  cancelText?: string | null;
  confirmText?: string | null;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  show,
  title,
  message,
  onCancel,
  onConfirm,
  onClose,
  showCloseButton,
  cancelText,
  confirmText
}) => {
  return (
    <Modal show={show} onHide={() => onClose && onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {showCloseButton && <Button variant="secondary" onClick={() => onClose && onClose()}>
          <i className="fas fa-times"></i> Close
        </Button>}
        <Button variant="secondary" onClick={onCancel}>
          <i className="fas fa-times"></i> {cancelText ?? 'Cancel'}
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          <i className="fas fa-check"></i> {confirmText ?? 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
