import React, { useEffect, useState } from 'react';
import { Table, Form, Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IAPIRequestFilter, IRisk } from '../../data-model';
import { useProjectDataContext } from '../../data-context/project-data-context';
import { getAllDefaultMasterRisk, getProjectAllRisk, updateRiskEstimation } from '../_services/project-rist-estimation-svc';
import LoadingIcon from '../../common/loading-icon/loading-icon';
import { getProjectHeader, patchProjectData } from '../_services/dashboard-svc';
import { areObjectsEqual, getRandomNumber, isProjectReadOnlyStatus, isUserRoleReadOnly } from '../../utilities/helper-function';
import { Console } from 'console';

const RiskEstimationModal: React.FC<{
    show: boolean;
    onClose: (totalRisk?: number, saveSuccess?: boolean) => void;
    projectId: number;
}> = ({ show, onClose, projectId }) => {

    const { allDefaultRiskGblCtx, setAllDefaultRiskGblCtx, currentProjectInfoGblCtx, currentProjectIdGblCtx,
        setCurrentProjectInfoGblCtx, setRefreshProjectHeaderGblCtx, refreshProjectStatusGblCtx, userAccessInfoGblCtx } = useProjectDataContext();

    const [allUpdatedProjectRisk, setAllUpdatedProjectRisk] = useState<IRisk[]>();
    const [allInitialRisk, setAllInitialRisk] = useState<IRisk[]>();
    const [saveRiskButtonDisabled, setSaveRiskButtonDisabled] = useState<boolean>(false);
    const [loadingRisk, setLoadingRisk] = useState<boolean>(false);
    const [totalRiskWeight, setTotalRiskWeight] = useState<number | undefined>();
    const [actionText, setActionText] = useState<string[]>(["Add", "Save"]);
    const [tempFixForRisk, setTempFixForRisk] = useState<number>();
    const [hideAddRiskSaveLink, setHideAddRiskSaveLink] = useState<boolean>(false);

    useEffect(() => {
        if (projectId > 0) {
            fetchAllMasterDefaultRisk();
        }
    }, [projectId, show]);

    useEffect(() => {
        calculateProjectRiskEstimation();
    }, [allUpdatedProjectRisk]);

    useEffect(() => {
        fetchProjectRiskEstimation(projectId);
    }, [allDefaultRiskGblCtx]);

    useEffect(() => {
        setAllInitialRisk(allUpdatedProjectRisk);
    }, [tempFixForRisk]);

    useEffect(() => {
        setSaveRiskButtonDisabled(areObjectsEqual(allInitialRisk ?? [], allUpdatedProjectRisk ?? []))
    }, [allUpdatedProjectRisk]);

    useEffect(() => {
        if (refreshProjectStatusGblCtx && refreshProjectStatusGblCtx > 0) {
            setHideAddRiskSaveLink(isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) || isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles));
        }
    }, [refreshProjectStatusGblCtx]);

    const calculateProjectRiskEstimation = () => {
        let totalWeight: number = 0;
        const lastFourRecords = allUpdatedProjectRisk?.slice(-5);
        const totalRisk2to6 = lastFourRecords?.reduce((sum, risk) => sum + (risk.SelectedOption?.Weight || 0), 0);

        if (totalRisk2to6 && allUpdatedProjectRisk && (allUpdatedProjectRisk[0].SelectedOption?.Weight || 0) > 0) {
            totalWeight = (allUpdatedProjectRisk[0].SelectedOption?.Weight || 0) * totalRisk2to6;
        }

        if (!isNaN(totalWeight ?? 0)) {
            setTotalRiskWeight(parseFloat(totalWeight ? totalWeight.toFixed(3) : '0'));
        }
    }

    const fetchAllMasterDefaultRisk = async () => {
        setLoadingRisk(true);
        if (!(allDefaultRiskGblCtx.length > 0)) {
            const data = await getAllDefaultMasterRisk();
            if (data) {
                setAllDefaultRiskGblCtx(data);
                updateDefaultRiskByProjectRisk(data);
            }
            else {
                setAllDefaultRiskGblCtx([]);
            }
        } else {
            updateDefaultRiskByProjectRisk(allDefaultRiskGblCtx);
        }
        setLoadingRisk(false);
    };

    const updateDefaultRiskByProjectRisk = (risks: IRisk[]) => {

        setAllUpdatedProjectRisk(risks.map((risk) => ({
            ...risk,
            ParentRiskId: risk.RiskId,
            IsDefaultRisk: false,
            RiskId: 0,
            ProjectId: projectId
        })))

        setTempFixForRisk(12);
    }

    const fetchProjectRiskEstimation = async (projectId: number) => {
        setLoadingRisk(true);
        const data = await getProjectAllRisk(projectId);
        if (data && data.length > 0) {
            setAllUpdatedProjectRisk(prevRisk =>
                prevRisk?.map(eachRisk => {
                    let sr = data.filter(p => p.ParentRiskId === eachRisk.ParentRiskId)[0];
                    if (sr) {
                        eachRisk.RiskId = sr.RiskId;
                        eachRisk.SelectedOption = sr.SelectedOption;
                        eachRisk.SelectedOptionId = sr.SelectedOptionId;
                    }
                    return eachRisk;
                })
            );
            setActionText(["Edit", "Update"]);
            setTempFixForRisk(15);
        }

        setLoadingRisk(false);
    };

    const handleSelectChange = (event: any, selectedRisk: IRisk) => {
        const { name, value } = event.target;
        setAllUpdatedProjectRisk(prevRisk =>
            prevRisk?.map(eachRisk => {
                return eachRisk.ParentRiskId == selectedRisk.ParentRiskId
                    ? { ...eachRisk, SelectedOptionId: value, SelectedOption: selectedRisk.Options.filter(f => f.OptionId == value)[0] }
                    : eachRisk
            })
        );
    }

    const handleRiskSave = async () => {
        if (Object.keys([]).length === 0) {
            toast.info('Saving risk...', { toastId: 'info1', autoClose: 1000, position: toast.POSITION.TOP_CENTER });
            try {
                if (currentProjectIdGblCtx && currentProjectIdGblCtx > 0) {
                    let [isSuccess, message] = await updateRiskEstimation(currentProjectIdGblCtx, allUpdatedProjectRisk ?? []);
                    if (isSuccess) {
                        let patchData: IAPIRequestFilter[] = [{
                            Field: "RiskMultiple",
                            Operator: '',
                            Value: totalRiskWeight ? totalRiskWeight.toString() : '0',
                            Text: ''
                        }];

                        [isSuccess, message] = await patchProjectData(projectId, patchData)

                        if (isSuccess) {
                            let projectHeaderData = await getProjectHeader(projectId);
                            if (projectHeaderData) {
                                setCurrentProjectInfoGblCtx(projectHeaderData);
                                setRefreshProjectHeaderGblCtx(getRandomNumber());
                            }
                            toast.success("Saved Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                            onClose(totalRiskWeight, true);
                        }
                    }
                    if (!isSuccess) {
                        return toast.error("Error: " + message, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
                    }
                }
            } catch (error) {
                console.error('API call failed:', error);
                // Handle API error here
                return toast.error("Error occurred", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
        } else {
            // Validation errors exist, update the state with error messages
            //setValidationErrors(errors);
        }
    };

    const resetRiskForm = () => {
        setAllUpdatedProjectRisk(allInitialRisk);
        calculateProjectRiskEstimation();
    };

    const handleRiskModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
        <div>
            <Modal onClick={handleRiskModalClick} show={show} onHide={() => onClose(totalRiskWeight)} onClose={onClose} centered dialogClassName="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title>{actionText[0]} Risk Estimation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {loadingRisk ? (<LoadingIcon />) :
                            (
                                allUpdatedProjectRisk && allUpdatedProjectRisk.length > 0 && allUpdatedProjectRisk.map((risk, rowIndex) => {
                                    return (<div key={rowIndex} style={{ marginBottom: '10px' }}>
                                        <p style={{ fontWeight: 'bold' }}>{risk.Question}</p>
                                        <select key={risk.RiskId} id="feeOptions" disabled={hideAddRiskSaveLink} style={{ height: '40px' }} value={risk.SelectedOptionId ?? -1} onChange={(e) => handleSelectChange(e, risk)}>
                                            <option key={-1} value="-1">Select your choice..</option>
                                            {
                                                risk.Options?.length > 0 && risk.Options.map((option, rowIndex) => {
                                                    return (
                                                        <option key={option.OptionId} value={option.OptionId}>{option.Option}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    )
                                })
                            )
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between" style={{ fontSize: '18pt', fontWeight: 'bold', flex: 1, textAlign: 'left' }}>
                        <p style={{ textAlign: 'left' }}>Total Risk: {totalRiskWeight ? totalRiskWeight.toFixed(3) : '0'}  Or  {((totalRiskWeight ?? 0) * 100).toFixed(2)}%</p>
                    </div>
                    {!hideAddRiskSaveLink && <div style={{ flex: 1, textAlign: 'right' }}>
                        <Button style={{ marginTop: '15px' }} variant="secondary" onClick={resetRiskForm}>
                            <i className="fas fa-undo"></i> Reset
                        </Button>
                        <Button style={{ marginLeft: '10px', marginTop: '15px' }} variant="secondary" onClick={() => onClose(totalRiskWeight)}>
                            <i className="fas fa-times"></i> Close
                        </Button>
                        <Button style={{ marginLeft: '10px', marginRight: '10px' }} disabled={saveRiskButtonDisabled} variant="primary" onClick={handleRiskSave}>
                            <i className="fas fa-save"></i> {actionText[1]}
                        </Button>
                    </div>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default RiskEstimationModal;