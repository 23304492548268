import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import './project-title-manage.scss'
import { useProjectDataContext } from '../../data-context/project-data-context';
import { isProjectReadOnlyStatus, isUserRoleReadOnly } from '../../utilities/helper-function';

interface TitleEditProps {
  projectId?: number;
  title: string;
  onTitleChange: (newTitle: string) => void;
}

const ProjectTitleManage: React.FC<TitleEditProps> = ({ projectId, title, onTitleChange }) => {

  const { currentProjectIdGblCtx, currentProjectInfoGblCtx, refreshProjectStatusGblCtx, userAccessInfoGblCtx } = useProjectDataContext();

  const [isEditingOn, setIsEditingOn] = useState<boolean>(false);
  const [isEditingClicked, setIsEditingClicked] = useState<boolean>(false);
  const [projectTitle, setProjectTitle] = useState<string>(title);
  const [validateTitle, setValidateTitle] = useState<string>();
  const [hideEditProjectTitleLink, setHideEditProjectTitleLink] = useState<boolean>(false);

  useEffect(() => {
    setProjectTitle(title);
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
    if (projId && projId > 0) {
      setIsEditingOn(true);
    } else {
      setIsEditingOn(false);
    }
  }, [projectId, currentProjectIdGblCtx, title]);

  useEffect(() => {
    if (refreshProjectStatusGblCtx && refreshProjectStatusGblCtx > 0) {
      // if (currentProjectInfoGblCtx?.Status == "Closed") {
      //   setHideEditProjectTitleLink(true);
      // } else {
      setHideEditProjectTitleLink(isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) || isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles));
      //}
    }
  }, [refreshProjectStatusGblCtx]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setProjectTitle(value);
  }

  const handleEditClick = () => {
    setIsEditingClicked(true)
    setProjectTitle(title);
  };

  const handleSaveClick = () => {
    let isValid = validateFormData();
    if (isValid) {
      if (projectTitle !== title) {
        onTitleChange(projectTitle);
      }
      setIsEditingClicked(false);
    }
  };

  const validateFormData = (): boolean => {
    let isValid = false;
    if (!projectTitle) {
      setValidateTitle('Title is required');
    } else {
      isValid = true;
      setValidateTitle('');
    }
    return isValid;
  }

  return (
    <>
      <div className="row" style={{ width: '100%' }}>
        <div style={{ width: '13%' }}>
          <label style={{ fontWeight: 'bold', marginTop: '18px', }} htmlFor="version">Title:</label>
        </div>
        <div style={{ width: '80%' }}>
          {!hideEditProjectTitleLink && isEditingOn && isEditingClicked ? (
            <Form.Control
              type="text"
              isInvalid={!!validateTitle}
              value={projectTitle}
              onChange={handleInputChange}
              style={{ width: '100%' }}
            />
          ) :
            (
              <h2 className="titleStyle" style={{ overflowY: projectTitle.length > 80 ? 'scroll' : 'hidden', height: projectTitle.length > 80 ? '50px' : '99%' }}>{projectTitle}</h2>
            )
          }
          {
            validateTitle && <p style={{ color: 'red' }} className="error-message">{validateTitle}*</p>
          }
        </div>
        {!hideEditProjectTitleLink &&
          <div style={{ width: '7%', marginTop: '18px' }}>
            {isEditingOn && isEditingClicked ? (
              <a className="" onClick={handleSaveClick}>
                <i className="fas fa-save"></i>
              </a>
            ) : isEditingOn && !isEditingClicked ? (
              <a className="" onClick={handleEditClick}>
                {<i className="fas fa-edit"></i>}
              </a>
            ) : ('')
            }
          </div>
        }
      </div>
    </>
  );
};

export default ProjectTitleManage;
