import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import './project-header-details.scss'
import { IProjectHeaderDetails } from '../../data-model';
import { calculateBufferDiscount, calculateDiscount, formatDateAsString, formatToUSCurrency, parseDateAsStringFormat } from '../../utilities/helper-function';
import RiskEstimationModal from '../risk-estimation';
import { useProjectDataContext } from '../../data-context/project-data-context';
import { toast } from 'react-toastify';
import { useDelayDebounce } from '../../common/hooks/debounce';
import { defaultProjectHeaderDetails } from '../_services/initial-default-data';

const ProjectHeaderDetails: React.FC<{ projectHeaderDetails: IProjectHeaderDetails | undefined }> = ({ projectHeaderDetails }) => {
  const { currentProjectIdGblCtx, currentProjectInfoGblCtx, refreshProjectHeaderGblCtx,
    projectEffortSummaryGblCtx } = useProjectDataContext();

  const [editRiskDisabled, setEditRiskDisabled] = useState<boolean>(true);
  const [currentProjectHeaderDetails, setCurrentProjectHeaderDetails] = useState<IProjectHeaderDetails | undefined>(projectHeaderDetails);
  const [showRiskEstimationModal, setShowRiskEstimationModal] = useState<boolean>(false);
  const [totalRisk, setTotalRisk] = useState<number | null>();
  const [offeredColor, setOfferedColor] = useState<string>('');

  useEffect(() => {
    if (projectHeaderDetails && projectHeaderDetails.ProjectId != undefined && projectHeaderDetails.ProjectId > 0) {
      setTotalRisk(projectHeaderDetails?.RiskMultiple);
      setCurrentProjectHeaderDetails(projectHeaderDetails);
      setEditRiskDisabled(false);
      setOfferedColor(getOfferedColor(projectHeaderDetails));
    } else {
      setCurrentProjectHeaderDetails(undefined);
      setEditRiskDisabled(true);
      setTotalRisk(null);
    }
  }, [projectHeaderDetails]);

  useEffect(() => {
    let projId = currentProjectInfoGblCtx?.ProjectId != undefined && currentProjectInfoGblCtx.ProjectId > 0 ? currentProjectInfoGblCtx.ProjectId : currentProjectIdGblCtx;
    if (projId != undefined && projId > 0 && currentProjectInfoGblCtx) {
      setCurrentProjectHeaderDetails(currentProjectInfoGblCtx);
      setEditRiskDisabled(false);
    } else {
      setTotalRisk(null);
      setEditRiskDisabled(true);
      setCurrentProjectHeaderDetails(defaultProjectHeaderDetails);
    }
  }, [refreshProjectHeaderGblCtx]);

  const displayRiskEstimationModal = (e: any) => {
    if (!editRiskDisabled) {
      setShowRiskEstimationModal(!showRiskEstimationModal);
    }
  };

  const toggleRiskModal = (totalRisk?: number, saveSuccess?: boolean) => {
    if (saveSuccess) {
      setTotalRisk(totalRisk);
    }
    let projId = projectHeaderDetails?.ProjectId != undefined && projectHeaderDetails.ProjectId > 0 ? projectHeaderDetails.ProjectId : currentProjectIdGblCtx;
    if (projId != undefined && projId > 0) {
      setEditRiskDisabled(false);
      setShowRiskEstimationModal(!showRiskEstimationModal);
    }
    else {
      toast.error("Save project header before add role and resource", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
    }
  };

  const getOfferedColor = (pHeaderInfo: IProjectHeaderDetails): string => {
    let color = 'black';
    if (pHeaderInfo?.OfferedPrice && pHeaderInfo?.RiskAdjustedServicesFees && pHeaderInfo?.EstimatedServicesFeesAltRate
      && pHeaderInfo?.OfferedPrice >= pHeaderInfo?.EstimatedServicesFeesAltRate
      && pHeaderInfo?.OfferedPrice >= pHeaderInfo?.RiskAdjustedServicesFees) {
      color = 'green';
    } else if (pHeaderInfo?.OfferedPrice && pHeaderInfo?.RiskAdjustedServicesFees && pHeaderInfo?.EstimatedServicesFeesAltRate
      && pHeaderInfo?.OfferedPrice >= pHeaderInfo?.EstimatedServicesFeesAltRate
      && pHeaderInfo?.OfferedPrice < pHeaderInfo?.RiskAdjustedServicesFees) {
      color = 'orange';
    } else if (pHeaderInfo?.OfferedPrice && pHeaderInfo?.RiskAdjustedServicesFees && pHeaderInfo?.EstimatedServicesFeesAltRate
      && pHeaderInfo?.OfferedPrice < pHeaderInfo?.EstimatedServicesFeesAltRate
      && pHeaderInfo?.OfferedPrice < pHeaderInfo?.RiskAdjustedServicesFees) {
      color = 'red';
    }
    return color;
  }

  return (
    <div>
      <div>
        {showRiskEstimationModal && (
          <RiskEstimationModal show={showRiskEstimationModal} onClose={(totalRisk, saveSuccess) => toggleRiskModal(totalRisk, saveSuccess)} projectId={currentProjectIdGblCtx ?? 0} />
        )}
      </div>

      <div className="project-details-container" style={{ minHeight: '410px', maxHeight: '410px', overflowY: 'scroll', overflowX: 'hidden' }}>
        <table style={{ fontSize: '12px' }}>
          <tbody>
            <tr>
              <th style={{ width: '50%' }}>Preparer</th>
              <td>{currentProjectHeaderDetails?.Preparer}</td>
            </tr>
            <tr>
              <th>Date Prepared</th>
              <td>{formatDateAsString(currentProjectHeaderDetails?.DatePrepared)}</td>
            </tr>
            <tr>
              <th>Executive Sponsor</th>
              <td>{currentProjectHeaderDetails?.ExecutiveSponsor}</td>
            </tr>
            <tr>
              <th>Engagement Manager</th>
              <td>{currentProjectHeaderDetails?.EngagementManager}</td>
            </tr>
            <tr>
              <th>Client Name</th>
              <td>{currentProjectHeaderDetails?.ClientName}</td>
            </tr>
            <tr>
              <th>Title</th>
              <td>{currentProjectHeaderDetails?.Title}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td><span style={{ color: currentProjectHeaderDetails?.StatusId == 50 ? 'red' : (currentProjectHeaderDetails?.StatusId == 299 || currentProjectHeaderDetails?.StatusId == 300) ? 'green' : '' }}> {currentProjectHeaderDetails?.Status}</span> {currentProjectHeaderDetails?.StatusId == 299 && <span style={{ color: 'red', fontWeight: 'bold' }}>(By Tier 1. Waiting for final approval).</span>}</td>
            </tr>
            <tr>
              <th>Hubspot Deal</th>
              <td>{currentProjectHeaderDetails?.HubspotDealId && currentProjectHeaderDetails?.HubspotDealId > 0 ? `${currentProjectHeaderDetails?.HubspotDealId} - ` : ''}{currentProjectHeaderDetails?.HubspotDealName}</td>
            </tr>
            <tr>
              <th>Start Date</th>
              <td>{formatDateAsString(currentProjectHeaderDetails?.StartDate)}</td>
            </tr>
            <tr>
              <th>Offered Price</th>
              <td style={{ fontSize: '11pt', fontWeight: 'bold', color: offeredColor }}>{formatToUSCurrency(currentProjectHeaderDetails?.OfferedPrice ?? null)}
                {projectEffortSummaryGblCtx.TotalSubContractorFees && (
                  <div className='d-flex'>
                    <strong style={{ color: 'grey' }}> Sub Contractor Cost:</strong>
                    <p style={{ color: 'brown' }}>{formatToUSCurrency(projectEffortSummaryGblCtx.TotalSubContractorFees)}</p>
                  </div>)
                }
              </td>
            </tr>
            <tr>
              <th>Proposed Fee Structure</th>
              <td>{currentProjectHeaderDetails?.ProposedFeeStructure}</td>
            </tr>
            <tr>
              <th>Estimated Services Fees (Alt Rate)</th>
              <td style={{ fontSize: '11pt' }}><strong style={{}}>{formatToUSCurrency(currentProjectHeaderDetails?.EstimatedServicesFeesAltRate ?? null)}  </strong>|<strong> Discount:</strong>  {`${calculateDiscount(currentProjectHeaderDetails?.EstimatedServicesFeesSTDRate ?? 0, currentProjectHeaderDetails?.EstimatedServicesFeesAltRate ?? 0)}%`}</td>
            </tr>
            <tr>
              <th>Estimated Services Fees (STD Rate)</th>
              <td>{formatToUSCurrency(currentProjectHeaderDetails?.EstimatedServicesFeesSTDRate ?? null)}</td>
            </tr>
            <tr>
              <th className="d-flex justify-content-between">Risk Multiple
                <a onClick={(e) => displayRiskEstimationModal(e)} className={`w-10 ${editRiskDisabled ? 'disabled-link' : ''}`} style={{ textDecoration: 'none', paddingLeft: '20px', paddingBottom: '5px', color: editRiskDisabled ? 'gray' : '', cursor: editRiskDisabled ? 'not-allowed' : 'pointer' }}>
                  {totalRisk == null ? (<i className="fas fa-plus text-blue-700 align-items-right">Add Risk</i>) : (<i className="fas fa-edit text-gray-700 align-items-right"></i>)}
                </a>
              </th>
              <td>{((totalRisk ?? 0) * 100).toFixed(2)}%</td>
            </tr>
            <tr>
              <th>Risk-Adjusted Services Fees</th>
              <td style={{ fontSize: '11pt', fontWeight: 'bold' }}>{formatToUSCurrency(currentProjectHeaderDetails?.RiskAdjustedServicesFees ?? null)}</td>
            </tr>
            {/* <tr>
              <th>Risk-Adjusted Services Fees (Std Rate)</th>
              <td>{formatToUSCurrency(currentProjectHeaderDetails?.RiskAdjustedServicesFeesStdRate ?? null)}</td>
            </tr> */}
            <tr>
              <th>Buffer (+) or Requested Discount (-)(%)</th>
              <td>{calculateBufferDiscount((currentProjectHeaderDetails?.OfferedPrice ?? 0), (currentProjectHeaderDetails?.RiskAdjustedServicesFees ?? 0))?.toFixed(2)}%</td>
            </tr>
            <tr>
              <th>Net Offered Blended Rate</th>
              <td>{projectEffortSummaryGblCtx.TotalHours > 0 ? formatToUSCurrency((currentProjectInfoGblCtx?.OfferedPrice ?? 0) / projectEffortSummaryGblCtx.TotalHours) : ''}</td>
            </tr>
            <tr>
              <th>Maximum WIP Accumulation Dollar ($) </th>
              <td>{formatToUSCurrency(currentProjectHeaderDetails?.MaximumWIPAccumulationDollar ?? null)}</td>
            </tr>
            <tr>
              <th>Maximum WIP Accumulation Percent (%) </th>
              <td>{calculateDiscount(currentProjectHeaderDetails?.MaximumWIPAccumulationDollar ?? 0, currentProjectHeaderDetails?.OfferedPrice ?? 0)}%</td>
            </tr>
            <tr>
              <th>End Date</th>
              <td>{formatDateAsString(currentProjectHeaderDetails?.EndDate)}</td>
            </tr>
            <tr>
              <th>Version </th>
              <td>{currentProjectHeaderDetails?.ParentProjectId && currentProjectHeaderDetails?.ParentProjectId > 0 ? `${currentProjectHeaderDetails?.VersionName}` : 'Main Version'}</td>
            </tr>
            <tr>
              <th>Default Plan? </th>
              <td>{currentProjectHeaderDetails?.IsDefaultPlan == true ? 'Yes' : 'No'}</td>
            </tr>
            {(currentProjectHeaderDetails?.ParentProjectId && currentProjectHeaderDetails.ParentProjectId > 0) &&
              <tr>
                <th>Version User Comment</th>
                <td>{currentProjectHeaderDetails?.VersionUserComment}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectHeaderDetails;
