import { useState } from "react";
import { IProjectRoleUtilization } from "../../data-model";
import { useProjectDataContext } from "../../data-context/project-data-context";
import { PhaseEffort } from "../../common/class/phase-effort";
import { Packer } from "docx";
import saveAs from "file-saver";

const ProjectRoleUtilizationReport: React.FC<{
    title: string;
    displayPropertyColumn2: (item: IProjectRoleUtilization) => number;
    displayPropertyColumn3: (item: IProjectRoleUtilization) => number;

}> = ({ title, displayPropertyColumn2, displayPropertyColumn3 }) => {
    const { allPhaseRoleUtilizationGblCtx, selectedPhaseGblCtx, currentProjectInfoGblCtx } = useProjectDataContext();

    var phaseEffort = new PhaseEffort();

    let sortedRoleUtilization = allPhaseRoleUtilizationGblCtx;
    if (selectedPhaseGblCtx?.length > 0) {
        sortedRoleUtilization = sortedRoleUtilization.filter(f => f.TimeUtilize > 0).sort(f => f.CostUtilize)
    } else {
        sortedRoleUtilization = allPhaseRoleUtilizationGblCtx;
    }

    const handleDownloadUtilization = async () => {
        const doc = await phaseEffort.createRoleUtilizationDocument(sortedRoleUtilization);
        Packer.toBlob(doc).then(blob => {
            saveAs(blob, `utilization_${currentProjectInfoGblCtx?.Title}.docx`);
        });
    };

    return (
        <>
            <div style={{ overflowY: 'scroll' }}>
                <table>
                    <thead>
                        <tr key={'role'} style={{ height: '10px', color: 'white', backgroundColor: '#1DB1AF', textAlign: 'center', fontWeight: 'bold' }}>
                            <td colSpan={3}>
                                <div className="d-flex justify-content-between" >
                                    <div>{title}</div>
                                    <a onClick={handleDownloadUtilization}
                                        className={`w-1 ${sortedRoleUtilization?.length == 0 ? 'disabled-link' : ''}`}
                                        style={{ textDecoration: 'none', paddingRight: '10px', color: 'gray', cursor: 'pointer' }}>
                                        <i className="fas fa-download"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedRoleUtilization.map((item) => {
                            let appearanceOrder = (item?.ProjectRolesResource?.Resource || item?.ProjectRolesResource?.Resource == "") && item?.ProjectRolesResource?.AppearanceOrder ? `(${item?.ProjectRolesResource?.AppearanceOrder ?? ''})` : ''
                            return (
                                <tr key={`eachRoleUtilization${item.ProjectRolesResourceId}`}>
                                    <td style={{ fontWeight: 'bold', width: '70%', fontSize: '8pt' }}>
                                        {`${item.ProjectRolesResource?.PCRRoleRate?.Role?.Name}${appearanceOrder} ${item.ProjectRolesResource?.Resource ? ' - ' + item.ProjectRolesResource?.Resource : ''}`}
                                    </td>
                                    <td style={{ borderRight: '1px solid grey', paddingRight: '1px' }}>
                                        {displayPropertyColumn2(item).toFixed(2)}%
                                    </td>
                                    <td className="font-weight-bold" >
                                        {displayPropertyColumn3(item).toFixed(2)}
                                    </td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )

}

export default ProjectRoleUtilizationReport;