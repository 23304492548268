
export const getBaseFTTAPIURLS = (): string => {
    let baseAPIUrls = (window as any).REACT_APP_FTT_API_PUBLIC_URL;
    const currentUrl: string = window.location.href;
    if (currentUrl?.length > 0 && currentUrl.includes('.pub.')) {
        baseAPIUrls = (window as any).REACT_APP_FTT_API_PUBLIC_URL;
    }
    if (currentUrl?.length > 0 && currentUrl.includes('.internal.')) {
        baseAPIUrls = (window as any).REACT_APP_FTT_API_INTERNAL_URL;
    }
    return baseAPIUrls;
}
