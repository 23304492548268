
export const calculateDiscount = (stdRate: number, offeredRate: number): number => {
  if (stdRate > 0 && offeredRate >= 0)
    return parseFloat((((offeredRate - stdRate) * 100) / stdRate).toFixed(2))
  else
    return 0;
}

export const calculateBufferDiscount = (offeredPrice: number, riskAdjustedPrice: number): number => {
  if (offeredPrice > 0 && riskAdjustedPrice >= 0)
    return parseFloat((((riskAdjustedPrice - offeredPrice) * 100) / offeredPrice).toFixed(2))
  else
    return 0;
}

export const calculateALTRate = (stdRate: number, discount: number): number => {
  if (stdRate > 0)
    return parseFloat((stdRate + ((stdRate * discount) / 100)).toFixed(2))
  else
    return 0;
}

export const calculatePercent = (value: number, total: number): number => {
  if (total == 0) {
    return 0;
  }

  const percent = (value / total) * 100;
  return percent;
}

export const addCommasToNumber = (numberString: string) => {
  const parts = numberString.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}


export const formatToUSCurrency = (amount: number | null | undefined): string => {
  if (amount != undefined || amount != null) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  } else {
    return '';
  }
};

export const areObjectsEqual = <T extends {}>(input1: T, input2: T, keys?: (keyof T)[]): boolean => {
  if (keys?.length) {
    for (const key of keys) {
      if (input1[key] !== input2[key]) {
        return false;
      }
    }
    return true;
  }
  else {
    return JSON.stringify(input1) === JSON.stringify(input2);
  }
};

export const parseAndCastDate = (dateString: string): Date | null => {
  if (dateString) {
    const parsedDate = new Date(dateString);
    let outDate = isNaN(parsedDate.getTime()) ? null : parsedDate;
    return outDate;
  } else
    return null
}

export const parseDate = (dateString: string): Date => {
  const parsedDate = new Date(dateString);
  return isNaN(parsedDate.getTime()) ? new Date() : parsedDate; // Default to current date if parsing fails
};

export const parseDateAsStringFormat = (date: Date | null | undefined): string => {
  if (date == null || date == undefined || '' + date == '') {
    return '';
  } else {
    return date.toISOString().substring(0, 10);
  }

}

export const formatDateAsString = (date: Date | null | undefined): string => {
  if (date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${month}/${day}/${year}`;
  } else {
    return '';
  }
}

export const formatDateTimeAsString = (inputDate?: Date | null | undefined): string => {
  if (inputDate) {
    const date = new Date(inputDate.toString().split('.')[0]);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear());
    const hours = String(date.getHours() % 12 || 12).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  } else {
    return '';
  }
}

export const getMinDateAsString = (date: Date | null | undefined): string => {
  if (date == null || date == undefined || '' + date == '') {
    return new Date().toISOString().substring(0, 10);
  } else {
    return date.toISOString().substring(0, 10);
  }
}

export const getDateByWeekNumber = (startDate: Date | undefined, weekNumber: number): string => {
  if (startDate != undefined) {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + (weekNumber - 1) * 7);

    // Adjust to the end of the week (Sunday)
    endDate.setDate(endDate.getDate() + (7 - endDate.getDay()));

    if (startDate > endDate) {
      return startDate.toISOString().substring(0, 10);
    } else {
      return endDate.toISOString().substring(0, 10);
    }
  } else {
    return 'Inavlid Date';
  }
}


export const getWeekDateRangeByWeekNumber = (startDate: Date | undefined, weekNumber: number): [Date, Date] => {
  if (!startDate) {
    return [new Date('0001-01-01T00:00:00Z'), new Date('0001-01-01T00:00:00Z')];
  }

  // Clone the startDate to avoid modifying the original date
  let startDateCopy = new Date(startDate);

  // Calculate the start of the week (Monday)
  const dayOfWeek = startDateCopy.getDay();
  const daysUntilMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  startDateCopy.setDate(startDateCopy.getDate() - daysUntilMonday);

  // Adjust the date range based on the weekNumber
  startDateCopy.setDate(startDateCopy.getDate() + 7 * (weekNumber - 1));

  const endDate = new Date(startDateCopy);
  endDate.setDate(endDate.getDate() + 6);

  if (weekNumber == 1 && startDateCopy < startDate) {
    startDateCopy = startDate;
  }
  return [startDateCopy, endDate]
};

export const getRandomNumber = (min: number = 0, max: number = 10000): number => {
  let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumber;
}

export const delayStatement = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const IsStringExists = (inputStringArray: string[] | null | undefined, validateString: string[] | undefined | null): boolean => {
  let isExist = false;
  if (inputStringArray == null || inputStringArray == undefined || inputStringArray?.length == 0 || validateString == null || validateString == undefined || validateString?.length == 0) {
  } else {
    const matchedRoles = inputStringArray.filter(role => validateString.includes(role));
    isExist = matchedRoles && matchedRoles.length > 0;
  }
  return isExist;
}

export const calculateWeeksDifference = (start: Date | null | undefined, end: Date | null | undefined): number => {
  if (start && end) {
    const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7;
    const differenceInMilliseconds = Math.abs(end.getTime() - start.getTime());
    const weeks = Math.floor(differenceInMilliseconds / millisecondsPerWeek);
    return weeks + 2;
  }
  else {
    return 0;
  }
}

export interface IFrequencyRange {
  name: string;
  startDate: Date;
  endDate: Date;
}


export const getMonthsInRange = (startDate: Date, endDate: Date): IFrequencyRange[] => {
  const ranges: IFrequencyRange[] = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const monthRange: IFrequencyRange = {
      name: startOfMonth.toLocaleString('default', { month: 'long' }),
      startDate: startOfMonth,
      endDate: endOfMonth > endDate ? endDate : endOfMonth,
    };

    ranges.push(monthRange);

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
    currentDate.setDate(1);
  }

  return ranges;
};

export const getQuartersInRange = (startDate: Date, endDate: Date): IFrequencyRange[] => {
  const quarters: IFrequencyRange[] = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const quarterStartMonth = Math.floor(currentDate.getMonth() / 3) * 3;
    const startOfQuarter = new Date(currentDate.getFullYear(), quarterStartMonth, 1);
    const endOfQuarter = new Date(startOfQuarter.getFullYear(), startOfQuarter.getMonth() + 3, 0);

    const quarterRange: IFrequencyRange = {
      name: `Quarter ${Math.floor(quarterStartMonth / 3) + 1}`,
      startDate: startOfQuarter,
      endDate: endOfQuarter > endDate ? endDate : endOfQuarter,
    };

    quarters.push(quarterRange);

    // Move to the start of the next quarter
    currentDate.setMonth(currentDate.getMonth() + 3 - currentDate.getMonth() % 3);
    currentDate.setDate(1);
  }

  return quarters;
};

export const getYearsInRange = (startDate: Date, endDate: Date): IFrequencyRange[] => {
  const years: IFrequencyRange[] = [];
  let currentYear = startDate.getFullYear();

  while (currentYear <= endDate.getFullYear()) {
    const startOfYear = new Date(currentYear, 0, 1);
    const endOfYear = new Date(currentYear, 11, 31);

    // Adjust the end date if it exceeds the endDate
    const yearEndDate = endOfYear > endDate ? new Date(endDate) : endOfYear;

    const yearRange: IFrequencyRange = {
      name: `Year ${currentYear}`,
      startDate: startOfYear,
      endDate: yearEndDate,
    };

    years.push(yearRange);

    // Move to the next year
    currentYear++;
  }

  return years;
};

export const getBiWeeklyRanges = (startDate: Date, endDate: Date): IFrequencyRange[] => {
  const biWeeklyRanges: IFrequencyRange[] = [];
  let currentDate = new Date(startDate);

  let index = 0;
  while (currentDate <= endDate) {
    index++;
    const startOfBiWeek = new Date(currentDate);
    const endOfBiWeek = new Date(currentDate);

    // Add 13 days to get to the end of the bi-week (two weeks)
    endOfBiWeek.setDate(endOfBiWeek.getDate() + 13);

    // Check if the end of the bi-week exceeds the endDate
    if (endOfBiWeek > endDate) {
      endOfBiWeek.setTime(endDate.getTime());
    }

    const biWeeklyRange: IFrequencyRange = {
      name: `Bi-Week-${index}`,
      startDate: startOfBiWeek,
      endDate: endOfBiWeek > endDate ? endDate : endOfBiWeek,
    };

    biWeeklyRanges.push(biWeeklyRange);

    // Move to the start of the next bi-week
    currentDate.setDate(currentDate.getDate() + 14);
  }

  return biWeeklyRanges;
};

export const getWeeksInRange = (startDate: Date, endDate: Date, maxWeekNumber: number): IFrequencyRange[] => {
  const range: IFrequencyRange[] = [];
  for (let i = 1; i <= maxWeekNumber; i++) {
    let [fromDate, toDate] = getWeekDateRangeByWeekNumber(startDate, i);
    range.push({
      name: "Week",
      startDate: fromDate,
      endDate: toDate > endDate ? endDate : toDate
    })
  }
  return range;
};

export const getWeekdaysInRange = (fromDate: Date, toDate: Date): Date[] | null => {
  const datesInRange: Date[] = [];
  let currentDate = new Date(fromDate);

  while (compareDatesOnly(toDate, currentDate) >= 0) {
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      datesInRange.push(new Date(currentDate));
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return datesInRange;
}

export const compareDatesOnly = (firstDate: Date, secondDate: Date): number => {
  const startYear = firstDate.getFullYear();
  const startMonth = firstDate.getMonth();
  const startDay = firstDate.getDate();

  const endYear = secondDate.getFullYear();
  const endMonth = secondDate.getMonth();
  const endDay = secondDate.getDate();

  if (startYear > endYear) {
    return 1; // startDate is greater
  } else if (startYear < endYear) {
    return -1; // endDate is greater
  } else {
    if (startMonth > endMonth) {
      return 1; // startDate is greater
    } else if (startMonth < endMonth) {
      return -1; // endDate is greater
    } else {
      if (startDay > endDay) {
        return 1; // startDate is greater
      } else if (startDay < endDay) {
        return -1; // endDate is greater
      } else {
        return 0; // Dates are equal
      }
    }
  }
}

export const isProjectReadOnlyStatus = (statusId: number | null | undefined, status: string | null | undefined): boolean => {
  let isReadOnly = (statusId == 299 || statusId == 300 || statusId == 400 || statusId == 450 || statusId == 500 || statusId == 600 || statusId == 700 ||
    status == "ApprovedTier1" || status == "Approved" || status == "ProposalDelivered" || status == "Passed" || status == "DealWon" || status == "DealLost" || status == "Archive");
  return isReadOnly;
}

export const isUserRoleReadOnly = (assignedUserRoles: string[] | undefined | null): boolean => {
  let matchedRoles = assignedUserRoles?.length == 1 && assignedUserRoles.filter(role => "FTT_USER".includes(role))?.length == 1;
  if (matchedRoles && IsStringExists(["FTT_SUPER_ADMIN_USER", "FTT_ADMIN_USER"], assignedUserRoles)) {
    matchedRoles = false;
  }
  return matchedRoles;
}

export const isProjectVersionCreateVisible = (statusId: number | null | undefined, status: string | null | undefined): boolean => {
  let isReadOnly = !(statusId == 450 || statusId == 500 || statusId == 600
    || status == "Passed" || status == "DealWon" || status == "DealLost");
  return isReadOnly;
}

export const isProjectStatusChangeReadOnly = (statusId: number | null | undefined, status: string | null | undefined): boolean => {
  let isReadOnly = (statusId == 450 || statusId == 500 || statusId == 600 || statusId == 700
    || status == "Passed" || status == "DealWon" || status == "DealLost" || status == "Archive");
  return isReadOnly;
}

export const calculateEndDate = (startDate: Date | null | undefined, numberOfWeeks: number): Date | null => {
  if (startDate) {
    const endDate: Date = new Date(startDate.getTime());
    endDate.setDate(endDate.getDate() + numberOfWeeks * 7);

    return endDate;
  } else {
    return null;
  }
}

export const getBrowserName = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.indexOf("Firefox") !== -1) {
    return "Mozilla Firefox";
  } else if (userAgent.indexOf("Chrome") !== -1) {
    return "Google Chrome";
  } else if (userAgent.indexOf("Safari") !== -1) {
    return "Apple Safari";
  } else if (userAgent.indexOf("Edge") !== -1 || userAgent.indexOf("Edg") !== -1) {
    return "Microsoft Edge";
  } else if (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident") !== -1) {
    return "Internet Explorer";
  } else {
    return "Unknown Browser";
  }
}

export const getRGBColors = (index: number): string => {
  let matchedColor = '';
  let colors: string[] = [
    "rgb(230, 211, 255)",   // 
    "rgb(133, 188, 155)",   // Light Yellow
    "rgb(109, 240, 245)",   // Lavender Blush
    "rgb(151, 100, 196)",   // Bisque
    "rgb(251, 208, 185)",   // Peach Puff
    "rgb(298, 255, 224)",   // Light Goldenrod Yellow
    "rgb(200, 110, 140)",   // Beige
    "rgb(219, 250, 205)",   // Lemon Chiffon
    "rgb(240, 230, 140)",   // Khaki
    "rgb(255, 240, 245)",   // Lavender Blush
    "rgb(230, 241, 255)",
    "rgb(255, 228, 225)",   // Misty Rose
    "rgb(221, 240, 245)",   // Lavender Blush
    "rgb(167, 192, 203)",   // Pink
    "rgb(189, 182, 193)",   // Light Pink
    "rgb(21, 160, 122)",   // Light Salmon
    "rgb(133, 140, 0)",     // Dark Orange
    "rgb(61, 120, 122)",   // Light Salmon
    "rgb(43, 99, 71)",     // Tomato
    "rgb(255, 105, 180)",   // Hot Pink
    "rgb(76, 182, 193)",    // Light Pink
    "rgb(219, 110, 108)",       // Navy
    "rgb(128, 128, 0)",     // Olive
    "rgb(128, 0, 128)",     // Purple
    "rgb(0, 128, 128)",     // Teal
    "rgb(81, 165, 0)",     // Orange
    "rgb(165, 42, 42)",     // Brown
    "rgb(128, 128, 128)",   // Gray
    "rgb(192, 192, 192)",   // Silver
  ];
  if (colors.length > index) {
    matchedColor = colors[index];
  }
  return matchedColor;
}

export const getPathFromUrl = (url?: string | null | undefined): string => {
  let outputPath = ''
  if (url) {
    outputPath = url.startsWith('/') ? url.slice(1) : url;
  }
  return outputPath;
}