import { Button, Form } from "react-bootstrap";
import { useProjectDataContext } from "../../data-context/project-data-context";
import { useEffect, useState } from "react";
import { IAPIRequestFilter } from "../../data-model";
import { toast } from "react-toastify";
import { patchProjectData } from "../_services/dashboard-svc";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { getRandomNumber, isProjectReadOnlyStatus, isUserRoleReadOnly } from "../../utilities/helper-function";


const DefaultAndHSLink: React.FC<{ projectId?: number }> = ({ projectId }) => {
    const { currentProjectIdGblCtx, setCurrentProjectIdGblCtx, currentProjectInfoGblCtx,
        currentProjectParentIdGblCtx, refreshProjectStatusGblCtx, setRefreshProjectStatusGblCtx,
        refreshProjectVersionGblCtx, userAccessInfoGblCtx, setRefreshProjectVersionGblCtx } = useProjectDataContext();

    const [defaultPlanHSLinkEnable, setDefaultPlanHSLinkEnable] = useState<boolean>(false);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [readonlyUI, setReadonlyUI] = useState<boolean>(false);

    useEffect(() => {
        if (currentProjectInfoGblCtx) {
            setDefaultPlanHSLinkEnable(currentProjectInfoGblCtx.IsDefaultPlan ?? false)
        }
    }, []);

    useEffect(() => {
        if (refreshProjectStatusGblCtx && refreshProjectStatusGblCtx > 0) {
            setReadonlyUI(isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) || isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles));
        }
    }, [refreshProjectStatusGblCtx]);

    const setDefaultHSLink = async (e: any) => {
        setLoadingStatus(true)
        if (currentProjectIdGblCtx && currentProjectIdGblCtx > 0) {
            if (currentProjectInfoGblCtx && currentProjectInfoGblCtx.HubspotClientId && currentProjectInfoGblCtx.HubspotClientId > 0 && currentProjectInfoGblCtx.HubspotDealId && currentProjectInfoGblCtx.HubspotDealId > 0) {
                let patchData: IAPIRequestFilter[] = [{
                    Field: "defaultplanlinkupdate",
                    Operator: '',
                    Value: '' + (!defaultPlanHSLinkEnable),
                    Text: ''
                }
                ];
                let [isSuccess, message] = await patchProjectData(currentProjectIdGblCtx, patchData)
                if (isSuccess) {
                    setDefaultPlanHSLinkEnable(!defaultPlanHSLinkEnable);
                    toast.success(message, { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                    //setRefreshProjectHeaderGblCtx(getRandomNumber());
                    //setRefreshProjectStatusGblCtx(getRandomNumber());
                    setRefreshProjectVersionGblCtx(getRandomNumber());
                } else {
                    toast.error("Error:" + message, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
                }
            } else {
                toast.error(`Unable to set plan as default. Please connect the plan to a valid (and open) HubSpot client and deal by opening the project header and inputting “Client Name” and “Select HubSpot Deal”`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
            setLoadingStatus(false);
        }
    }

    return (<>
        {loadingStatus && <LoadingIcon />}
        <div>
            <Form.Check
                style={{ fontWeight: 'bold', cursor: 'pointer' }}
                type="switch"
                id="default-hs-link"
                label="Default & HS Link"
                title="Default & Link"
                disabled={readonlyUI}
                checked={defaultPlanHSLinkEnable}
                onChange={(e) => setDefaultHSLink(e)}
            />
        </div>
    </>)
}

export default DefaultAndHSLink;