import { useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { Button, Form } from "react-bootstrap";
import { importPCRRatesFromPreviousYear } from "../_services/pcr-rates-svc";

const ImportPCRRateFromPreviousYear: React.FC<{}> = ({ }) => {

    const [loadingCreateMasterData, setLoadingCreateMasterData] = useState<boolean>(false);
    const [disabledCreateMasterDataButton, setDisabledCreateMasterDataButton] = useState<boolean>(false);

    const handleCreateMasterData = async () => {
        setLoadingCreateMasterData(true);
        setDisabledCreateMasterDataButton(true);
        let isSuccess = await importPCRRatesFromPreviousYear(new Date().getFullYear());
        if (isSuccess) {
            toast.success("Successfully Imported PCR Rate Data. See Seq for more information.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
        }
        else {
            toast.error("Error occurred", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setDisabledCreateMasterDataButton(false);
        setLoadingCreateMasterData(false);
    }

    return (
        <>
            <div style={{ marginLeft: '30%', minHeight: '410px', maxHeight: '410px' }}>
                {loadingCreateMasterData && <LoadingIcon />}
                <div>
                    <Form.Control as="select" name="Year" style={{width: '150px'}}
                        value={new Date().getFullYear()}
                    >
                        <option value={new Date().getFullYear()} disabled>{new Date().getFullYear()}</option>

                    </Form.Control>
                </div>
                <div className="d-flex justify-content-between">

                    <Button disabled={disabledCreateMasterDataButton} variant="primary" onClick={handleCreateMasterData}>
                        Create PCR Rate from Previous Year
                    </Button>
                </div>
            </div>
        </>
    );
}


export default ImportPCRRateFromPreviousYear;