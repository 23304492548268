import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const ApplyRoleDiscountModal: React.FC<{
    show: boolean;
    onClose: () => void;
    onApplyDiscount: (discountPercent: number) => void;
}> = ({ show, onClose, onApplyDiscount }) => {

    const [roleRateDiscountPercent, setRoleRateDiscountPercent] = useState<number>();
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

    const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let discount = parseFloat(value)
        setRoleRateDiscountPercent(discount);
    }

    const handleApplyRateDiscount = async () => {
        const errors = validateFormData();
        if (Object.keys(errors).length === 0 && roleRateDiscountPercent != undefined) {
            onApplyDiscount(roleRateDiscountPercent)
        }
    }

    const validateFormData = async () => {
        const errors: { [key: string]: string } = {};
        setValidationErrors({});
        if (roleRateDiscountPercent == undefined) {
            errors.Discount = `Discount(-)/Markup(+) is required`;
        }
        if (roleRateDiscountPercent != undefined && (roleRateDiscountPercent <= -100 || roleRateDiscountPercent > 100)) {
            errors.Discount = `Discount(-)/Markup(+) should be between greater '-100%' and +100%`;
        }
        setValidationErrors(errors);
        return errors;
    }

    return (
        <>
            <Modal onClick={handleModalClick} show={show} onHide={onClose} onClose={onClose} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Apply Discount / Markup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group controlId="formVersionName">
                                    <Form.Label>Discount(-)/Markup(+)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="discountPercent"
                                        value={roleRateDiscountPercent}
                                        onChange={handleInputChange}
                                        isInvalid={!!validationErrors.Discount}
                                        placeholder="Enter your rate  discount..."
                                    />
                                </Form.Group>
                            </div>
                            {
                                validationErrors.Discount && <p style={{ color: 'red' }} className="error-message">{validationErrors.Discount}*</p>
                            }
                        </div>
                        <strong>Note: The following discount will apply to all role(s).</strong>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ marginTop: '15px' }} variant="secondary" onClick={onClose}>
                        <i className="fas fa-times"></i>  Close
                    </Button>
                    <Button style={{ marginLeft: '10px', marginRight: '10px', marginTop: '14px' }} variant="primary" onClick={handleApplyRateDiscount}>
                        <i className="fas fa-save"></i> Apply
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ApplyRoleDiscountModal;