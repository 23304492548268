import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';
import { IPCRRoleRate, IProjectRolesResource, IProjectEffortSummary, IProjectHeaderDetails, IRisk, IProjectRoleUtilization, IProjectStatus, ISkillBase } from '../data-model';
import { defaultProjectEffortSummary } from '../scenes/_services/initial-default-data';
import IProjectPhaseUtilization from '../data-model/project-phase-utilization';
import IUserRoleAccessType from '../data-model/user-role-access-type';

export interface ProjectDataContextProps {
  currentProjectIdGblCtx: number | undefined;
  setCurrentProjectIdGblCtx: (currentProjectId: number | undefined) => void;

  currentProjectInfoGblCtx: IProjectHeaderDetails | undefined;
  setCurrentProjectInfoGblCtx: (projectHeaderDetails: IProjectHeaderDetails | undefined) => void;

  allProjectRoleResoursesGblCtx: IProjectRolesResource[];
  setAllProjectRoleResoursesGblCtx: (allProjectResourseEffort: IProjectRolesResource[]) => void;
  addProjectEffortHoursDataGblCtx: (allProjectResourseEffort: IProjectRolesResource) => void;

  projectEffortSummaryGblCtx: IProjectEffortSummary;
  setProjectEffortSummaryGblCtx: (projectEffortSummary: IProjectEffortSummary) => void;

  allCurrentPCRRoleRateGblCtx: IPCRRoleRate[];
  setAllCurrentPCRRoleRateGblCtx: (allCurrentPCRRoleRate: IPCRRoleRate[]) => void;

  allMasterStatusGblCtx: IProjectStatus[];
  setAllMasterStatusGblCtx: (allStatus: IProjectStatus[]) => void;

  refreshLevelOfEstimationGblCtx: number | undefined;
  setRefreshLevelOfEstimationGblCtx: (refreshChangeRandomNumber: number) => void;

  allDefaultRiskGblCtx: IRisk[];
  setAllDefaultRiskGblCtx: (risks: IRisk[]) => void;

  allDefaultSkillBaseGblCtx: ISkillBase[];
  setAllDefaultSkillBaseGblCtx: (skills: ISkillBase[]) => void;

  allProjectRoleUtilizationGblCtx: IProjectRoleUtilization[];
  setAllProjectRoleUtilizationGblCtx: (risks: IProjectRoleUtilization[]) => void;

  allPhaseRoleUtilizationGblCtx: IProjectRoleUtilization[];
  setAllPhaseRoleUtilizationGblCtx: (risks: IProjectRoleUtilization[]) => void;

  allProjectPhaseUtilizationGblCtx: IProjectPhaseUtilization[];
  setAllProjectPhaseUtilizationGblCtx: (phases: IProjectPhaseUtilization[]) => void;

  refreshProjectHeaderGblCtx: number | undefined;
  setRefreshProjectHeaderGblCtx: (refreshChangeRandomNumber: number) => void;

  currentProjectParentIdGblCtx: number | undefined;
  setCurrentProjectParentIdGblCtx: (currentParentProjectId: number | undefined) => void;

  refreshProjectStatusGblCtx: number | undefined;
  setRefreshProjectStatusGblCtx: (refreshChangeRandomNumber: number) => void;

  refreshProjectVersionGblCtx: number | undefined;
  setRefreshProjectVersionGblCtx: (refreshChangeRandomNumber: number) => void;

  userAccessInfoGblCtx: IUserRoleAccessType | undefined,
  setUserAccessInfoGblCtx: (info: IUserRoleAccessType | undefined) => void;

  isRoleWillCreateAtEffortGblCtx: boolean | true,
  setIsRoleWillCreateAtEffortGblCtx: (isCopyAtEffort: boolean) => void;

  selectedPhaseGblCtx: string[];
  setSelectedPhaseGblCtx: (phases: string[]) => void;
}

interface ProjectDataProviderProps {
  children: ReactNode;
}

const ProjectDataContext = createContext<ProjectDataContextProps | undefined>(undefined);

export const ProjectDataProvider: React.FC<ProjectDataProviderProps> = ({ children }) => {
  const [data, setData] = useState<ProjectDataContextProps>({

    currentProjectIdGblCtx: undefined,
    setCurrentProjectIdGblCtx: (currentProjectId: number | undefined) => {
      setData((prevData) => ({
        ...prevData,
        currentProjectIdGblCtx: currentProjectId,
      }));
    },

    currentProjectInfoGblCtx: undefined,
    setCurrentProjectInfoGblCtx: (projectHeaderDetails: IProjectHeaderDetails | undefined) => {
      setData((prevData) => ({
        ...prevData,
        currentProjectInfoGblCtx: projectHeaderDetails,
      }));
    },

    allProjectRoleResoursesGblCtx: [],
    setAllProjectRoleResoursesGblCtx: (allProjectResourse: IProjectRolesResource[]) => {
      if (allProjectResourse.length > 0)
        setData((prevData) => ({
          ...prevData,
          allProjectRoleResoursesGblCtx: allProjectResourse,
        }));
      else {
        setData((prevData) => ({
          ...prevData,
          allProjectRoleResoursesGblCtx: [],
        }));
      }
    },
    addProjectEffortHoursDataGblCtx: (allProjectResourse: IProjectRolesResource) => {
      setData((prevData) => ({
        ...prevData,
        allProjectRoleResoursesGblCtx: [...prevData.allProjectRoleResoursesGblCtx, allProjectResourse],
      }));
    },

    projectEffortSummaryGblCtx: defaultProjectEffortSummary,
    setProjectEffortSummaryGblCtx: (projectEffortSummary: IProjectEffortSummary) => {
      setData((prevData) => ({
        ...prevData,
        projectEffortSummaryGblCtx: projectEffortSummary,
      }));
    },

    allCurrentPCRRoleRateGblCtx: [],
    setAllCurrentPCRRoleRateGblCtx: (allCurrentPCRRoleRate: IPCRRoleRate[]) => {
      setData((prevData) => ({
        ...prevData,
        allCurrentPCRRoleRateGblCtx: allCurrentPCRRoleRate,
      }));
    },

    allMasterStatusGblCtx: [],
    setAllMasterStatusGblCtx: (allStatus: IProjectStatus[]) => {
      setData((prevData) => ({
        ...prevData,
        allMasterStatusGblCtx: allStatus,
      }));
    },

    refreshLevelOfEstimationGblCtx: undefined,
    setRefreshLevelOfEstimationGblCtx: (refreshChangeRandomNumber: number | undefined) => {
      setData((prevData) => ({
        ...prevData,
        refreshLevelOfEstimationGblCtx: refreshChangeRandomNumber,
      }));
    },

    allDefaultRiskGblCtx: [],
    setAllDefaultRiskGblCtx: (risks: IRisk[]) => {
      setData((prevData) => ({
        ...prevData,
        allDefaultRiskGblCtx: risks,
      }));
    },

    allDefaultSkillBaseGblCtx: [],
    setAllDefaultSkillBaseGblCtx: (skills: ISkillBase[]) => {
      setData((prevData) => ({
        ...prevData,
        allDefaultSkillBaseGblCtx: skills,
      }));
    },

    allProjectRoleUtilizationGblCtx: [],
    setAllProjectRoleUtilizationGblCtx: (roleUtilizations: IProjectRoleUtilization[]) => {
      setData((prevData) => ({
        ...prevData,
        allProjectRoleUtilizationGblCtx: roleUtilizations,
      }));
    },

    allPhaseRoleUtilizationGblCtx: [],
    setAllPhaseRoleUtilizationGblCtx: (roleUtilizations: IProjectRoleUtilization[]) => {
      setData((prevData) => ({
        ...prevData,
        allPhaseRoleUtilizationGblCtx: roleUtilizations,
      }));
    },

    allProjectPhaseUtilizationGblCtx: [],
    setAllProjectPhaseUtilizationGblCtx: (phases: IProjectPhaseUtilization[]) => {
      setData((prevData) => ({
        ...prevData,
        allProjectPhaseUtilizationGblCtx: phases,
      }));
    },

    refreshProjectHeaderGblCtx: undefined,
    setRefreshProjectHeaderGblCtx: (refreshChangeRandomNumber: number | undefined) => {
      setData((prevData) => ({
        ...prevData,
        refreshProjectHeaderGblCtx: refreshChangeRandomNumber,
      }));
    },

    currentProjectParentIdGblCtx: undefined,
    setCurrentProjectParentIdGblCtx: (currentParentProjectId: number | undefined) => {
      setData((prevData) => ({
        ...prevData,
        currentProjectParentIdGblCtx: currentParentProjectId,
      }));
    },

    refreshProjectStatusGblCtx: undefined,
    setRefreshProjectStatusGblCtx: (refreshChangeRandomNumber: number | undefined) => {
      setData((prevData) => ({
        ...prevData,
        refreshProjectStatusGblCtx: refreshChangeRandomNumber,
      }));
    },

    refreshProjectVersionGblCtx: undefined,
    setRefreshProjectVersionGblCtx: (refreshChangeRandomNumber: number | undefined) => {
      setData((prevData) => ({
        ...prevData,
        refreshProjectVersionGblCtx: refreshChangeRandomNumber,
      }));
    },

    userAccessInfoGblCtx: undefined,
    setUserAccessInfoGblCtx: (info: IUserRoleAccessType | undefined) => {
      setData((prevData) => ({
        ...prevData,
        userAccessInfoGblCtx: info,
      }));
    },

    isRoleWillCreateAtEffortGblCtx: true,
    setIsRoleWillCreateAtEffortGblCtx: (isCopyAtEffort: boolean) => {
      setData((prevData) => ({
        ...prevData,
        isRoleWillCreateAtEffortGblCtx: isCopyAtEffort,
      }));
    },

    selectedPhaseGblCtx: [],
    setSelectedPhaseGblCtx: (phases: string[]) => {
      setData((prevData) => ({
        ...prevData,
        selectedPhaseGblCtx: phases,
      }));
    },


  });

  return (
    <ProjectDataContext.Provider value={{ ...data }}>
      {children}
    </ProjectDataContext.Provider>
  );
};

export const useProjectDataContext = () => {
  const context = useContext(ProjectDataContext);
  if (context === undefined) {
    throw new Error('useDataContext must be used within a DataProvider');
  }
  return context;
};
