import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import ProjectHeaderCreate from './project-header-create';
import ProjectHeaderDetails from './project-header-details';
import { IProjectHeaderDetails } from '../../data-model';
import { deleteProject, getProjectHeader, saveNewProjectHeader, updateProjectHeader } from "../_services/dashboard-svc";
import { defaultProjectHeaderDetails } from '../_services/initial-default-data';
import LoadingIcon from '../../common/loading-icon/loading-icon';
import { useProjectDataContext } from '../../data-context/project-data-context';
import { useDelayDebounce } from '../../common/hooks/debounce';
import ProjectDuplicateModal from './project-version-create-modal';
import ConfirmationModal from "../../common/confirmation-dialog";
import { getRandomNumber, isProjectReadOnlyStatus, isProjectVersionCreateVisible, isUserRoleReadOnly } from '../../utilities/helper-function';
import InfoModal from '../../common/confirmation-dialog/information-dialog';
import UserComments from '../user-comments';
import AuditDetails from '../audit/audit-details';

const ProjectHeaderManage: React.FC<{ projectId?: number }> = ({ projectId }) => {
    const { currentProjectIdGblCtx, setCurrentProjectIdGblCtx, setCurrentProjectInfoGblCtx,
        refreshProjectHeaderGblCtx, setCurrentProjectParentIdGblCtx, currentProjectInfoGblCtx,
        refreshProjectStatusGblCtx, setRefreshProjectStatusGblCtx,
        setRefreshProjectVersionGblCtx, userAccessInfoGblCtx } = useProjectDataContext();

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const delayDebouncedValue = useDelayDebounce(100);
    const delayProjectCheckValue = useDelayDebounce(200);

    const navigate = useNavigate();
    const [projectHeaderDetails, setProjectHeaderDetails] = useState<IProjectHeaderDetails>(defaultProjectHeaderDetails);
    const [showProjectCreateModal, setShowProjectCreateModal] = useState<boolean>(false);
    const [showProjectAuditModal, setShowProjectAuditModal] = useState<boolean>(false);
    const [showUserCommentModal, setShowUserCommentModal] = useState<boolean>(false);
    const [showProjectDuplicateModal, setShowProjectDuplicateModal] = useState<boolean>(false);
    const [loadingProjectCreate, setLoadingProjectCreate] = useState<boolean>(false);
    const [actionText, setActionText] = useState<string>('');
    const [hideEditProjectLink, setHideEditProjectLink] = useState<boolean>(false);
    const [showProjectConfirmationDeleteModal, setShowProjectConfirmationDeleteModal] = useState<boolean>(false);
    const [displayProjectDeleteLink, setDisplayProjectDeleteLink] = useState<boolean>(false);

    useEffect(() => {
        if (delayDebouncedValue > 0) {
            fetchProjectHeader();
        } else {
            setProjectHeaderDetails(defaultProjectHeaderDetails);
            setCurrentProjectInfoGblCtx(undefined);
        }

    }, [delayDebouncedValue, currentProjectIdGblCtx]);

    useEffect(() => {
        projectId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
        if (delayProjectCheckValue > 0 && (projectId == 0 || projectId == undefined)) {
            decideToDisplayCreateNewProject(projectHeaderDetails.Title, projectHeaderDetails.EngagementManager);
        }
    }, [delayProjectCheckValue, projectHeaderDetails]);

    useEffect(() => {
        setActionText(currentProjectIdGblCtx != undefined && currentProjectIdGblCtx > 0 ? "Edit" : "Add");
    }, [currentProjectIdGblCtx]);

    useEffect(() => {
        if (refreshProjectStatusGblCtx && refreshProjectStatusGblCtx > 0) {
            setHideEditProjectLink(isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) || isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles));
        }
    }, [refreshProjectStatusGblCtx]);

    const fetchProjectHeader = async () => {
        setCurrentProjectInfoGblCtx(undefined);
        projectId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
        setActionText(projectId != undefined && projectId > 0 ? "Edit" : "Add");
        if (projectId !== undefined && projectId > 0) {
            setLoadingProjectCreate(true);
            const data = await getProjectHeader(projectId);
            setLoadingProjectCreate(false);
            if (data) {
                setProjectHeaderDetails(data);
                projectId = data.ProjectId;
                setCurrentProjectInfoGblCtx(data);
                setCurrentProjectParentIdGblCtx(data.ParentProjectId ?? undefined);
                decideToDisplayCreateNewProject(data.Title, data.EngagementManager);
                setRefreshProjectStatusGblCtx(getRandomNumber());
            } else {
                setProjectHeaderDetails(defaultProjectHeaderDetails);
            }
        } else {

        }
    };

    const toggleProjectHeaderCreateModal = () => {
        setShowProjectCreateModal(!showProjectCreateModal);
    };

    const toggleProjectHeaderAuditModal = () => {
        setShowProjectAuditModal(!showProjectAuditModal);
    };

    const toggleUserCommentModal = () => {
        setShowUserCommentModal(!showUserCommentModal);
    };

    const toggleProjectDuplicateModal = () => {
        setShowProjectDuplicateModal(!showProjectDuplicateModal);
    };

    const decideToDisplayCreateNewProject = (title: string | undefined, engagementManager: string | undefined) => {
        if ((title == '' || title == null) && (engagementManager == '' || engagementManager == null)) {
            setShowProjectCreateModal(true);
        } else {
            setShowProjectCreateModal(false);
        }
    }

    const saveProject = async (updatedDetails: IProjectHeaderDetails) => {
        let [isSuccess, message] = [false, ''];
        setLoadingProjectCreate(true);
        if (updatedDetails.ProjectId) {
            [isSuccess, message] = await updateProjectHeader(updatedDetails);
        }
        else {
            updatedDetails.DatePrepared = new Date();
            updatedDetails.Preparer = activeAccount?.name ?? '';
            updatedDetails.Status = "1"; //draft
            [isSuccess, message] = await saveNewProjectHeader(updatedDetails);
            if (isSuccess) {
                projectId = parseInt(message);
                updatedDetails.ProjectId = projectId;
                setCurrentProjectIdGblCtx(projectId);
                navigate(`/project-dashboard/${projectId}`)
            }
        }
        if (isSuccess) {
            await fetchProjectHeader();
            toast.success("successfully saved", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
        }
        else {
            toast.error("Error:" + message, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setLoadingProjectCreate(false);
        return [isSuccess, message]
    };

    const handleSave = (updatedDetails: IProjectHeaderDetails) => {
        saveProject(updatedDetails);
    };

    const handleDeleteProjectClick = () => {
        setShowProjectConfirmationDeleteModal(true);
    };

    const confirmProjectDelete = async () => {
        if (showProjectConfirmationDeleteModal && currentProjectIdGblCtx && currentProjectIdGblCtx > 0) {
            let [isSuccess, message] = await deleteProject(currentProjectIdGblCtx);
            if (isSuccess) {
                toast.success("Deleted Successfully.", { toastId: 'success1', autoClose: 4000, position: toast.POSITION.TOP_CENTER });
                setShowProjectConfirmationDeleteModal(false);
                if (currentProjectInfoGblCtx?.ParentProjectId && currentProjectInfoGblCtx?.ParentProjectId > 0) {
                    setRefreshProjectVersionGblCtx(getRandomNumber());
                    navigate(`/project-dashboard/${currentProjectInfoGblCtx.ParentProjectId}`);
                } else {
                    navigate('/');
                }
            }
            else {
                toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
        }
    };

    const cancelProjectDelete = () => {
        setShowProjectConfirmationDeleteModal(false);
    };

    const onCloseUserCommentModal = () => {
        setShowUserCommentModal(false);
    };

    const deleteText = (): string => {
        let text = 'Delete Project';
        if (currentProjectInfoGblCtx?.ParentProjectId && currentProjectInfoGblCtx?.ParentProjectId > 0) {
            text = `Delete Version '${currentProjectInfoGblCtx.VersionName}'`
        }
        return text;
    };

    return (<>
        <div>
            <ConfirmationModal
                show={showProjectConfirmationDeleteModal}
                title="Confirm Deletion"
                message={"Are you sure " + deleteText()}
                onClose={() => setShowProjectConfirmationDeleteModal(false)}
                onCancel={cancelProjectDelete}
                onConfirm={confirmProjectDelete}
            />
            {<InfoModal
                show={showUserCommentModal}
                title='Discussion and Attachment'
                showCloseButton={true}
                onClose={onCloseUserCommentModal}
                htmlBody={<UserComments />}
            />}
        </div>
        <div className="card shadow mb-1 modalClass">
            <div
                className="card-header py-1 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">Project Header
                    {!hideEditProjectLink && <a style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={toggleProjectHeaderCreateModal} className="w-100">
                        {actionText == "Add" ? <i className="fas fa-plus"></i> : <i className="fas fa-edit"></i>}
                    </a>
                    }
                </h6>
                <div className="dropdown arrow">
                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                        aria-labelledby="dropdownMenuLink">
                        <div className="dropdown-header">Dropdown Header:</div>
                        {!hideEditProjectLink && <a className="dropdown-item" href="#" onClick={toggleProjectHeaderCreateModal}>{actionText} Project Header

                        </a>
                        }
                        <div>
                            <ProjectHeaderCreate show={showProjectCreateModal} onClose={toggleProjectHeaderCreateModal} onSave={handleSave} projectDetails={projectHeaderDetails} />
                        </div>
                        <div className="dropdown-divider"></div>
                        {currentProjectIdGblCtx && isProjectVersionCreateVisible(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) && !isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles)
                            && <a className="dropdown-item" href="#" onClick={toggleProjectDuplicateModal}>Create New Version
                            </a>
                        }
                        <div>
                            <ProjectDuplicateModal show={showProjectDuplicateModal} onClose={toggleProjectDuplicateModal} projectId={projectHeaderDetails.ProjectId ?? currentProjectIdGblCtx} parentProjectId={projectHeaderDetails.ParentProjectId} />
                        </div>
                        {currentProjectIdGblCtx && <a className="dropdown-item" href={`/invoice-schedule/${currentProjectIdGblCtx}`}>Invoice Schedule
                        </a>
                        }
                        {currentProjectIdGblCtx && <a className="dropdown-item" href="#" onClick={toggleUserCommentModal}>Supporting Documentation
                        </a>
                        }

                        {currentProjectIdGblCtx && <a className="dropdown-item" href="#" onClick={toggleProjectHeaderAuditModal}>Audit History
                            {<InfoModal
                                show={showProjectAuditModal}
                                title='Audit Transactions'
                                onClose={toggleProjectHeaderAuditModal}
                                htmlBody={<AuditDetails projectId={projectId ?? 0} />}
                                modalSize='modal-xl'
                            />}
                        </a>
                        }
                        {!hideEditProjectLink && currentProjectIdGblCtx && currentProjectInfoGblCtx?.IsProjectDeleteApplicable &&
                            <a className="dropdown-item" href="#" onClick={() => handleDeleteProjectClick()}>
                                {deleteText()}
                            </a>
                        }
                    </div>
                </div>
            </div>

            {/*  <!-- Card Body --> */}
            <div className={loadingProjectCreate ? 'card-body table-fade' : 'card-body'}>
                {loadingProjectCreate ? (<LoadingIcon />) : ''}
                <ProjectHeaderDetails projectHeaderDetails={projectHeaderDetails} />
            </div>
        </div>
    </>
    )
}

export default ProjectHeaderManage;