import { useEffect, useState } from "react";
import { useProjectDataContext } from "../../data-context/project-data-context";
import { calculateDiscount, formatDateAsString, formatToUSCurrency, getRandomNumber } from "../../utilities/helper-function";

const ProjectPhaseCostUtilizationReport: React.FC<{}> = ({ }) => {
    const { allProjectPhaseUtilizationGblCtx, projectEffortSummaryGblCtx, currentProjectInfoGblCtx,
        setAllProjectRoleUtilizationGblCtx, allProjectRoleResoursesGblCtx, setSelectedPhaseGblCtx
    } = useProjectDataContext();
    const [displayRiskPhaseUtilization, setDisplayRiskPhaseUtilization] = useState<boolean>(false);
    const [selectedPhase, setSelectedPhase] = useState<string[]>([]);

    let title = 'Phase/Cost Utilization';
    let titleRisk = 'Risk Adjusted - Phase/Cost Utilization';

    useEffect(() => {
        setSelectedPhaseGblCtx(selectedPhase);
    }, [selectedPhase]);

    const handleSelectedPhase = (phase: string) => {
        if (selectedPhase.some(e => e == phase)) {
            setSelectedPhase(selectedPhase.filter(f => f != phase));
        } else {
            setSelectedPhase([...selectedPhase, phase]);
        }
    };

    const handleRiskAdjustedPhaseUtilization = () => {
        setDisplayRiskPhaseUtilization(!displayRiskPhaseUtilization);
        setSelectedPhase([]);
    };

    return (
        <>
            <div style={{ overflowY: 'scroll' }}>
                <table>
                    <thead>
                        <tr onClick={(e) => handleRiskAdjustedPhaseUtilization()} style={{ height: '10px', width: '45%', color: 'white', backgroundColor: '#1DB1AF', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer' }}>
                            <td colSpan={3}>
                                {displayRiskPhaseUtilization ? titleRisk : title}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {allProjectPhaseUtilizationGblCtx.map((item) => {
                            let phaseCostPercent = 0;
                            if (projectEffortSummaryGblCtx.ProjectCost > 0 && item.TotalCostUtilize >= 0) {
                                phaseCostPercent = (item.TotalCostUtilize * 100) / projectEffortSummaryGblCtx.ProjectCost;
                            }

                            let phaseTimePercent = 0;
                            if (projectEffortSummaryGblCtx.TotalHours > 0 && item.TotalTimeUtilize >= 0) {
                                phaseTimePercent = (item.TotalTimeUtilize * 100) / projectEffortSummaryGblCtx.TotalHours
                            }

                            let color = selectedPhase.some(e => e == item.ProjectPhase) ? 'grey' : '';

                            return (
                                <tr key={`phase${getRandomNumber()}`} style={{ backgroundColor: color }} onClick={(e) => handleSelectedPhase(item.ProjectPhase)}>
                                    <td style={{ fontWeight: 'bold', width: '80%' }} title={formatDateAsString(item.PhaseStartDate) + ' - ' + formatDateAsString(item.PhaseEndDate)}>{(item.ProjectPhase == 'null' || item.ProjectPhase == null || item.ProjectPhase == '') ? 'N/A' : item.ProjectPhase}</td>
                                    {!displayRiskPhaseUtilization && <td style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey', paddingRight: '3px' }}>{phaseCostPercent?.toFixed(2)}%</td>}
                                    {!displayRiskPhaseUtilization && <td style={{ fontWeight: 'bold', paddingLeft: '3px' }}>{formatToUSCurrency(item.TotalCostUtilize)}</td>}
                                    {displayRiskPhaseUtilization && <td style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey', paddingRight: '3px' }}>{phaseCostPercent?.toFixed(2)}%</td>}
                                    {displayRiskPhaseUtilization && <td style={{ fontWeight: 'bold', paddingLeft: '3px' }}>{formatToUSCurrency((item.TotalCostUtilize * (1 + (currentProjectInfoGblCtx?.RiskMultiple ?? 0))))}</td>}
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )

}

export default ProjectPhaseCostUtilizationReport;