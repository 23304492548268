import React from "react";
import { MSALLogin } from "./ad-login-ui";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const SessionTimedOutComponent = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const activeAccount = instance.getActiveAccount();

  const handleLogout = () => {
    instance.logoutRedirect({
      account: activeAccount
    }).catch((error) => console.error(error));
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <h2><i className="fas fa-times-circle"></i>Session Timedout</h2>
      <p>
        Your ({activeAccount?.name}) Microsoft access token has expired and you will need to log out and log back in to access. Please make sure
        you are logged in with the correct credentials or contact the administrator for assistance.
      </p>
      {isAuthenticated && (<p>
        <a style={{ color: 'blue' }} onClick={handleLogout}>Click <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-blue-400"></i>here to Signout </a>
      </p>)}
    </div>
  );
};

export default SessionTimedOutComponent;
