import { loginRequest } from '../../config/auth-config';
import resultantLogo from '../../../assets/RES_Word_w_Tag_Blue_250px.png';
import { Button } from 'react-bootstrap';
import { createContext, useEffect } from 'react';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';

export const MSALLogin = () => {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const handleLoginRedirect = async () => {
        try {
            if (accounts.length === 0 && !isAuthenticated && inProgress === InteractionStatus.None) {
                await instance.handleRedirectPromise();
                await instance.loginRedirect({ ...loginRequest })
                    .catch(async (e) => {
                        console.error("handleLoginRedirect inside error:", e);
                    });
            }
        }
        catch (error) {
            console.error("handleLoginRedirect error:", error);
        }
    };

    return (
        <>
            <div>
                <div className="container d-flex flex-column align-items-center justify-content-center vh-10">
                    <div>
                        <img src={resultantLogo} style={{ width: '250px', height: '90px' }} className="card-img-top" title={`Financial Tool`} />
                    </div>
                    <div>
                        <Button onClick={async () => await handleLoginRedirect()}>Azure AD Sign In</Button>
                    </div>
                </div>
            </div>
        </>
    );
};
