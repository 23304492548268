import React, { useEffect } from 'react';
import './App.scss';
import { ProjectDataProvider } from './data-context/project-data-context';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { MSALLogin } from './scenes/login/ad-login-ui';
import { ValidateAuthentication } from './scenes/login/validate-authentication';
import APPRoute from './router/app-routes';

export default function App() {

  return (
    <div>
      <React.Fragment>
        <ProjectDataProvider>
          <UnauthenticatedTemplate>
            <MSALLogin />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <ValidateAuthentication>
              <APPRoute />
            </ValidateAuthentication>
          </AuthenticatedTemplate>
        </ProjectDataProvider>
      </React.Fragment>
    </div>
  );
}