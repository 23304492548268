import { useEffect, useState } from "react";
import ConfirmationModal from "../../common/confirmation-dialog";
import IUserComment from "../../data-model/user-comment";
import { useProjectDataContext } from "../../data-context/project-data-context";
import { deleteUserCommentAndAttachment, downloadCommentAttachment, getProjectAllUserComments, getUserCommentAttachmentBlobUrl } from "../_services/user-comment-svc";
import CreateUserComments from "./create-user-comment";
import { formatDateTimeAsString, isProjectReadOnlyStatus, isUserRoleReadOnly } from "../../utilities/helper-function";
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';


const UserComments: React.FC = () => {

    const { currentProjectIdGblCtx, currentProjectInfoGblCtx, userAccessInfoGblCtx, refreshProjectStatusGblCtx } = useProjectDataContext();

    const [allUserComments, setAllUserComments] = useState<IUserComment[]>();
    const [loadingRoleGrid, setLoadingRoleGrid] = useState<boolean>(false);

    const [showCommentCreateEditComp, setShowCommentCreateEditComp] = useState<boolean>(false);
    const [showEditLink, setShowEditLink] = useState<boolean>(false);
    const [showDeleteLink, setShowDeleteLink] = useState<boolean>(false);

    const [showModal, setShowModal] = useState(false);
    const [currentSelectedRecord, setCurrentSelectedRecord] = useState<IUserComment | null | undefined>();
    const [recordToDeleteMessage, setRecordToDeleteMessage] = useState<string>('');
    const [showRecDeleteModal, setShowRecDeleteModal] = useState<boolean>(false);

    let isReadOnly = isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) || isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles);

    useEffect(() => {
        fetchProjectUserComments();
    }, [currentProjectIdGblCtx]);

    useEffect(() => {
        if (refreshProjectStatusGblCtx && refreshProjectStatusGblCtx > 0) {
            setShowEditLink(!isReadOnly);
            setShowDeleteLink(!isReadOnly);
        }
    }, [refreshProjectStatusGblCtx]);

    const fetchProjectUserComments = async () => {
        if (currentProjectIdGblCtx != undefined && currentProjectIdGblCtx > 0) {
            setLoadingRoleGrid(true);
            const data = await getProjectAllUserComments(currentProjectIdGblCtx);
            setLoadingRoleGrid(false);
            if (data) {
                setAllUserComments(data);
            }
        }
        else {
            setAllUserComments([]);
        }
    };

    const onCommentCreateClose = (isSuccessRec: boolean) => {
        if (isSuccessRec) {
            fetchProjectUserComments();
        }
        setShowCommentCreateEditComp(false)
        setCurrentSelectedRecord(null);
    };

    const deleteResource = async (rec: IUserComment) => {
        if (rec && rec?.Id && rec?.Id > 0) {
            setCurrentSelectedRecord(rec)
            setRecordToDeleteMessage(`Are you sure you want to delete record?`);
            setShowRecDeleteModal(true);
        }
    };

    const editResource = (rec: IUserComment) => {
        if (rec && rec?.Id && rec?.Id > 0) {
            setCurrentSelectedRecord(rec)
            setShowCommentCreateEditComp(true)
        }
    };

    const confirmDelete = async () => {
        if (currentSelectedRecord != undefined) {
            let [isSuccess, message] = await deleteUserCommentAndAttachment((currentProjectIdGblCtx ?? 0), (currentSelectedRecord?.Id ?? 0))
            if (isSuccess) {
                fetchProjectUserComments();
                toast.success("Deleted Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
            } else {
                return toast.error(`Error to delete`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
        }
        setCurrentSelectedRecord(null);
        setShowRecDeleteModal(false);
    };

    const cancelDelete = () => {
        setCurrentSelectedRecord(null);
        setShowRecDeleteModal(false);
    };

    const handleDownload = async (commentId: number, fileName: string) => {
        if (commentId > 0) {
            try {
                let [isSuccess, response] = await downloadCommentAttachment(currentProjectIdGblCtx ?? 0, commentId);
                const file = new Blob([response]);
                const fileURL = window.URL.createObjectURL(file);
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
                window.open(fileURL, '_blank');
            } catch (error) {
                return toast.error(`Error downloading the attachment:${error}`, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
        }
    };

    const handleDownloadAzureURL = async (commentId: number, fileName: string) => {
        if (commentId > 0) {
            let [isSuccess, blobUrl] = await getUserCommentAttachmentBlobUrl(currentProjectIdGblCtx ?? 0, commentId);
            //window.open(blobUrl, '_blank');

            const fileLink = document.createElement('a');
            fileLink.href = blobUrl;
            fileLink.setAttribute('download', fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    }

    return (
        <>
            <div>
                <ConfirmationModal
                    show={showRecDeleteModal}
                    title="Confirm Deletion of Role"
                    message={recordToDeleteMessage}
                    onClose={() => setShowRecDeleteModal(false)}
                    onCancel={cancelDelete}
                    onConfirm={confirmDelete}
                    confirmText="Delete"
                />
            </div>
            {showCommentCreateEditComp &&
                <CreateUserComments projectId={(currentProjectIdGblCtx ?? 0)}
                    onClose={onCommentCreateClose}
                    initialData={currentSelectedRecord}
                />
            }
            <div className="table-container" style={{ minHeight: '376px', maxHeight: '376px', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #ccc' }}>
                {!isReadOnly && !showCommentCreateEditComp && <a href="#" onClick={() => setShowCommentCreateEditComp(!showCommentCreateEditComp)}> Add Comment/Supporting Document</a>}

                <table className="table" style={{ fontSize: '12px', margin: '0px' }}>
                    <thead style={{ position: 'sticky', top: '0' }}>
                        <tr>
                            <th>Comment</th>
                            <th>Attachment?</th>
                            <th>Project Version</th>
                            <th>Created By</th>
                            <th>Created Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allUserComments?.map((rec, index) => {
                            let isDisplayEditLink = !isReadOnly && index == 0 && rec.CreatedByEmail == userAccessInfoGblCtx?.UserName
                            let isDisplayDeleteLink = !isReadOnly && index == 0 && rec.CreatedByEmail == userAccessInfoGblCtx?.UserName

                            return (
                                <tr key={index}>
                                    <td style={{ minWidth: '250px', border: '1px solid #ccc' }}>{rec.Comment}</td>
                                    <td style={{ border: '1px solid #ccc' }}><a href="#" onClick={() => handleDownloadAzureURL((rec.Id ?? 0), rec.FileName ?? "")}>{rec.FileName}</a> </td>
                                    <td style={{ border: '1px solid #ccc' }}>{rec.ProjectVersion}</td>
                                    <td style={{ border: '1px solid #ccc' }}>{rec.CreatedBy}</td>
                                    <td style={{ border: '1px solid #ccc' }}>{formatDateTimeAsString(rec.CreatedDate)}</td>

                                    <td style={{ width: '50px', border: '1px solid #ccc' }}>
                                        {showEditLink && isDisplayEditLink && <a onClick={() => editResource(rec)} className="">
                                            <i className="fas fa-edit"></i>
                                        </a>
                                        }
                                        {showDeleteLink && isDisplayDeleteLink && <a style={{ marginLeft: '5px', color: 'red' }} onClick={() => deleteResource(rec)} className="">
                                            <i className="fas fa-trash"></i>
                                        </a>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {allUserComments?.length == 0 && (
                    <div style={{ paddingTop: 'center', textAlign: 'center' }}>No record found. Please add new comment.</div>
                )}
            </div>
        </>
    );
};

export default UserComments;