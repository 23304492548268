import React, { useEffect, useState } from 'react';
import { IRole, ISkillBase } from '../../data-model';
import { useDelayDebounce } from '../../common/hooks/debounce';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { useProjectDataContext } from '../../data-context/project-data-context';
import { Link, useParams } from 'react-router-dom';
import { attachSkillBaseToRole, detachSkillBaseFromRole, getAllSkills, getRoleDetailsById } from '../_services/pcr-rates-svc';
import { toast } from 'react-toastify';
import LoadingIcon from '../../common/loading-icon/loading-icon';

const RoleDetails: React.FC<{}> = ({ }) => {

    const { allDefaultSkillBaseGblCtx, setAllDefaultSkillBaseGblCtx } = useProjectDataContext();

    const [selectedRole, setSelectedRole] = useState<IRole | null>();
    const [allSelectedSkills, setAllSelectedSkills] = useState<ISkillBase[]>([]);
    const [allDeSelectedSkills, setAllDeSelectedSkills] = useState<ISkillBase[]>([]);
    const [loadingSelectedSkills, setLoadingSelectedSkills] = useState(true);
    const [loadingDefaultSkills, setLoadingDefaultSkills] = useState(true);

    const delayDebouncedSkills = useDelayDebounce(100);
    const delayDebouncedRole = useDelayDebounce(90);

    const { roleId } = useParams<{ roleId: string }>();

    let selectedRoleId: number;
    if (roleId != undefined)
        selectedRoleId = parseInt(roleId);
    else
        selectedRoleId = 0;

    useEffect(() => {
        if (selectedRoleId > 0 && delayDebouncedRole > 0) {
            fetchRoleDetails();
        }

    }, [delayDebouncedRole]);

    const fetchRoleDetails = async () => {
        if (selectedRoleId > 0) {
            setLoadingSelectedSkills(true);
            let data = await getRoleDetailsById(selectedRoleId);
            if (data) {
                setSelectedRole(data);
            }
            setLoadingSelectedSkills(false);
        }
    }

    useEffect(() => {
        if (delayDebouncedSkills > 0 && allDefaultSkillBaseGblCtx.length == 0) {
            fetchAllDefaultSkills();
        }
    }, [delayDebouncedSkills]);

    const fetchAllDefaultSkills = async () => {
        if (allDefaultSkillBaseGblCtx.length == 0) {
            setLoadingDefaultSkills(true);
            let skills = await getAllSkills();
            if (skills) {
                setAllDefaultSkillBaseGblCtx(skills ?? []);
            }
            setLoadingDefaultSkills(false);
        }
    }

    const handleAttach = async () => {
        if (allSelectedSkills.length > 0) {
            let [isSuccess, message] = await attachSkillBaseToRole(selectedRoleId, allSelectedSkills);

            if (isSuccess) {
                toast.success("Attached Skill Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                await fetchRoleDetails();
                setAllSelectedSkills([]);
                //setAllDeSelectedSkills([]);
            }
            else {
                toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
            setLoadingSelectedSkills(false);
        }
    };

    const handleDetach = async () => {
        if (allDeSelectedSkills.length > 0) {
            let [isSuccess, message] = await detachSkillBaseFromRole(selectedRoleId, allDeSelectedSkills);

            if (isSuccess) {
                toast.success("Removed Skill Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                await fetchRoleDetails();
                //setAllSelectedSkills([]);
                setAllDeSelectedSkills([]);
            }
            else {
                toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
            setLoadingSelectedSkills(false);
        }
    };

    const handleSelectedSkillChange = (event: React.ChangeEvent<HTMLInputElement>, skill: ISkillBase) => {
        const { name, value, checked } = event.target;
        if (!checked) {
            const isExist = allDeSelectedSkills.some((item) => item.SkillBaseId == skill.SkillBaseId);
            if (isExist) {
                setAllDeSelectedSkills(allDeSelectedSkills.filter((item) => item.SkillBaseId != skill.SkillBaseId));
            }
        }
        else {
            const isExist = allDeSelectedSkills.some((item) => item.SkillBaseId == skill.SkillBaseId);
            if (!isExist) {
                setAllDeSelectedSkills([...allDeSelectedSkills, skill]);
            }
        }
    };

    const handleDefaultSkillChange = (event: React.ChangeEvent<HTMLInputElement>, skill: ISkillBase) => {
        const { name, value, checked } = event.target;
        if (!checked) {
            const isExist = allSelectedSkills.some((item) => item.SkillBaseId == skill.SkillBaseId);
            if (isExist) {
                setAllSelectedSkills(allSelectedSkills.filter((item) => item.SkillBaseId != skill.SkillBaseId));
            }
        }
        else {
            const isExist = allSelectedSkills.some((item) => item.SkillBaseId == skill.SkillBaseId);
            if (!isExist) {
                setAllSelectedSkills([...allSelectedSkills, skill]);
            }
        }
    };


    return (
        <>
            <div>

                <div style={{ paddingBottom: '2px' }} className="d-flex justify-content-left">
                    <p style={{ fontWeight: 'bold', paddingRight: '10px' }}>Role Name:</p>{selectedRole?.Name ?? ''}
                </div>
                <div style={{ paddingBottom: '2px' }} className="d-flex justify-content-left">
                    <p style={{ fontWeight: 'bold', paddingRight: '10px' }}>Role Code:</p>{selectedRole?.Code ?? ''}
                </div>
                <div style={{ paddingBottom: '10px' }} className="d-flex justify-content-between">
                    <p><strong>Description:</strong> {selectedRole?.Description ?? ''}</p>
                </div>
                <div className='row' style={{ border: '1px solid grey' }}>
                    <div className="col-xl-5 col-md-5 col-lg-5" style={{ borderRight: '2px solid grey' }}>
                        <h3 style={{ backgroundColor: 'grey' }}>Attached Skills:</h3>
                        <div style={{ marginLeft: '20px', marginTop: '5px', overflowY: 'scroll', minHeight: '400px', maxHeight: '400px' }}>
                            {loadingSelectedSkills ? (<LoadingIcon />) : ''}
                            {selectedRole?.Skills?.map((item) => {
                                let isRoleDeSelected: boolean = allDeSelectedSkills.some((s) => item.SkillBaseId == s.SkillBaseId);

                                return <div>
                                    <Form.Check
                                        style={{ marginTop: '3px', fontSize: '15pt' }}
                                        key={item.SkillBaseId}
                                        disabled={false}
                                        type="checkbox"
                                        name='skill'
                                        label={item.Skill}
                                        checked={isRoleDeSelected}
                                        onChange={(e) => handleSelectedSkillChange(e, item)}
                                    />
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="col-xl-1 col-md-1 col-lg-1" style={{ marginTop: '180px' }}>
                        <Button disabled={allSelectedSkills.length == 0} variant="primary" style={{ width: '100px' }} onClick={handleAttach}>
                            {'<--'}
                        </Button>
                        <Button disabled={allDeSelectedSkills.length == 0} variant="primary" style={{ marginTop: '10px', width: '100px' }} onClick={handleDetach}>
                            {'-->'}
                        </Button>
                    </div>
                    <div className="col-xl-5 col-md-5 col-lg-5" style={{ borderLeft: '2px solid grey' }}>
                        <h3 style={{ backgroundColor: 'green' }}>Available Skills:</h3>
                        <div style={{ marginLeft: '20px', marginTop: '5px', overflowY: 'scroll', minHeight: '400px', maxHeight: '400px' }}>
                            {loadingSelectedSkills ? (<LoadingIcon />) : ''}
                            {allDefaultSkillBaseGblCtx?.map((item) => {
                                let isExist1 = selectedRole?.Skills?.some((s) => item.SkillBaseId == s.SkillBaseId);
                                if (!isExist1) {
                                    let isRoleSelected: boolean = allSelectedSkills.some((s) => item.SkillBaseId == s.SkillBaseId);
                                    return <div>
                                        <Form.Check
                                            style={{ marginTop: '3px', fontSize: '15pt' }}
                                            key={item.SkillBaseId}
                                            disabled={false}
                                            type="checkbox"
                                            name='skill'
                                            label={item.Skill}
                                            checked={isRoleSelected}
                                            onChange={(e) => handleDefaultSkillChange(e, item)}
                                        />
                                    </div>
                                }
                            })}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Link to={`/role-summary`}>
                        All Role(s)
                    </Link>
                </div>
            </div >
        </>
    );
};

export default RoleDetails;
