import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useProjectDataContext } from "../../data-context/project-data-context";
import { useEffect, useState } from "react";
import { getRandomNumber } from "../../utilities/helper-function";
import { IAPIRequestFilter } from "../../data-model";
import { patchProjectData } from "../_services/dashboard-svc";

const ModifyOfferedPriceModal: React.FC<{
    show: boolean;
    onClose: () => void;
    projectId: number | undefined;
}> = ({ show, onClose, projectId }) => {

    const { currentProjectIdGblCtx, currentProjectInfoGblCtx, setCurrentProjectInfoGblCtx } = useProjectDataContext();
    const [offeredPrice, setOfferedPrice] = useState<number | undefined>();
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const [disabledModifyButton, setDisabledModifyButton] = useState<boolean>(true);

    useEffect(() => {
        if (show && currentProjectInfoGblCtx && currentProjectInfoGblCtx.OfferedPrice) {
            setOfferedPrice(currentProjectInfoGblCtx.OfferedPrice);
        }
    }, [show]);

    useEffect(() => {
        setDisabledModifyButton(offeredPrice == currentProjectInfoGblCtx?.OfferedPrice)
    }, [offeredPrice]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let p = parseInt(value);
        if (name == "offeredPrice") {
            setOfferedPrice(p);
        }

        if (validationErrors[name]) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }

        validateFormData(name, value);
    };

    const validateFormData = (name: string, value: string) => {
        const errors: { [key: string]: string } = {};

        if (!offeredPrice) {
            errors.offeredPrice = 'Offered price is required';
        }

        if (!(offeredPrice && offeredPrice >= 0 && offeredPrice <= 99999999)) {
            errors.offeredPrice = 'Offered price should be greater 0';
        }

        if (validationErrors[name]) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }

        return errors;
    }

    const handleModifyOfferedPriceClick = async () => {
        setDisabledModifyButton(true);
        const errors = validateFormData("", "");
        if (Object.keys(errors).length === 0 && currentProjectInfoGblCtx) {
            toast.info('Saving offered price...', { toastId: 'info1', autoClose: 1000, position: toast.POSITION.TOP_CENTER });

            if (projectId && projectId > 0) {
                let patchData: IAPIRequestFilter[] = [{
                    Field: "OfferedPrice",
                    Operator: '',
                    Value: '' + offeredPrice,
                    Text: ''
                }];

                let [isSuccess, message] = await patchProjectData(projectId, patchData)
                if (isSuccess) {
                    toast.success("Project offered Price updated successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                    currentProjectInfoGblCtx.OfferedPrice = offeredPrice;
                    setCurrentProjectInfoGblCtx(currentProjectInfoGblCtx)
                    onClose();
                }
                else {
                    toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
                }
            }
        } else {
            setValidationErrors(errors);
        }
        setDisabledModifyButton(false);
    };

    const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
        <div id="auditTransaction" className='auditTransaction'>
            <Modal onClick={handleModalClick} show={show} onHide={onClose} onClose={onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Project Offered Price</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group controlId="formOfferedPrice">
                                    <Form.Label>Offered Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="offeredPrice"
                                        value={offeredPrice}
                                        isInvalid={!!validationErrors.offeredPrice}
                                        onChange={handleInputChange}
                                        placeholder="Enter your Offered price..."
                                    />
                                </Form.Group>
                            </div>
                            {
                                validationErrors.offeredPrice && <p style={{ color: 'red' }} className="error-message">{validationErrors.offeredPrice}*</p>
                            }
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ marginTop: '15px' }} variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                    <Button style={{ marginLeft: '10px', marginRight: '10px' }} disabled={disabledModifyButton} variant="primary" onClick={handleModifyOfferedPriceClick}>
                        Update Offered Price
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ModifyOfferedPriceModal;