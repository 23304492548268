import { deleteApiData, getApiData, patchApiData, postApiData, putApiData } from '../../app-services'
import { IPCRRoleRate, IPCRRoleRateSummary, IProjectEffortHours, IRole, ISkillBase } from '../../data-model';
import IPCRRoleRateReq from '../../data-model/pcr-rates-req';
import { parseAndCastDate } from '../../utilities/helper-function';
import { getBaseFTTAPIURLS } from './common-svc';

const getAllPCRRateSummary = async (): Promise<IPCRRoleRateSummary[] | null> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/summary/all`;

  var apiResponse = await getApiData(url);

  let pcrRateAllSummary: IPCRRoleRateSummary[] = [];
  if (apiResponse?.Success && apiResponse.Result?.length > 0) {
    pcrRateAllSummary = mapPCRRateSummaryResponse(apiResponse.Result);
  }
  return pcrRateAllSummary;
}

function mapPCRRateSummaryResponse(apiResponse: any): IPCRRoleRateSummary[] {
  return apiResponse.map((result: any) => ({
    ...result,
    ValidFrom: parseAndCastDate(result.ValidFrom),
    ValidTo: parseAndCastDate(result.ValidTo),
    CreateDate: parseAndCastDate(result.CreateDate ?? ''),
    ModifiedDate: parseAndCastDate(result.ModifiedDate ?? '')
  }));
}

const getPCRRateSummary = async (year: number): Promise<IPCRRoleRateSummary | null> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/summary/${year}`;

  var apiResponse = await getApiData(url);
  if (apiResponse?.Success && apiResponse?.Result) {
    let pcrs = mapPCRRateApiResponse(apiResponse.Result);
    return pcrs;
  }
  return null;
}

function mapPCRRateApiResponse(res: any): IPCRRoleRateSummary {
  return {
    ...res,
    ValidFrom: parseAndCastDate(res.ValidFrom),
    ValidTo: parseAndCastDate(res.ValidTo),
    CreateDate: parseAndCastDate(res.CreateDate ?? ''),
    ModifiedDate: parseAndCastDate(res.ModifiedDate ?? '')
  };
}

const getAllPCRRoleByYear = async (): Promise<IPCRRoleRate[] | null> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/pcrrole/default/all/${new Date().getFullYear()}`;
  var apiResponse = await getApiData(url);

  let allPCRRoleRates: IPCRRoleRate[] = [];
  if (apiResponse?.Success && apiResponse.Result?.length > 0) {
    allPCRRoleRates = mapAllPCRRoleRateResponse(apiResponse.Result);
  }
  return allPCRRoleRates;
}

function mapAllPCRRoleRateResponse(apiResponse: any): IPCRRoleRate[] {
  return apiResponse.map((result: any) => ({
    ...result,
    DateFrom: parseAndCastDate(result.DateFrom),
    DateTo: parseAndCastDate(result.DateTo)
  }));
}


const getPCRHistoryRoleId = async (roleId: number | undefined): Promise<IPCRRoleRate[] | null> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/pcrrole/history/${roleId}`;
  var apiResponse = await getApiData(url);

  let allPCRRoleRates: IPCRRoleRate[] = [];
  if (apiResponse?.Success && apiResponse.Result?.length > 0) {
    allPCRRoleRates = mapAllPCRRoleRateHistoryResponse(apiResponse.Result);
  }
  return allPCRRoleRates;
}

function mapAllPCRRoleRateHistoryResponse(apiResponse: any): IPCRRoleRate[] {
  return apiResponse.map((result: any) => ({
    ...result,
    DateFrom: parseAndCastDate(result.DateFrom),
    DateTo: parseAndCastDate(result.DateTo)
  }));
}

const getAllAvailableRoles = async (year: number): Promise<IRole[] | null> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/all/available/roles/${year}`;
  var apiResponse = await getApiData(url);
  let allPCRRoleRates: IRole[] = [];
  if (apiResponse?.Success && apiResponse.Result?.length > 0) {
    allPCRRoleRates = mapAllRoleResponse(apiResponse.Result);
  }
  return allPCRRoleRates;
}

const getAllRoles = async (): Promise<IRole[] | null> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/role/all`;
  var apiResponse = await getApiData(url);
  let allPCRRoleRates: IRole[] = [];
  if (apiResponse?.Success && apiResponse.Result?.length > 0) {
    allPCRRoleRates = mapAllRoleResponse(apiResponse.Result);
  }
  return allPCRRoleRates;
}

function mapAllRoleResponse(apiResponse: any): IRole[] {
  return apiResponse.map((result: any) => ({
    ...result
  }));
}

const saveNewRole = async (role: IRole): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/role/create`;
  var apiResponse = await postApiData(url, role);
  return [apiResponse?.Success, apiResponse?.Result];
}

const updateRole = async (role: IRole): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/role/update`;
  var apiResponse = await putApiData(url, role);
  return [apiResponse?.Success, apiResponse?.Result];
}

const deleteRole = async (roleId: number): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/role/delete/${roleId}`;
  var apiResponse = await deleteApiData(url);
  return [apiResponse?.Success, apiResponse?.Result];
}

const updatePCRRateData = async (pcr: IPCRRoleRateReq | undefined): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/update/rate`;
  var apiResponse = await postApiData(url, pcr);
  return [apiResponse?.Success, apiResponse?.Result];
}

const attachedPCRRateData = async (pcr: IPCRRoleRateReq | undefined): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/attach/role`;
  var apiResponse = await postApiData(url, pcr);
  return [apiResponse?.Success, apiResponse?.Result];
}

const importPCRRatesFromPreviousYear = async (year: number): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/import/${year}`;
  var apiResponse = await postApiData(url);
  return [apiResponse?.Success, apiResponse?.Result];
}

const getAllSkills = async (): Promise<ISkillBase[] | null> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/skill/all`;
  var apiResponse = await getApiData(url);
  let allSkills: ISkillBase[] = [];
  if (apiResponse?.Success && apiResponse.Result?.length > 0) {
    allSkills = mapAllSkillBaseResponse(apiResponse.Result);
  }
  return allSkills;
}

function mapAllSkillBaseResponse(apiResponse: any): ISkillBase[] {
  return apiResponse.map((result: any) => ({
    ...result
  }));
}

const getRoleDetailsById = async (roleId: number): Promise<IRole | undefined> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/role/get/${roleId}`;
  var apiResponse = await getApiData(url);
  let role: IRole | undefined = undefined;
  if (apiResponse?.Success && apiResponse.Result) {
    role = { ...apiResponse.Result };
  }
  return role;
}

const saveNewSkillBase = async (skillBase: ISkillBase): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/skill/create`;
  var apiResponse = await postApiData(url, skillBase);
  return [apiResponse?.Success, apiResponse?.Result];
}

const updateSkillBase = async (skillBase: ISkillBase): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/skill/update`;
  var apiResponse = await putApiData(url, skillBase);
  return [apiResponse?.Success, apiResponse?.Result];
}

const getSkillBaseDetailsById = async (skillId: number): Promise<ISkillBase | undefined> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/skill/get/${skillId}`;
  var apiResponse = await getApiData(url);
  let skill: ISkillBase | undefined = undefined;
  if (apiResponse?.Success && apiResponse.Result) {
    skill = { ...apiResponse.Result };
  }
  return skill;
}

const attachSkillBaseToRole = async (roleId: number, skillBases: ISkillBase[]): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/role/attach/${roleId}/skills`;
  var apiResponse = await patchApiData(url, skillBases);
  return [apiResponse?.Success, apiResponse?.Result];
}

const detachSkillBaseFromRole = async (roleId: number, skillBases: ISkillBase[]): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/role/detach/${roleId}/skills`;
  var apiResponse = await patchApiData(url, skillBases);
  return [apiResponse?.Success, apiResponse?.Result];
}

const deleteSkillBase = async (skillId: number): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/skill/delete/${skillId}`;
  var apiResponse = await deleteApiData(url);
  return [apiResponse?.Success, apiResponse?.Result];
}

const attachRoleToSkillBase = async (skillId: number, roles: IRole[]): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/skill/attach/${skillId}/roles`;
  var apiResponse = await patchApiData(url, roles);
  return [apiResponse?.Success, apiResponse?.Result];
}

const detachRoleFromSkillBase = async (skillId: number, roles: IRole[]): Promise<[boolean, string]> => {
  const baseAPIUrls = getBaseFTTAPIURLS();;
  let url = `${baseAPIUrls}/pcrrate/skill/detach/${skillId}/roles`;
  var apiResponse = await patchApiData(url, roles);
  return [apiResponse?.Success, apiResponse?.Result];
}

export {
  getAllPCRRateSummary, getAllPCRRoleByYear, getPCRRateSummary, saveNewRole, updateRole, getAllRoles,
  deleteRole, getAllSkills, saveNewSkillBase, updateSkillBase, deleteSkillBase, getRoleDetailsById
  , attachSkillBaseToRole, detachSkillBaseFromRole, getSkillBaseDetailsById, attachRoleToSkillBase,
  detachRoleFromSkillBase, updatePCRRateData, importPCRRatesFromPreviousYear, getPCRHistoryRoleId,
  getAllAvailableRoles, attachedPCRRateData
};