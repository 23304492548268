import { useEffect, useState } from "react";
import { ISkillBase } from "../../data-model";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { initialDefaultSkillBase } from "../_services/initial-default-data";

const SkillBaseCreate: React.FC<{
    show: boolean;
    onClose: () => void;
    onSave: (updatedDetails: ISkillBase) => void;
    initialRole: ISkillBase;
}> = ({ show, onClose, initialRole, onSave }) => {

    const [formData, setFormData] = useState<ISkillBase>(initialRole || initialDefaultSkillBase);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{initialRole ? 'Edit Role' : 'Create Role'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formRoleName">
                            <Form.Label>Skill</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter skill name"
                                name="Skill"
                                value={formData?.Skill ?? ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formRoleIsActive">
                            <Form.Check
                                type="checkbox"
                                label="Is Active"
                                name="IsActive"
                                checked={formData.IsActive ?? false}
                                onChange={(e) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        IsActive: e.target.checked,
                                    }))
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        <i className="fas fa-times"></i> Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        <i className="fas fa-save"></i> Save Skill
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default SkillBaseCreate;