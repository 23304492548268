import React from 'react';

const RefreshCache: React.FC = () => {
    return (
        <>
            <div>
                 <h2>Manage Cache</h2>
            </div>
        </>

    );
};

export default RefreshCache;
